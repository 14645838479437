* {
  font-family: "Gordita";
}

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url(https://db.onlinewebfonts.com/c/0825e48b888e2966c14f17034b133d07?family=Gordita);

@font-face {
  font-family: "Gordita";
  src: url("https:https://db.onlinewebfonts.com/t/0825e48b888e2966c14f17034b133d07.eot");
  src: url("https:https://db.onlinewebfonts.com/t/0825e48b888e2966c14f17034b133d07.eot?#iefix") format("embedded-opentype"), url("https://db.onlinewebfonts.com/t/0825e48b888e2966c14f17034b133d07.woff2") format("woff2"), url("https://db.onlinewebfonts.com/t/0825e48b888e2966c14f17034b133d07.woff") format("woff"), url("https://db.onlinewebfonts.com/t/0825e48b888e2966c14f17034b133d07.ttf") format("truetype"), url("https://db.onlinewebfonts.com/t/0825e48b888e2966c14f17034b133d07.svg#Gordita") format("svg");
}


.ticketReveal{
  display: flex;
  flex-flow: row nowrap;
}

.elementCss{
  margin-right: 10px;
  margin-bottom: 10px;
}

.rowIds{
  display: flex;
  flex-flow: row wrap;
  margin-top: 20px;
}

.mainImg img{
  width: 100%;
  height: 100%;
}



@keyframes bang {
  from {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
.hoverme {

  position: relative;
}

.hoverme i {
  position: absolute;
  display: block;
  left: 50%;
  top: 0;
  width: 3px;
  height: 5px;
  background: red;
  opacity: 0;
}
.hoverme:hover i:nth-of-type(1) {
  transform: translate3d(-14px, -66px, 0) rotate(296deg);
  background: #15ff00;
  animation: bang 700ms ease-out forwards;
  opacity: 0;
}
.hoverme:hover i:nth-of-type(2) {
  transform: translate3d(-73px, -72px, 0) rotate(342deg);
  background: #00fffb;
  animation: bang 700ms ease-out forwards;
  opacity: 0;
}
.hoverme:hover i:nth-of-type(3) {
  transform: translate3d(-3px, -89px, 0) rotate(194deg);
  background: #9500ff;
  animation: bang 700ms ease-out forwards;
  opacity: 0;
}
.hoverme:hover i:nth-of-type(4) {
  transform: translate3d(74px, -62px, 0) rotate(124deg);
  background: #ff00f2;
  animation: bang 700ms ease-out forwards;
  opacity: 0;
}
.hoverme:hover i:nth-of-type(5) {
  transform: translate3d(42px, -99px, 0) rotate(150deg);
  background: #1500ff;
  animation: bang 700ms ease-out forwards;
  opacity: 0;
}
.hoverme:hover i:nth-of-type(6) {
  transform: translate3d(84px, -68px, 0) rotate(353deg);
  background: #9100ff;
  animation: bang 700ms ease-out forwards;
  opacity: 0;
}
.hoverme:hover i:nth-of-type(7) {
  transform: translate3d(-82px, -67px, 0) rotate(242deg);
  background: #00ffd9;
  animation: bang 700ms ease-out forwards;
  opacity: 0;
}
.hoverme:hover i:nth-of-type(8) {
  transform: translate3d(-15px, -71px, 0) rotate(243deg);
  background: #ffe600;
  animation: bang 700ms ease-out forwards;
  opacity: 0;
}
.hoverme:hover i:nth-of-type(9) {
  transform: translate3d(57px, -98px, 0) rotate(89deg);
  background: #ff00cc;
  animation: bang 700ms ease-out forwards;
  opacity: 0;
}
.hoverme:hover i:nth-of-type(10) {
  transform: translate3d(-86px, -60px, 0) rotate(119deg);
  background: #fbff00;
  animation: bang 700ms ease-out forwards;
  opacity: 0;
}
.hoverme:hover i:nth-of-type(11) {
  transform: translate3d(-28px, -90px, 0) rotate(75deg);
  background: #00ff33;
  animation: bang 700ms ease-out forwards;
  opacity: 0;
}
.hoverme:hover i:nth-of-type(12) {
  transform: translate3d(46px, -83px, 0) rotate(120deg);
  background: #ff0009;
  animation: bang 700ms ease-out forwards;
  opacity: 0;
}
.hoverme:hover i:nth-of-type(13) {
  transform: translate3d(-49px, -50px, 0) rotate(243deg);
  background: #c8ff00;
  animation: bang 700ms ease-out forwards;
  opacity: 0;
}
.hoverme:hover i:nth-of-type(14) {
  transform: translate3d(-85px, -56px, 0) rotate(243deg);
  background: #00ff2f;
  animation: bang 700ms ease-out forwards;
  opacity: 0;
}
.hoverme:hover i:nth-of-type(15) {
  transform: translate3d(-41px, -92px, 0) rotate(269deg);
  background: #8cff00;
  animation: bang 700ms ease-out forwards;
  opacity: 0;
}
.hoverme:hover i:nth-of-type(16) {
  transform: translate3d(46px, -67px, 0) rotate(69deg);
  background: #00ffe1;
  animation: bang 700ms ease-out forwards;
  opacity: 0;
}
.hoverme:hover i:nth-of-type(17) {
  transform: translate3d(-41px, -65px, 0) rotate(97deg);
  background: #ffbb00;
  animation: bang 700ms ease-out forwards;
  opacity: 0;
}
.hoverme:hover i:nth-of-type(18) {
  transform: translate3d(44px, -78px, 0) rotate(257deg);
  background: #001eff;
  animation: bang 700ms ease-out forwards;
  opacity: 0;
}
.hoverme:hover i:nth-of-type(19) {
  transform: translate3d(-11px, -77px, 0) rotate(6deg);
  background: #2fff00;
  animation: bang 700ms ease-out forwards;
  opacity: 0;
}
.hoverme:hover i:nth-of-type(20) {
  transform: translate3d(15px, -62px, 0) rotate(336deg);
  background: #0077ff;
  animation: bang 700ms ease-out forwards;
  opacity: 0;
}
.hoverme:hover i:nth-of-type(21) {
  transform: translate3d(-63px, -89px, 0) rotate(283deg);
  background: #ffc400;
  animation: bang 700ms ease-out forwards;
  opacity: 0;
}
.hoverme:hover i:nth-of-type(22) {
  transform: translate3d(65px, -76px, 0) rotate(142deg);
  background: #ff6600;
  animation: bang 700ms ease-out forwards;
  opacity: 0;
}
.hoverme:hover i:nth-of-type(23) {
  transform: translate3d(-8px, -64px, 0) rotate(18deg);
  background: #aaff00;
  animation: bang 700ms ease-out forwards;
  opacity: 0;
}
.hoverme:hover i:nth-of-type(24) {
  transform: translate3d(36px, -70px, 0) rotate(340deg);
  background: #c400ff;
  animation: bang 700ms ease-out forwards;
  opacity: 0;
}
.hoverme:hover i:nth-of-type(25) {
  transform: translate3d(-4px, -82px, 0) rotate(149deg);
  background: #e1ff00;
  animation: bang 700ms ease-out forwards;
  opacity: 0;
}
.hoverme:hover i:nth-of-type(26) {
  transform: translate3d(-47px, -88px, 0) rotate(134deg);
  background: #ff0080;
  animation: bang 700ms ease-out forwards;
  opacity: 0;
}
.hoverme:hover i:nth-of-type(27) {
  transform: translate3d(-69px, -96px, 0) rotate(1deg);
  background: #00ff51;
  animation: bang 700ms ease-out forwards;
  opacity: 0;
}
.hoverme:hover i:nth-of-type(28) {
  transform: translate3d(-18px, -64px, 0) rotate(1deg);
  background: #fffb00;
  animation: bang 700ms ease-out forwards;
  opacity: 0;
}
.hoverme:hover i:nth-of-type(29) {
  transform: translate3d(-99px, -81px, 0) rotate(23deg);
  background: #ff2600;
  animation: bang 700ms ease-out forwards;
  opacity: 0;
}
.hoverme:hover i:nth-of-type(30) {
  transform: translate3d(-23px, -82px, 0) rotate(337deg);
  background: #00ffe1;
  animation: bang 700ms ease-out forwards;
  opacity: 0;
}
.hoverme:hover i:nth-of-type(31) {
  transform: translate3d(-44px, -70px, 0) rotate(247deg);
  background: #ff002b;
  animation: bang 700ms ease-out forwards;
  opacity: 0;
}
.hoverme:hover i:nth-of-type(32) {
  transform: translate3d(-15px, -99px, 0) rotate(45deg);
  background: #bbff00;
  animation: bang 700ms ease-out forwards;
  opacity: 0;
}
.hoverme:hover i:nth-of-type(33) {
  transform: translate3d(37px, -95px, 0) rotate(59deg);
  background: #00b3ff;
  animation: bang 700ms ease-out forwards;
  opacity: 0;
}
.hoverme:hover i:nth-of-type(34) {
  transform: translate3d(-14px, -83px, 0) rotate(218deg);
  background: #00ff1e;
  animation: bang 700ms ease-out forwards;
  opacity: 0;
}
.hoverme:hover i:nth-of-type(35) {
  transform: translate3d(-44px, -51px, 0) rotate(13deg);
  background: #ff00dd;
  animation: bang 700ms ease-out forwards;
  opacity: 0;
}
.hoverme:hover i:nth-of-type(36) {
  transform: translate3d(67px, -73px, 0) rotate(231deg);
  background: #c4ff00;
  animation: bang 700ms ease-out forwards;
  opacity: 0;
}
.hoverme:hover i:nth-of-type(37) {
  transform: translate3d(-4px, -58px, 0) rotate(17deg);
  background: #40ff00;
  animation: bang 700ms ease-out forwards;
  opacity: 0;
}
.hoverme:hover i:nth-of-type(38) {
  transform: translate3d(-44px, -57px, 0) rotate(81deg);
  background: #00ff91;
  animation: bang 700ms ease-out forwards;
  opacity: 0;
}
.hoverme:hover i:nth-of-type(39) {
  transform: translate3d(-33px, -74px, 0) rotate(111deg);
  background: #00ff3c;
  animation: bang 700ms ease-out forwards;
  opacity: 0;
}
.hoverme:hover i:nth-of-type(40) {
  transform: translate3d(-84px, -95px, 0) rotate(46deg);
  background: #00d9ff;
  animation: bang 700ms ease-out forwards;
  opacity: 0;
}
.hoverme:hover i:nth-of-type(41) {
  transform: translate3d(88px, -84px, 0) rotate(30deg);
  background: #00ff37;
  animation: bang 700ms ease-out forwards;
  opacity: 0;
}
.hoverme:hover i:nth-of-type(42) {
  transform: translate3d(-96px, -74px, 0) rotate(253deg);
  background: #f7ff00;
  animation: bang 700ms ease-out forwards;
  opacity: 0;
}
.hoverme:hover i:nth-of-type(43) {
  transform: translate3d(-22px, -67px, 0) rotate(197deg);
  background: #ff8000;
  animation: bang 700ms ease-out forwards;
  opacity: 0;
}
.hoverme:hover i:nth-of-type(44) {
  transform: translate3d(-99px, -91px, 0) rotate(340deg);
  background: #22ff00;
  animation: bang 700ms ease-out forwards;
  opacity: 0;
}
.hoverme:hover i:nth-of-type(45) {
  transform: translate3d(-58px, -68px, 0) rotate(87deg);
  background: #ffe100;
  animation: bang 700ms ease-out forwards;
  opacity: 0;
}
.hoverme:hover i:nth-of-type(46) {
  transform: translate3d(-30px, -86px, 0) rotate(206deg);
  background: #ff00a2;
  animation: bang 700ms ease-out forwards;
  opacity: 0;
}
.hoverme:hover i:nth-of-type(47) {
  transform: translate3d(-41px, -77px, 0) rotate(12deg);
  background: #007bff;
  animation: bang 700ms ease-out forwards;
  opacity: 0;
}
.hoverme:hover i:nth-of-type(48) {
  transform: translate3d(83px, -96px, 0) rotate(15deg);
  background: #ff5500;
  animation: bang 700ms ease-out forwards;
  opacity: 0;
}
.hoverme:hover i:nth-of-type(49) {
  transform: translate3d(11px, -56px, 0) rotate(177deg);
  background: #ff8000;
  animation: bang 700ms ease-out forwards;
  opacity: 0;
}
.hoverme:hover i:nth-of-type(50) {
  transform: translate3d(9px, -96px, 0) rotate(160deg);
  background: #00ffd0;
  animation: bang 700ms ease-out forwards;
  opacity: 0;
}


@media screen and (max-width: 499px) {
  #fontSize2 {
    font-size: 15px;
    font-family: "Gordita";
    cursor: pointer;
    z-index: 10000;
    font-weight: 500;
    color: white;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }

  #fontSize2:hover{
    color: #ffc000;

  }
  .lotteryMain4{
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
   }
  .lotteryMain2-2 {
    justify-content: center;
   display: flex;
   flex-flow: column nowrap;
  /* From https://css.glass */
  background: url('./assets/Container.png') no-repeat center center fixed; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
    border: 2px solid rgb(160, 160, 255);
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(120, 120, 226);
  padding-top: 10px;
  margin-bottom: 120px;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
   } 
/*.............. FAQ ..............*/
.totalTickets{
  color: white;
  font-size: 25px;
  margin-bottom: 80px;
}
.pomPrice{
  color: #4ac7fc;
  text-align: center;
  margin-top: 25px;
  font-size: 18px;
 }
/* CSS */
.button-29 {
  align-items: center;
  appearance: none;
  background-image: radial-gradient(100% 100% at 100% 0, #fff2b7 0, #ffd000 100%);
  border: 0;
  border-radius: 6px;
  box-shadow: rgba(45, 35, 66, .4) 0 2px 4px,rgba(45, 35, 66, .3) 0 7px 13px -3px,rgba(58, 65, 111, .5) 0 -3px 0 inset;
  box-sizing: border-box;
  color: rgb(0, 0, 0);
  cursor: pointer;
  display: inline-flex;
  font-family: "JetBrains Mono",monospace;
  height: 48px;
  justify-content: center;
  line-height: 1;
  list-style: none;
  overflow: hidden;
  padding-left: 16px;
  padding-right: 16px;
  position: relative;
  text-align: left;
  text-decoration: none;
  transition: box-shadow .15s,transform .15s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  will-change: box-shadow,transform;
  font-size: 18px;
  width: 250px;

}

.button-29:focus {
  box-shadow: #dab200 0 0 0 1.5px inset, rgba(45, 35, 66, .4) 0 2px 4px, rgba(45, 35, 66, .3) 0 7px 13px -3px, #f3cc1d 0 -3px 0 inset;
}

.button-29:hover {
  box-shadow: rgba(45, 35, 66, .4) 0 4px 8px, rgba(45, 35, 66, .3) 0 7px 13px -3px, #9c8001 0 -3px 0 inset;
  transform: translateY(-2px);
}

.ticketsSeparately{
  display: flex;
  flex-flow: column nowrap;
  margin-bottom: 10%;
}


summary {
  font-size: 20px;
  background: #af3d330c;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  color: rgb(0, 0, 0);
  padding: 1rem;
  margin-bottom: 1rem;
  outline: none;
  text-align: left;
  cursor: pointer;
  color: white;
  border: 2px solid rgb(197, 197, 197);
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(255, 255, 255, 0.61);
}

details>summary::-webkit-details-marker {
  display: none;
}

details[open] summary~* {
  animation: sweep .5s ease-in-out;
}

@keyframes sweep {
  0% {
    opacity: 0;
    margin-top: -10px
  }

  100% {
    opacity: 1;
    margin-top: 0px
  }
}

.accordin {
  width: 90%;
  color: white;
  display: inline-block;
  flex-direction: column;
  margin-bottom: 85px;
}

.faq__content {

  text-shadow: 0 0 10px #FFFFFF;
  padding-bottom: 20px;
  text-align: left;
  font-size: 17px;
}

.faqTitle {
  margin-bottom: 20px;
  text-align: center;
  font-size: 70px;
  color: white;
  font-family: 'Poppins', sans-serif;
}

.faqbg {
  width: 100%;
  text-align: left;
  padding-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  width: 100%;
  padding-bottom: 10%;

}

.lotterySubH{
  justify-content: center;
  display: flex;
  font-size: 30px;
  font-family: 'Poppins', sans-serif;
  text-align: left;
  color: white;
  font-weight: bold;
  margin-top: -20px;
    border: 2px solid rgb(160, 160, 255);
   box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(120, 120, 226);
   width: 300px;
   margin-left: auto;
   margin-right: auto;
   border-bottom: 0px;
}

.connect div:hover {
  color: #ffc000;
}

.boxWrap2Lottery {
  display: flex;
  flex-flow: column nowrap;
  padding-bottom: 5%;
  padding-top: 5%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  width: 100%;
  background: rgb(0, 29, 56);
  background: linear-gradient(10deg, rgb(0, 37, 54) 0%, rgb(18, 17, 1) 34%, rgba(1, 6, 18, 1) 100%);
  padding-left: 5%;
  padding-right: 5%;
  
}

.lottery{
  width: 270px;
  height: 300px;
  display: flex;
  margin-left: auto;
  margin-right: auto;
}

.ticketCategory{
  color: white;
  margin-left: auto;
  margin-right: auto;
}

.lotteryMain {
  margin-top: 30px;
   display: flex;
  flex-flow: column nowrap;
  margin-bottom: 5%;

}

.lotteryMain2 {
   justify-content: space-evenly;
  display: flex;
  flex-flow: column nowrap;
 /* From https://css.glass */
 background: url('./assets/Container.png') no-repeat center center fixed; 
 -webkit-background-size: cover;
 -moz-background-size: cover;
 -o-background-size: cover;
 background-size: cover;
   border: 2px solid rgb(160, 160, 255);
 box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(120, 120, 226);
 padding-top: 10px;
 margin-bottom: 120px;
}

.lotteryMain3 {
  justify-content: space-evenly;
  flex-flow: column nowrap;
/* From https://css.glass */
background: url('./assets/Container.png') no-repeat center center fixed; 
-webkit-background-size: cover;
-moz-background-size: cover;
-o-background-size: cover;
background-size: cover;
padding: 60px;
 border: 2px solid rgb(160, 160, 255);
box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(120, 120, 226);
padding-top: 10px;
margin-bottom: 120px;
}

.drawBtn{
  background-color: white;
  color: black;
  border: none;
  padding: 15px;
  font-weight: 500;  
}

.autoPickedMain{
  background: url('./assets/Container.png') no-repeat center center fixed; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  padding: 60px;
   border: 2px solid rgb(160, 160, 255);
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(120, 120, 226);
   margin-bottom: 120px;
}

.autoPickedMain p{
  color: white;
  text-align: center;
}

.kave-btn {
  position: relative;
  border: solid 1px rgba(194, 191, 193, 0.3);
  background: none;
  color: white;
  letter-spacing: 1.8px;
  width: 250px;
  height: 50px;
  padding: 0;
  transition: all 0.25s ease;
  outline: none;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 60px;
}

.kave-btn:before {
  content: "";
  position: absolute;
  top: 0;
  left: 10%;
  width: 60px;
  height: 1px;
  transform: translateY(-1px);
  background: #93edfd;
  transition: all 0.25s ease;
}

.kave-btn:after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 10%;
  width: 60px;
  height: 1px;
  transform: translateY(1px);
  background: #00d6fc;
  transition: all 0.25s ease;
}

.kave-btn:hover {
  box-shadow: 1px 1px 8px rgba(0, 214, 252, 0.3);
  color: #00d6fc;
  text-shadow: 0 0 8px rgba(0, 214, 252, 0.4);
}

.kave-btn:hover.kave-btn:before {
  left: 0;
  width: 20px;
}

.kave-btn:hover.kave-btn:after {
  right: 0;
  width: 20px;
}

.kave-btn:hover .kave-line:before {
  bottom: 0;
}

.kave-btn:hover .kave-line:after {
  top: 0;
}

.kave-line:before {
  content: "";
  position: absolute;
  bottom: 30%;
  right: 0;
  width: 1px;
  height: 20px;
  transform: translateX(1px);
  background: #00d6fc;
  transition: all 0.25s ease;
}

.kave-line:after {
  content: "";
  position: absolute;
  top: 30%;
  left: 0;
  width: 1px;
  height: 20px;
  transform: translateX(-1px);
  background: #00d6fc;
  transition: all 0.25s ease;
}


  /* CSS */
  .button-92 {
    --c: #fff;
    /* text color */
    background: linear-gradient(90deg, #0000 33%, #fff5, #0000 67%) var(--_p,100%)/300% no-repeat,
      #004dff;
    /* background color */
    color: #0000;
    border: none;
    transform: perspective(500px) rotateY(calc(20deg * var(--_i,-1)));
    text-shadow: calc(var(--_i,-1) * 0.08em) -.01em 0   var(--c),
      calc(var(--_i,-1) * -0.08em)  .01em 2px #0004;
    outline-offset: .1em;
    transition: 0.3s;
  }

.button-92:hover,
.button-92:focus-visible {
  --_p: 0%;
  --_i: 1;
}

.button-92:active {
  text-shadow: none;
  color: var(--c);
  box-shadow: inset 0 0 9e9q #0005;
  transition: 0s;
}

.button-92 {
  font-weight: bold;
  font-size: 2rem;
  margin: 0;
  cursor: pointer;
  padding: .1em .3em;
}
  
  .about {
    display: none;
  }
  .boxWrap2Mint {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: rgb(0, 41, 79);
    background: radial-gradient(circle, rgba(0, 41, 79, 1) 0%, rgba(0, 10, 25, 1) 74%, rgba(0, 10, 25, 1) 100%);
    padding-left: 5%;
    padding-right: 5%;
  }
  .special:hover {
    background-color: rgb(228, 194, 0);

  }

  .logoW {
    width: 25px;
    height: 25px;
  }

  .special:active {
    background-color: rgb(228, 194, 0);
    box-shadow: -0px 7px rgb(153, 131, 4);
    transform: translateY(4px);
  }

  .special2:hover {
    background-color: rgb(228, 194, 0);

  }

  .special2:active {
    background-color: rgb(228, 194, 0);
    box-shadow: -0px 7px rgb(153, 131, 4);
    transform: translateY(4px);
  }

  .placeAndWL {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 50px;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    background: rgba(82, 82, 82, 0.2);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 30px;
  }

  .special {
    font-size: 20px;
    margin-left: 30px;
    border-radius: 10px;
    background-color: gold;
    border: none;
    padding: 10px;
    box-shadow: -0px 5px rgb(194, 165, 5);
  }

  .special2 {
    font-size: 20px;
    border-radius: 10px;
    background-color: gold;
    border: none;
    padding: 10px;
    box-shadow: -0px 5px rgb(194, 165, 5);
  }

  .mintingAmount {
    border: black 2px solid;
    border-radius: 10px;
    box-shadow: -0px 5px black;
    padding: 10px;
  }

  h1 {
    text-align: center;
    margin-bottom: 200px;
    margin-top: 100px;
    font-size: 50px;
    font-weight: 600;
    color: #0b72d3;
  }

  .timeline-container {
    display: flex;
    justify-content: center;
    margin-top: 80px;
  }

  .timeline-container .vertical-scrollable-timeline {
    width: 700px;
    list-style-type: none;
  }

  .timeline-container .vertical-scrollable-timeline li {
    position: relative;
    padding: 35px 0px 125px 158px;
  }

  .timeline-container .vertical-scrollable-timeline li:last-child {
    margin-bottom: 0;
  }

  .timeline-container .vertical-scrollable-timeline li h2 {
    font-size: 17px;
    margin-bottom: 10px;
    color: #ffc000;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    margin-left: -40px;
  }

  .timeline-container .vertical-scrollable-timeline li p {
    line-height: 1.5;
    color: rgb(198, 221, 255);
    font-size: 14px;
    margin-left: -40px;
  }

  .timeline-container .vertical-scrollable-timeline li p:last-child {
    margin-bottom: 0;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder {
    position: absolute;
    left: 0;
    top: 0;
    width: 95px;
    height: 95px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #c5c3cb;
    border-radius: 50%;
    z-index: 1;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder::before {
    content: "";
    width: 70px;
    height: 70px;
    border: 4px solid #fff;
    position: absolute;
    background-color: #c5c3cb;
    border-radius: 50%;
    z-index: -1;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder i {
    font-size: 25px;
    color: #fff;
  }

  .timeline-container .vertical-scrollable-timeline li::after {
    content: "";
    position: absolute;
    height: 0;
    width: 8px;
    background-color: #3f96e7;
    left: 45px;
    top: 34px;
    z-index: 0;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 8px;
    background-color: #c5c3cb;
    left: 45px;
    z-index: 0;
  }

  .timeline-container .vertical-scrollable-timeline li:last-child::before {
    content: unset;
  }

  .timeline-container .vertical-scrollable-timeline li.active .icon-holder {
    background-color: #ffc000;
  }

  .timeline-container .vertical-scrollable-timeline li.active .icon-holder::before {
    background-color: #4372c3;
  }

  .timeline-container .vertical-scrollable-timeline li.active::after {
    height: 100%;
  }

  .timeline-container .vertical-scrollable-timeline li.active:last-child::after {
    content: unset;
  }


  figure {
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
  }

  figure:nth-child(1) .rmDetails {
    transform: scale(0.6) rotate(-12.5deg);
  }

  figure:nth-child(2) .rmDetails {
    transform: scale(0.6) rotate(-9.7222222222deg);
  }

  figure:nth-child(3) .rmDetails {
    transform: scale(0.6) rotate(-6.9444444444deg);
  }

  figure:nth-child(4) .rmDetails {
    transform: scale(0.6) rotate(-4.1666666667deg);
  }

  figure:nth-child(5) .rmDetails {
    transform: scale(0.6) rotate(-1.3888888889deg);
  }

  figure:nth-child(6) .rmDetails {
    transform: scale(0.6) rotate(1.3888888889deg);
  }

  figure:nth-child(7) .rmDetails {
    transform: scale(0.6) rotate(4.1666666667deg);
  }

  figure:nth-child(8) .rmDetails {
    transform: scale(0.6) rotate(6.9444444444deg);
  }

  figure:nth-child(9) .rmDetails {
    transform: scale(0.6) rotate(9.7222222222deg);
  }

  figure:nth-child(10) .rmDetails {
    transform: scale(0.6) rotate(12.5deg);
  }

  figure .rmDetails {
    width: 100%;
    border-radius: 3px;
    background-color: black;
    padding: 50px;
    border: 5px solid gray;
  }

  .connect2Mobile {
    display: none;
  }

  .discordNone {
    transition: transform .2s;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: no-drop;
  }

  .discordNone:hover {
    transform: scale(1.1);
  }

  .linkedin {
    width: 30px;
    height: 30px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 10px;
    cursor: pointer;

  }

  .team1 {
    width: 50%;
  }

  .discord3 {
    display: none;
  }

  .discord4 {
    display: none;
  }

  .storyHRmM {
    display: none;
  }

  .iconsMob {
    display: none;
  }

  .mainBanner {
    display: none;
  }

  .icons {
    display: flex;
    flex-flow: row nowrap;
  }

  .cover52 {
    display: none;
  }

  .coverRm {
    width: 100vw;
  }

  .discordF2 {
    display: none;
  }

  .connectMobile {
    display: none;
  }

  .rightMobile {
    display: none;
  }

  .osPic {
    width: 30px;
    height: 30px;
    cursor: pointer;
    z-index: 10000;
  }

  .osPic2 {
    width: 40px;
    height: 40px;
    cursor: pointer;
  }

  .price {
    text-align: center;
    font-size: 20px;
    color: #aef6ff;
  }

  .discord2 {
    display: none;
  }

  .discord3 {
    padding-right: 20px;
    display: none;
  }


  .uRMobile {
    display: none;
  }

  .storyPicDivMobile {
    display: none;
  }

  .allWrap {

    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    contain: content;
  }

  .boxWrap2 {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background-color: black;
  }

  .boxWrap2Story {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: rgb(0, 88, 81);
    background: radial-gradient(circle, rgba(0, 88, 81, 1) 0%, rgba(0, 42, 47, 1) 34%, rgb(3, 14, 41) 100%);
    padding-left: 5%;
    padding-right: 5%;
  }

  .boxWrap2Utility {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: rgb(0, 45, 56);
    background: linear-gradient(0deg, rgba(0, 45, 56, 1) 0%, rgba(1, 6, 18, 1) 34%, rgba(1, 6, 18, 1) 100%);
    padding-left: 5%;
    padding-right: 5%;
  }

  .boxWrap2Team {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: rgb(0, 41, 79);
    background: radial-gradient(circle, rgba(0, 41, 79, 1) 0%, rgba(0, 10, 25, 1) 74%, rgba(0, 10, 25, 1) 100%);
    padding-left: 5%;
    padding-right: 5%;
  }

  .boxWrap2Platform {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: rgb(21, 38, 7);
    background: linear-gradient(309deg, rgba(21, 38, 7, 1) 0%, rgba(0, 10, 25, 1) 74%, rgba(0, 10, 25, 1) 100%);
    padding-left: 5%;
    padding-right: 5%;
  }

  .boxWrap2Tokenomic {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: rgb(0, 5, 27);
    background: linear-gradient(97deg, rgba(0, 5, 27, 1) 0%, rgba(43, 22, 56, 1) 74%, rgba(0, 5, 27, 1) 100%);
    padding-left: 5%;
    padding-right: 5%;
  }

  .sharks {
    width: 100%;
  }

  .boxWrapC {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    position: relative;

    padding-bottom: 5%;
  }

  .green {
    background-color: #97ba5e;

  }

  .aboutMob {
    margin-top: 30px;
    justify-content: space-evenly;
    display: flex;
    flex-flow: row nowrap;
  }

  .aboutVAndM {
    margin-top: 30px;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
  }

  .about2 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 90%;
  }

  .aboutImg {
    width: 50%;
    border: white 6px solid;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-bottom: 3%;
  }

  .aboutImg2 {
    width: 28%;
    height: 28%;
    border: white 6px solid;
    transition: transform .5s;
  }

  .aboutImg2:hover {
    transform: scale(1.2);
  }

  .utilityPics {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
  }

  .roadmapMain1 {
    top: 0;
    position: relative;
  }

  .storyPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .UtilDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .storyH {
    justify-content: left;
    display: flex;
    font-size: 15px;
    font-family: "Gordita";
    color: #00fae5;
    text-align: left;
  }

  .utilH {
    justify-content: left;
    display: flex;
    font-size: 15px;
    font-family: "Gordita";
    color: #00c8fa;
    text-align: left;
  }

  .TokenomicH {
    justify-content: left;
    display: flex;
    font-size: 15px;
    font-family: "Gordita";
    color: #c86ffc;
    text-align: left;
  }

  .platformH {
    justify-content: left;
    display: flex;
    font-size: 15px;
    font-family: "Gordita";
    color: #a2f55e;
    text-align: left;
  }

  .nftH {
    justify-content: left;
    display: flex;
    font-size: 15px;
    font-family: "Gordita";
    color: #0278ee;
    text-align: left;
  }

  .teamH {
    justify-content: left;
    display: flex;
    font-size: 15px;
    font-family: "Gordita";
    color: #ffc959;
    text-align: left;
  }

  .storyH2 {
    justify-content: left;
    display: flex;
    font-size: 28px;
    font-family: 'Poppins', sans-serif;
    text-align: left;
    color: white;
    font-weight: bold;
    margin-top: -20px;
  }

  .taxT {
    justify-content: center;
    display: flex;
    font-size: 20px;
    font-family: 'Poppins', sans-serif;
    text-align: left;
    color: white;
    font-weight: bold;
    margin-top: -20px;
  }

  .taxMain {
    margin-top: 80px;
  }

  .mintH {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    font-size: 90px;
    background: -webkit-linear-gradient(#f0d9a3, #ff9d01);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: "Gordita";
    -webkit-text-stroke-width: 3px;
    -webkit-text-stroke-color: black;
  }

  .storyRm img {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .tokenH {
    font-size: 70px;
    color: white;
    font-family: 'Poppins', sans-serif;
    text-align: center;
    margin-bottom: 2%;
  }


  .carouselH {
    font-size: 70px;
    color: white;
    font-family: 'Poppins', sans-serif;
    text-align: center;
    margin-bottom: 2%;
  }

  .rmH {
    font-size: 80px;
    color: white;
    font-family: 'Poppins', sans-serif;
    text-align: center;
    background-color: #fe8a00;
    padding-left: 20px;
    margin-bottom: 20px;
  }

  .rmH2 {
    color: grey;
    font-size: 18px;
    line-height: 30px;
  }

  .rmName {
    background: rgb(81, 190, 229);
    background: linear-gradient(0deg, rgba(81, 190, 229, 1) 2%, rgba(81, 190, 229, 1) 20%, rgba(6, 136, 198, 1) 63%, rgba(6, 136, 198, 1) 100%);
  }



  .storyCon {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 70%;
    margin-left: auto;
    margin-right: auto;

  }

  .storyCon-2 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    padding: 20px;
    border-radius: 50px;
    box-shadow: -10px 10px rgba(14, 21, 36, 0.363);
    background-color: rgba(30, 46, 80, 0.568);

  }

  .storyCon2 {
    font-size: 30px;
    font-family: 'Poppins', sans-serif;
    text-align: center;
  }

  .storyConP {
    font-size: 20px;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
  }

  .storyPic {
    width: 540px;
    height: 339px;
  }

  .storyRight {
    width: 45%;
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
  }

  .uR {
    width: 50%;
  }

  .intro {
    color: white;
    font-size: 100px;
    text-align: center;
    font-family: 'Riangriung', sans-serif;
  }

  .logoF {
    color: white;
    text-align: center;
    font-size: 50px;
    background: -webkit-linear-gradient(#f0d9a3, #ff9d01);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: "Gordita";
  }

  .logoF img {
    width: 50%;
  }

  .intro img {
    width: 500px;
    height: 500px;
  }

  .intro3 {
    font-style: italic;
    font-size: 20px;
    font-weight: 500;
  }

  .intro2 {
    color: rgb(0, 0, 0);
    font-size: 23px;
    text-align: left;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    font-weight: bold;
    width: 50%;
  }

  .mintDiv {
    padding-top: 3%;
    padding-bottom: 10%;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.493) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
    margin-bottom: 80px;
    border: 2px solid rgb(119, 222, 248);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(106, 184, 243);
    margin-top: 50px;
  }

  .errorMessage {
    margin-left: 3%;
    margin-top: 1%;
    font-size: 14px;
    color: #fc4741;
    text-align: center;
    text-shadow: -1px -1px 1px rgba(255, 255, 255, .1), 1px 1px 1px rgba(0, 0, 0, .5);
  }

  .loadingContainer {
    margin-top: 1%;
  }

  .loadingText {
    font-size: 20px;
    text-align: center;
    color: rgb(120, 184, 189);
    font-weight: bold;
  }

  .loadTextSub {
    text-align: center;
    color: rgb(157, 194, 199);
    font-size: 13px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;

  }

  .wPMobile {
    display: none;
  }

  .introductionMobile {
    display: none
  }

  .hl {
    color: #6ce34d;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
  }

  .right2 {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 1.5%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .discord {
    transition: transform .2s;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .discord:hover {
    transform: scale(1.1);
  }


  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(99, 31, 177);
  }



  .note {
    color: rgb(255, 255, 255);
  }

  .twitterSoc {
    margin-top: 10px;
    margin-right: 5px;
    /* z-index: -2;*/
    margin-left: 5px;

  }

  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/

  }

  /*.website{
    margin-top: 10px;
    margin-left: 10px;
   /* z-index: -2;*/
  /*color: #ffffffa2;
  }*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;

  }





  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    padding-left: 50px;
    padding-right: 50px;

    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);


  }

  .wallet:hover {
    background-color: white;
    color: rgb(73, 81, 190);
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-family: "Gordita";
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    display: none;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    font-family: "Gordita";
    align-items: center;
  }

  .connect div {
    margin-left: 15px;
    margin-right: 15px;
    font-family: "Gordita";
    color: #ffffff;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease;
    font-weight: 500;
  }


  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }




  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding-bottom: 50px;
    margin-left: auto;
    margin-right: auto;

  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-bottom: 20px;

  }

  .center {
    margin-top: auto;
    margin-bottom: auto;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }

  .btnfos-0-2 {
    font-size: 20px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 60px;
    height: 60px;
    border: none;
    font-family: 'Poppins', sans-serif;
    cursor: pointer;
    background-color: #3a9ae802;
    border: white 2px solid;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 20px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 60px;
    height: 60px;
    border: none;
    font-family: 'Poppins', sans-serif;
    cursor: pointer;
    background-color: #3a9ae802;
    border: white 2px solid;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }



  .btnfos-0-2:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(134, 134, 134, 0.288) -5px 5px;
  }


  .btnfos-0-2-2:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(134, 134, 134, 0.288) 5px 5px;
  }


  .btnfos-0-3 {
    margin-top: 20px;
    background-color: #6cfc19;
    color: #000000;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 28px;
    border-radius: 50px;
    padding-left: 30px;
    padding-right: 30px;
    transition: transform .2s;
    border: none;
    font-family: 'Poppins', sans-serif;
    transition: transform .2s;
  }

  .btnfos-0-3:hover {
    transform: scale(1.1);
  }

  .nftamount {
    color: cyan;
    font-size: 70px;
    font-family: 'Poppins', sans-serif;
   }

  .walletConnect {

    display: flex;
    justify-content: center;
  }

  .connectButton {

    font-size: 45px;
  }

  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;

  }

  .loadingContainer {
    text-align: center;
    padding-top: 20px;
  }

  .errorMessage {
    text-align: center;
    padding-top: 20px;
  }

  .successfully {
    text-align: center;
    padding-top: 20px;
    color: #1ee03e;
    font-family: 'Poppins', sans-serif;
    font-size: 22px;
    text-shadow: -1px -1px 1px rgba(255, 255, 255, .1), 1px 1px 1px rgba(0, 0, 0, .5);
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #000000;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
    border-bottom: 1px solid rgba(59, 45, 87, 0.863);
    padding: 20px;

  }

  .h1 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
    margin-top: auto;
    margin-bottom: auto;
  }

  .h2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    background-color: #5ebaad;

    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/

  }

  .introduction {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    z-index: 1000;
    background: rgba(3, 24, 107, 0.733);
    background: radial-gradient(circle, rgba(3, 24, 107, 0.747) 0%, rgba(3, 25, 54, 0.705) 74%, rgba(3, 20, 42, 0.781) 100%);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    padding-top: 130px;
    padding-bottom: 90px;
  }

  .wallet2 {
    z-index: 1000;
    background-color: #000000;
    color: #ffffff;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 20px;
    border-radius: 50px;
    width: 270px;
    transition: transform .2s;
    border: none;
    font-family: "Gordita";
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    transition: 0.5s ease;
    cursor: pointer;
    box-shadow: 0 3px #363636;
    letter-spacing: 2px;
  }

  .wallet2:hover {
    color: #000000;
    background-color: #ffffff;
  }

  .wallet2Btn {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    width: 400px;
    border: 2px solid #ffffff;
    margin-left: 10px;
    margin-right: 10px;
    border: 2px solid #2E8B57;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
  }

  .wallet2Btn:hover {
    color: rgb(156, 156, 156);
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }


  .wallet3 {
    background-image: linear-gradient(to right top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1);
    color: rgb(0, 0, 0);
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 30px;
    font-weight: bold;
    border-radius: 15px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 50px;
    padding-right: 50px;
    box-shadow: black 0px 5px;
  }

  .wallet3:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }

  @keyframes shake {

    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }

  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }

  .in2 {
    width: 100%;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .totalSupply {
    text-align: center;
    font-size: 50px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    font-family: 'Poppins', sans-serif;
    text-shadow: 0 0 10px #ffffff93;
    letter-spacing: 1px;
    margin-top: 1%;
    font-weight: bold;
  }

  @keyframes Slide_Up {
    0% {
      transform: translateY(250px);
      opacity: 1;
    }

    10%,
    87.5% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Tag {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 1;
    }

    20%,
    92.5% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Buttons {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 0;
    }

    20% {
      opacity: 1;
    }

    25%,
    95% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  .copyright {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 18px;
    text-shadow: 0 0 10px #FFFFFF;
  }

  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }

  @keyframes shake {

    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }

  @keyframes Slide_Left {
    0% {
      transform: translateX(250px);
    }

    100% {
      transform: translateX(0);
    }
  }

  @keyframes Slide_Left_Right {

    0%,
    100% {
      transform: translateX(100px);
    }

    12.5%,
    75% {
      transform: translateX(0);
    }

    25% {
      transform: translateX(20px);
    }

  }

  @keyframes Opacity {

    0%,
    100% {
      opacity: 0;
    }

    25%,
    75% {
      opacity: 1;
    }


  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }

  @keyframes scaleIn {
    from {
      transform: scale(.5, .5);
      opacity: .5;
    }

    to {
      transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }

  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1, 1);
    }

    50% {
      transform: scale(1.05, 1.05);
    }

    100% {
      transform: scale(1, 1);
    }
  }

  .logo {
    width: 90px;
    height: 90px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    border: #00c8fa 2px solid;
    border-radius: 100%;
  }

  .logo2 {
    width: 20%;
    cursor: pointer;
  }

  #fontSize {
    font-size: 12px;
    font-family: "Gordita";
    cursor: pointer;
    z-index: 10000;
    font-weight: 500;
  }

  .parrotDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;

  }

  .pic1 {
    width: 32%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    left: 70%;
    bottom: 0;

  }

  .pic2 {
    width: 32%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    left: -5%;
    bottom: 0;

  }

  
  .cover {
    width: 100vw;
  }

  .cover2 {
    width: 100%;
  }

  .parrotPic {
    width: 100vw;
  }

  .parrot {
    width: 70%;
    border-radius: 30px;
  }

  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .rmMain {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: -100px;
  }

  .rmBox {
    text-align: right;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }

  .rmBox2 {
    text-align: right;
    width: 80%;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }

  .rmBox {
    font-weight: 700;
    color: rgb(58, 58, 58);
    margin-bottom: 60%;
  }

  .year {
    font-size: 18px;
    font-family: 'Poppins', sans-serif;
    color: rgb(8, 81, 94);
    letter-spacing: 1px;
  }


  .rmBoxMain2 {
    margin-top: 30%;
    width: 33.33%;
  }

  .rmBoxMain {
    width: max-content;
    justify-content: flex-start;
    width: 33.33%;

  }

  .rmBox2 {
    text-align: left;
    width: 30%;
  }

  .rmParrot {
    width: 130px;
    height: 186px;
    z-index: 1;
    justify-content: center;
    position: -webkit-sticky;
    position: sticky;
    top: 10%;
    margin-bottom: 25%;
    margin-right: auto;
    display: block;
    margin-left: 33%;
    margin-top: -50%;
  }

  .line {
    margin-left: auto;
    margin-right: auto;
    display: block;
    height: 270vh;
    margin-top: -30%;
  }

  .rmP {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 33.33%;
    height: fit-content;
  }

  .box {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: -15%;
  }

  .discordF {
    padding-left: 10px;
    padding-right: 10px;
  }

  .socialAndLogo {
    flex-flow: row nowrap;
    justify-content: space-between;
    display: flex;
    padding-left: 10%;
    padding-right: 10%;
    margin-bottom: 10%;
    padding-top: 10%;
  }

  .chartAndContent {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }

  .tCon {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    color: white;
    font-weight: 500;
  }

  .tCon p {
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 5px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 13px;
  }

  .chart {
    width: 60%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .boxWrapToken {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-left: 5%;
    padding-right: 5%;
    background: rgb(74, 175, 139);
    background: linear-gradient(0deg, rgba(74, 175, 139, 1) 0%, rgba(88, 176, 135, 1) 18%, rgba(74, 175, 128, 1) 100%);
  }

  .tH {
    color: #3b3b3b;
    font-weight: bold;
  }

  .cont {
    height: 100vh;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background: rgb(3, 23, 107);
    background: radial-gradient(circle, rgba(3, 23, 107, 1) 0%, rgba(3, 25, 54, 1) 74%, rgba(3, 20, 42, 1) 100%);
  }

  .web {
    width: 100%;
    height: 100%;
  }

  .cont .drip {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background: rgb(24, 70, 28);
    position: absolute;
    top: -100%;
    animation: falling 5s linear infinite;
  }

  @keyframes falling {
    0% {
      top: -100%;
    }

    50% {
      top: 0%;
    }

    80% {
      top: 80%;
    }

    100% {
      top: 100%;
    }
  }

  .cont .drip:nth-child(1) {
    border-color: black;
    height: 9px;
    width: 9px;
    animation-delay: -0.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 241px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(2) {
    border-color: black;
    height: 9px;
    width: 9px;
    animation-delay: -0.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 175px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(3) {
    border-color: black;
    height: 36px;
    width: 36px;
    animation-delay: -0.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 190px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(4) {
    border-color: black;
    height: 24px;
    width: 24px;
    animation-delay: -0.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 86px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(5) {
    border-color: black;
    height: 5px;
    width: 5px;
    animation-delay: -1s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 197px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(6) {
    border-color: black;
    height: 23px;
    width: 23px;
    animation-delay: -1.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 244px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(7) {
    border-color: black;
    height: 11px;
    width: 11px;
    animation-delay: -1.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 120px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(8) {
    border-color: black;
    height: 10px;
    width: 10px;
    animation-delay: -1.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 120px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(9) {
    border-color: black;
    height: 27px;
    width: 27px;
    animation-delay: -1.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 29px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(10) {
    border-color: black;
    height: 33px;
    width: 33px;
    animation-delay: -2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 154px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(11) {
    border-color: black;
    height: 39px;
    width: 39px;
    animation-delay: -2.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 109px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(12) {
    border-color: black;
    height: 23px;
    width: 23px;
    animation-delay: -2.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 272px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(13) {
    border-color: black;
    height: 13px;
    width: 13px;
    animation-delay: -2.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 41%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(14) {
    border-color: black;
    height: 21px;
    width: 21px;
    animation-delay: -2.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 71%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(15) {
    border-color: black;
    height: 1px;
    width: 1px;
    animation-delay: -3s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 95%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(16) {
    border-color: black;
    height: 12px;
    width: 12px;
    animation-delay: -3.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 76%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(17) {
    border-color: black;
    height: 12px;
    width: 12px;
    animation-delay: -3.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 71%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(18) {
    border-color: black;
    height: 38px;
    width: 38px;
    animation-delay: -3.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 46%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(19) {
    border-color: black;
    height: 1px;
    width: 1px;
    animation-delay: -3.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 41%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(20) {
    border-color: black;
    height: 19px;
    width: 19px;
    animation-delay: -4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 33%;
    margin-left: 60px;
  }


  .cont .drip:nth-child(21) {
    border-color: black;
    height: 9px;
    width: 9px;
    animation-delay: -0.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 42%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(22) {
    border-color: black;
    height: 9px;
    width: 9px;
    animation-delay: -0.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 58%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(23) {
    border-color: black;
    height: 36px;
    width: 36px;
    animation-delay: -0.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 49%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(24) {
    border-color: black;
    height: 24px;
    width: 24px;
    animation-delay: -0.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 73%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(25) {
    border-color: black;
    height: 5px;
    width: 5px;
    animation-delay: -1s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 83%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(26) {
    border-color: black;
    height: 23px;
    width: 23px;
    animation-delay: -1.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 28%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(27) {
    border-color: black;
    height: 11px;
    width: 11px;
    animation-delay: -1.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 14%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(28) {
    border-color: black;
    height: 10px;
    width: 10px;
    animation-delay: -1.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 43%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(29) {
    border-color: black;
    height: 27px;
    width: 27px;
    animation-delay: -1.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 68%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(30) {
    border-color: black;
    height: 33px;
    width: 33px;
    animation-delay: -2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 57%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(31) {
    border-color: black;
    height: 39px;
    width: 39px;
    animation-delay: -2.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 27%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(32) {
    border-color: black;
    height: 23px;
    width: 23px;
    animation-delay: -2.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 12%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(33) {
    border-color: black;
    height: 13px;
    width: 13px;
    animation-delay: -2.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 30%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(34) {
    border-color: black;
    height: 21px;
    width: 21px;
    animation-delay: -2.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 38%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(35) {
    border-color: black;
    height: 1px;
    width: 1px;
    animation-delay: -3s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 55%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(36) {
    border-color: black;
    height: 12px;
    width: 12px;
    animation-delay: -3.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 50%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(37) {
    border-color: black;
    height: 12px;
    width: 12px;
    animation-delay: -3.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 58%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(38) {
    border-color: black;
    height: 38px;
    width: 38px;
    animation-delay: -3.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 67%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(39) {
    border-color: black;
    height: 1px;
    width: 1px;
    animation-delay: -3.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 70%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(40) {
    border-color: black;
    height: 19px;
    width: 19px;
    animation-delay: -4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 90%;
    margin-left: 60px;
  }

  .donutAndInfo {
    display: flex;
    flex-flow: row nowrap;
  }

  .donut {
    width: 45px;
    height: 45px;
    margin-right: 2%;
  }

  .rmCon {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    width: 90%;
  }

  .phrases p {
    font-size: 18px;
    letter-spacing: 2px;

  }

  .shoe {
    width: 400px;
    height: 400px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .phrases {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: row nowrap;
    width: fit-content;
    margin-top: 3%;
  }

  .rmCon1 {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .teamCon {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    display: flex;
    flex-flow: column nowrap;

  }

  .teamCon img {
    width: 300px;
    height: 300px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    border: 3px solid white;
  }

  .memberDetails {
    color: white;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .memberName {
    font-size: 23px;
    text-align: center;
  }

  .memberCon {
    font-size: 20px;
    text-align: center;
  }


  * {
    box-sizing: border-box;
  }

  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 4rem;
    background-color: #aa5620;
    padding-left: 100%;
  }

  .ticker-wrap .ticker {
    display: inline-block;
    height: 4rem;
    line-height: 4rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 30s;
    animation-duration: 50s;
  }

  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 18px;
    color: #ffffff;
    font-weight: 800;
  }

  .shark {
    width: 400px;
    height: 572px;
    z-index: 1;
    margin-right: -3%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .teamMain {
    display: flex;
  }


  .memName {
    font-family: 'Poppins', sans-serif;
    color: #ffc000;
    font-size: 15px;
    text-align: center;
    padding-right: 2%;
    font-weight: 500;
  }

  .memNamePosition {
    color: #99bad6;
    font-size: 13px;
    text-align: center;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .tPic {
    width: 30px;
    height: 30px;
    cursor: pointer;
  }


  .man {
    width: 110px;
    height: 110px;
    border-radius: 100%;
    transition: transform .5s;
  }

  .man:hover {
    transform: scale(1.1);
    border: 2px solid #5496ec;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px #90bbf3;
  }

  .teamSection {
    display: none;
  }

  .teamSection2 {
    display: none;
  }

  .teamSectionMob {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    margin-top: 40px;

  }

  .teamSection2Mob {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    margin-top: 40px;
  }


  .tPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .subT {
    font-family: 'Poppins', sans-serif;
    color: #f9f305;
    font-size: 50px;
    text-align: left;
  }

  .manDiv {
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 15px;
  }

  .memName2 {
    color: rgb(255, 255, 255);
    text-align: center;
    font-weight: 500;
    text-shadow: 0 0 10px rgb(255, 255, 255);
  }

  .rmDetails p {
    color: rgb(209, 209, 209);
    padding-left: 20px;
    font-size: 45px;
  }

  .flowers {
    width: 100%;
    margin-top: -10%;
  }

  .rmPicAndCon {
    display: flex;
    justify-content: flex-start;
    margin-left: 20px;
  }

  .footPrint2 {
    width: 30px;
    height: 30px;
  }


  /* Background */
  .mainFoot {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    margin-top: -10%;
  }

  /* Footprint */
  .footprint {
    position: absolute;
    top: 20%;
    left: 0;
    animation: walk 5s steps(10, end) infinite, walkopacity 2s ease-out infinite;
    text-align: center;
  }

  /* Image Size */
  .foot {
    max-width: 20%;
  }

  /* Right Footprint */
  .rightFoot {
    margin: 5rem;
    animation-delay: 250ms;
  }

  /* Walking Animation */
  @keyframes walk {
    to {
      transform: translateX(100vw);
    }
  }

  /* Opacity During Walking */
  @keyframes walkopacity {

    0%,
    100% {
      opacity: 0;
    }

    50% {
      opacity: 0.4;
    }
  }

  .honeyComb {
    width: 450px;
    height: 345px;
    position: sticky;
    position: -webkit-sticky;
    left: 80%;
    bottom: 80px;
  }

  .dripCover {
    width: 100%;
  }

  .ball {
    width: 120px;
    height: 120px;
    animation: floating 5s ease-in-out infinite;
  }

  .ball2 {
    width: 120px;
    height: 120px;
    animation: floating2 5.25s ease-in-out infinite;
  }

  .ball3 {
    width: 120px;
    height: 120px;
    animation: floating3 5.5s ease-in-out infinite;
  }

  .ball4 {
    width: 130px;
    height: 130px;
    animation: floating4 2.1s ease-in-out infinite;
    margin-top: -200px;
    margin-left: 250px;
  }

  .ball5 {
    width: 130px;
    height: 130px;
    animation: floating4 2.3s ease-in-out infinite;
    margin-top: -400px;
    margin-left: 200px;
  }

  .ball6 {
    width: 130px;
    height: 130px;
    animation: floating4 2.8s ease-in-out infinite;
    margin-top: -1100px;
    margin-right: 600px;
  }

  .ball7 {
    width: 130px;
    height: 130px;
    animation: floating4 3s ease-in-out infinite;
    margin-top: -1100px;
    margin-left: 1200px;
  }

  /* Define the keyframes for the floating animation */
  @keyframes floating {
    0% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-10px);
    }

    100% {
      transform: translateY(0);
    }
  }

  @keyframes floating2 {
    0% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-10px);
    }

    100% {
      transform: translateY(0);
    }
  }

  @keyframes floating3 {
    0% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-10px);
    }

    100% {
      transform: translateY(0);
    }
  }

  @keyframes floating4 {
    0% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-10px);
    }

    100% {
      transform: translateY(0);
    }
  }

  .title {
    text-align: center;
    font-weight: bolder;
    font-size: 65px;
    font-family: 'Poppins', sans-serif;
    color: #d1d1e7;
    text-transform: uppercase;
    text-shadow: 0 0 10px #ffffff75;
    z-index: 1000;

  }

  .title2 {
    text-align: center;
    font-weight: bolder;
    font-size: 25px;
    font-family: 'Poppins', sans-serif;
    color: #d1d1e7;
    text-transform: uppercase;
    margin-top: -20px;
    text-shadow: 0 0 10px #ffffff75;
    z-index: 1000;

  }

  .title3 {
    font-size: 13px;
    color: rgb(202, 201, 201);
    width: 90%;
    justify-content: center;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    z-index: 1000;

  }

  .balls {
    display: flex;
    justify-content: center;
    margin-top: 200px;
  }

  .visionBox {
    color: white;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    width: 320px;
    padding: 20px;
    border-radius: 20px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 40px;
    font-size: 13px;
  }

  .visionBox:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }

  .missionBox {
    color: #a5f8f1;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    width: 320px;
    padding: 20px;
    border-radius: 20px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 40px;
    font-size: 13px;
  }

  .missionBox:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }

  .visionT {
    color: #a5f8f1;
    font-size: 30px;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    text-align: center;
  }

  .utilCon {
    color: white;
    font-size: 12px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    width: 95%;
  }

  .utilMainCon {
    width: 100%;
  }

  .utilMainCon img {
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }

  .tokenCon {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
  }

  .platformMain {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
  }

  .tokenCon p {
    color: white;
    font-weight: 500;
  }

  th {
    font-weight: bold;
    font-size: 18px;
    color: #e3bcfa;
  }

  td {
    color: white;
    font-size: 15px;
  }

  table {
    width: 85%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-bottom: 50px;
  }

  .chart {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .taxMain2 {
    display: none;
  }

  .taxMain2Mob {
    display: flex;
    margin-top: 50px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  #customers {
    font-family: 'Poppins', sans-serif;
    border-collapse: collapse;
    width: 100%;
  }

  #customers td,
  #customers th {
    border: 1px solid #ddd;
    padding: 5px;
    font-size: 7px;
  }

  #customers tr:nth-child(even) {
    background-color: #f2f2f225;
  }

  #customers th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #ffc000;
    color: rgb(0, 0, 0);
  }

  #customers2 {
    font-family: 'Poppins', sans-serif;
    border-collapse: collapse;
    width: 90%;
    margin-right: 60px;
    margin-left: auto;
    margin-right: auto;
  }

  #customers2 td,
  #customers2 th {
    border: 1px solid #ddd;
    padding: 8px;
    font-size: 7px;
  }

  #customers2 tr:nth-child(even) {
    background-color: #b7d5f725;
  }

  #customers2 th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #8297f7;
    color: rgb(0, 0, 0);
  }


  #customers3 {
    font-family: 'Poppins', sans-serif;
    border-collapse: collapse;
    width: 100%;
    margin-right: 60px;
  }

  #customers3 td,
  #customers3 th {
    border: 1px solid #ddd;
    padding: 8px;
    font-size: 7px;
  }

  #customers3 tr:nth-child(even) {
    background-color: #b7d5f725;
  }

  #customers3 th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #8297f7;
    color: rgb(0, 0, 0);
  }

  .platformMain2 {
    display: flex;
    justify-content: center;
    margin-top: 50px;
    flex-flow: column nowrap;
  }

  .platformMain3 {
    justify-content: center;
    margin-top: 50px;
  }

  .pT2 {
    font-family: 'Poppins', sans-serif;
    color: #ffc000;
    font-size: 16px;
  }

  .tableCon2 p {
    color: rgb(145, 196, 245);
    font-size: 14px;
  }

  .tableCon2 {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .tableCon3 {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .alien {

    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
    display: flex;
    transform-origin: center;
    transform: perspective(800px) rotateY(25deg);
    transition: 0.5s;
    background-color: rgba(85, 22, 22, 0.267);
    width: 100%;
  }

  .alien:hover {
    transform: perspective(800px) rotateY(0deg);
    opacity: 1;
  }

  .tableCon2Main {
    margin-top: auto;
    margin-bottom: auto;
  }

  .winnerNote {
    color: rgb(145, 196, 245);
    text-align: center;
    margin-top: 10px;
    font-size: 15px;
  }

  .nftT {
    font-weight: bold;
    color: white;
    font-size: 14px;
  }

  .nftT2 {
    color: white;
    font-size: 12px;

  }

  .nftCon {
    margin-bottom: 20px
  }

  #customers4 {
    font-family: 'Poppins', sans-serif;
    border-collapse: collapse;
    width: 100%;
  }

  #customers4 td,
  #customers4 th {
    border: 1px solid #ddd;
    padding: 8px;
    font-size: 7px;
  }

  #customers4 tr:nth-child(even) {
    background-color: #7ec5ff25;
  }

  #customers4 th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #ffc000;
    color: rgb(0, 0, 0);
  }

  .alien {
    width: 300px;
    height: 300px;
    border-radius: 20px;
    margin-left: auto;
    margin-right: auto;
  }

  .nftInfoT {
    color: #ffc000;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    font-size: 16px;
  }

  .nftInfo {
    color: rgb(162, 208, 252);
    font-size: 14px;
  }

  .note {
    width: 25px;
    height: 25px;
  }

  .com {
    color: #767698;
    font-size: 12px;
  }

  a {
    color: #767698;
    font-size: 12px;
    text-decoration: none;
  }

  a:hover {
    color: #a2a2c4;
    font-size: 12px;
    text-decoration: underline;
  }

  .comInfo {
    display: flex;
    justify-content: space-between;
    margin-top: 60px;
  }

  .lineF {
    width: 100%;
    height: 1px;
    background-color: rgba(75, 63, 78, 0.3);
    margin-bottom: 20px;
  }

  .lineF2 {
    width: 100%;
    height: 1px;
    background-color: rgba(75, 63, 78, 0.3);
    margin-top: 20px;
  }

  .cs {
    color: #5c6c70;
    margin-top: -20px;
    margin-bottom: 60px;
    font-size: 13px;
  }

}

@media screen and (min-width: 500px) and (max-width: 767px) {
  .lotteryMain4{
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  .lotteryMain2-2 {
    justify-content: center;
   display: flex;
   flex-flow: column nowrap;
  /* From https://css.glass */
  background: url('./assets/Container.png') no-repeat center center fixed; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  padding: 60px;
   border: 2px solid rgb(160, 160, 255);
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(120, 120, 226);
  padding-top: 10px;
  margin-bottom: 120px;
  margin-left: auto;
  margin-right: auto;
  width: 95%;
  }
  
/*.............. FAQ ..............*/
.totalTickets{
  color: white;
  font-size: 25px;
  margin-bottom: 80px;
}
.pomPrice{
  color: #4ac7fc;
  text-align: center;
  margin-top: 25px;
  font-size: 18px;
 }
/* CSS */
.button-29 {
  align-items: center;
  appearance: none;
  background-image: radial-gradient(100% 100% at 100% 0, #fff2b7 0, #ffd000 100%);
  border: 0;
  border-radius: 6px;
  box-shadow: rgba(45, 35, 66, .4) 0 2px 4px,rgba(45, 35, 66, .3) 0 7px 13px -3px,rgba(58, 65, 111, .5) 0 -3px 0 inset;
  box-sizing: border-box;
  color: rgb(0, 0, 0);
  cursor: pointer;
  display: inline-flex;
  font-family: "JetBrains Mono",monospace;
  height: 48px;
  justify-content: center;
  line-height: 1;
  list-style: none;
  overflow: hidden;
  padding-left: 16px;
  padding-right: 16px;
  position: relative;
  text-align: left;
  text-decoration: none;
  transition: box-shadow .15s,transform .15s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  will-change: box-shadow,transform;
  font-size: 18px;
  width: 250px;
}

.button-29:focus {
  box-shadow: #dab200 0 0 0 1.5px inset, rgba(45, 35, 66, .4) 0 2px 4px, rgba(45, 35, 66, .3) 0 7px 13px -3px, #f3cc1d 0 -3px 0 inset;
}

.button-29:hover {
  box-shadow: rgba(45, 35, 66, .4) 0 4px 8px, rgba(45, 35, 66, .3) 0 7px 13px -3px, #9c8001 0 -3px 0 inset;
  transform: translateY(-2px);
}

.ticketsSeparately{
  display: flex;
  flex-flow: column nowrap;
  margin-bottom: 10%;
}


summary {
  font-size: 20px;
  background: #af3d330c;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  color: rgb(0, 0, 0);
  padding: 1rem;
  margin-bottom: 1rem;
  outline: none;
  text-align: left;
  cursor: pointer;
  color: white;
  border: 2px solid rgb(197, 197, 197);
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(255, 255, 255, 0.61);
}

details>summary::-webkit-details-marker {
  display: none;
}

details[open] summary~* {
  animation: sweep .5s ease-in-out;
}

@keyframes sweep {
  0% {
    opacity: 0;
    margin-top: -10px
  }

  100% {
    opacity: 1;
    margin-top: 0px
  }
}

.accordin {
  width: 70%;
  color: white;
  display: inline-block;
  flex-direction: column;
  margin-bottom: 85px;
}

.faq__content {

  text-shadow: 0 0 10px #FFFFFF;
  padding-bottom: 20px;
  text-align: left;
  font-size: 17px;
}

.faqTitle {
  margin-bottom: 20px;
  text-align: center;
  font-size: 70px;
  color: white;
  font-family: 'Poppins', sans-serif;
}

.faqbg {
  width: 100%;
  text-align: left;
  padding-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  width: 100%;
  padding-bottom: 10%;

}

.lotterySubH{
  justify-content: center;
  display: flex;
  font-size: 30px;
  font-family: 'Poppins', sans-serif;
  text-align: left;
  color: white;
  font-weight: bold;
  margin-top: -20px;
    border: 2px solid rgb(160, 160, 255);
   box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(120, 120, 226);
   width: 300px;
   margin-left: auto;
   margin-right: auto;
   border-bottom: 0px;
}

.connect div:hover {
  color: #ffc000;
}

.boxWrap2Lottery {
  display: flex;
  flex-flow: column nowrap;
  padding-bottom: 5%;
  padding-top: 5%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  width: 100%;
  background: rgb(0, 29, 56);
  background: linear-gradient(10deg, rgb(0, 37, 54) 0%, rgb(18, 17, 1) 34%, rgba(1, 6, 18, 1) 100%);
  padding-left: 5%;
  padding-right: 5%;
  
}

.lottery{
  width: 270px;
  height: 300px;
  display: flex;
  margin-left: auto;
  margin-right: auto;
}

.ticketCategory{
  color: white;
  margin-left: auto;
  margin-right: auto;
}

.lotteryMain {
  margin-top: 30px;
   display: flex;
  flex-flow: column nowrap;
  margin-bottom: 5%;

}

.lotteryMain2 {
   justify-content: space-evenly;
  display: flex;
  flex-flow: column nowrap;
 /* From https://css.glass */
 background: url('./assets/Container.png') no-repeat center center fixed; 
 -webkit-background-size: cover;
 -moz-background-size: cover;
 -o-background-size: cover;
 background-size: cover;
 padding: 60px;
  border: 2px solid rgb(160, 160, 255);
 box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(120, 120, 226);
 padding-top: 10px;
 margin-bottom: 120px;
}

.lotteryMain3 {
  justify-content: space-evenly;
  flex-flow: column nowrap;
/* From https://css.glass */
background: url('./assets/Container.png') no-repeat center center fixed; 
-webkit-background-size: cover;
-moz-background-size: cover;
-o-background-size: cover;
background-size: cover;
padding: 60px;
 border: 2px solid rgb(160, 160, 255);
box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(120, 120, 226);
padding-top: 10px;
margin-bottom: 120px;
}

.drawBtn{
  background-color: white;
  color: black;
  border: none;
  padding: 15px;
  font-weight: 500;  
}

.autoPickedMain{
  background: url('./assets/Container.png') no-repeat center center fixed; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  padding: 60px;
   border: 2px solid rgb(160, 160, 255);
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(120, 120, 226);
   margin-bottom: 120px;
}

.autoPickedMain p{
  color: white;
  text-align: center;
}

.kave-btn {
  position: relative;
  border: solid 1px rgba(194, 191, 193, 0.3);
  background: none;
  color: white;
  letter-spacing: 1.8px;
  width: 250px;
  height: 50px;
  padding: 0;
  transition: all 0.25s ease;
  outline: none;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 60px;
}

.kave-btn:before {
  content: "";
  position: absolute;
  top: 0;
  left: 10%;
  width: 60px;
  height: 1px;
  transform: translateY(-1px);
  background: #93edfd;
  transition: all 0.25s ease;
}

.kave-btn:after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 10%;
  width: 60px;
  height: 1px;
  transform: translateY(1px);
  background: #00d6fc;
  transition: all 0.25s ease;
}

.kave-btn:hover {
  box-shadow: 1px 1px 8px rgba(0, 214, 252, 0.3);
  color: #00d6fc;
  text-shadow: 0 0 8px rgba(0, 214, 252, 0.4);
}

.kave-btn:hover.kave-btn:before {
  left: 0;
  width: 20px;
}

.kave-btn:hover.kave-btn:after {
  right: 0;
  width: 20px;
}

.kave-btn:hover .kave-line:before {
  bottom: 0;
}

.kave-btn:hover .kave-line:after {
  top: 0;
}

.kave-line:before {
  content: "";
  position: absolute;
  bottom: 30%;
  right: 0;
  width: 1px;
  height: 20px;
  transform: translateX(1px);
  background: #00d6fc;
  transition: all 0.25s ease;
}

.kave-line:after {
  content: "";
  position: absolute;
  top: 30%;
  left: 0;
  width: 1px;
  height: 20px;
  transform: translateX(-1px);
  background: #00d6fc;
  transition: all 0.25s ease;
}

  /* CSS */
.button-92 {
  --c: #fff;
  /* text color */
  background: linear-gradient(90deg, #0000 33%, #fff5, #0000 67%) var(--_p,100%)/300% no-repeat,
    #004dff;
  /* background color */
  color: #0000;
  border: none;
  transform: perspective(500px) rotateY(calc(20deg * var(--_i,-1)));
  text-shadow: calc(var(--_i,-1) * 0.08em) -.01em 0   var(--c),
    calc(var(--_i,-1) * -0.08em)  .01em 2px #0004;
  outline-offset: .1em;
  transition: 0.3s;
 }

.button-92:hover,
.button-92:focus-visible {
  --_p: 0%;
  --_i: 1;
}

.button-92:active {
  text-shadow: none;
  color: var(--c);
  box-shadow: inset 0 0 9e9q #0005;
  transition: 0s;
}

.button-92 {
  font-weight: bold;
  font-size: 2rem;
  margin: 0;
  cursor: pointer;
  padding: .1em .3em;
}
  
  .about {
    display: none;
  }
  .boxWrap2Mint {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: rgb(0, 41, 79);
    background: radial-gradient(circle, rgba(0, 41, 79, 1) 0%, rgba(0, 10, 25, 1) 74%, rgba(0, 10, 25, 1) 100%);
    padding-left: 5%;
    padding-right: 5%;
  }
  .special:hover {
    background-color: rgb(228, 194, 0);

  }

  .logoW {
    width: 25px;
    height: 25px;
  }

  .special:active {
    background-color: rgb(228, 194, 0);
    box-shadow: -0px 7px rgb(153, 131, 4);
    transform: translateY(4px);
  }

  .special2:hover {
    background-color: rgb(228, 194, 0);

  }

  .special2:active {
    background-color: rgb(228, 194, 0);
    box-shadow: -0px 7px rgb(153, 131, 4);
    transform: translateY(4px);
  }

  .placeAndWL {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 50px;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    background: rgba(82, 82, 82, 0.2);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 30px;
  }

  .special {
    font-size: 20px;
    margin-left: 30px;
    border-radius: 10px;
    background-color: gold;
    border: none;
    padding: 10px;
    box-shadow: -0px 5px rgb(194, 165, 5);
  }

  .special2 {
    font-size: 20px;
    border-radius: 10px;
    background-color: gold;
    border: none;
    padding: 10px;
    box-shadow: -0px 5px rgb(194, 165, 5);
  }

  .mintingAmount {
    border: black 2px solid;
    border-radius: 10px;
    box-shadow: -0px 5px black;
    padding: 10px;
  }

  h1 {
    text-align: center;
    margin-bottom: 200px;
    margin-top: 100px;
    font-size: 50px;
    font-weight: 600;
    color: #0b72d3;
  }

  .timeline-container {
    display: flex;
    justify-content: center;
    margin-top: 80px;
  }

  .timeline-container .vertical-scrollable-timeline {
    width: 700px;
    list-style-type: none;
  }

  .timeline-container .vertical-scrollable-timeline li {
    position: relative;
    padding: 35px 0px 125px 158px;
  }

  .timeline-container .vertical-scrollable-timeline li:last-child {
    margin-bottom: 0;
  }

  .timeline-container .vertical-scrollable-timeline li h2 {
    font-size: 18px;
    margin-bottom: 10px;
    color: #ffc000;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
  }

  .timeline-container .vertical-scrollable-timeline li p {
    line-height: 1.5;
    color: rgb(198, 221, 255);
    font-size: 15px;
  }

  .timeline-container .vertical-scrollable-timeline li p:last-child {
    margin-bottom: 0;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder {
    position: absolute;
    left: 0;
    top: 0;
    width: 104px;
    height: 104px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #c5c3cb;
    border-radius: 50%;
    z-index: 1;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder::before {
    content: "";
    width: 80px;
    height: 80px;
    border: 4px solid #fff;
    position: absolute;
    background-color: #c5c3cb;
    border-radius: 50%;
    z-index: -1;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder i {
    font-size: 25px;
    color: #fff;
  }

  .timeline-container .vertical-scrollable-timeline li::after {
    content: "";
    position: absolute;
    height: 0;
    width: 8px;
    background-color: #3f96e7;
    left: 48px;
    top: 34px;
    z-index: 0;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 8px;
    background-color: #c5c3cb;
    left: 48px;
    z-index: 0;
  }

  .timeline-container .vertical-scrollable-timeline li:last-child::before {
    content: unset;
  }

  .timeline-container .vertical-scrollable-timeline li.active .icon-holder {
    background-color: #ffc000;
  }

  .timeline-container .vertical-scrollable-timeline li.active .icon-holder::before {
    background-color: #4372c3;
  }

  .timeline-container .vertical-scrollable-timeline li.active::after {
    height: 100%;
  }

  .timeline-container .vertical-scrollable-timeline li.active:last-child::after {
    content: unset;
  }


  figure {
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
  }

  figure:nth-child(1) .rmDetails {
    transform: scale(0.6) rotate(-12.5deg);
  }

  figure:nth-child(2) .rmDetails {
    transform: scale(0.6) rotate(-9.7222222222deg);
  }

  figure:nth-child(3) .rmDetails {
    transform: scale(0.6) rotate(-6.9444444444deg);
  }

  figure:nth-child(4) .rmDetails {
    transform: scale(0.6) rotate(-4.1666666667deg);
  }

  figure:nth-child(5) .rmDetails {
    transform: scale(0.6) rotate(-1.3888888889deg);
  }

  figure:nth-child(6) .rmDetails {
    transform: scale(0.6) rotate(1.3888888889deg);
  }

  figure:nth-child(7) .rmDetails {
    transform: scale(0.6) rotate(4.1666666667deg);
  }

  figure:nth-child(8) .rmDetails {
    transform: scale(0.6) rotate(6.9444444444deg);
  }

  figure:nth-child(9) .rmDetails {
    transform: scale(0.6) rotate(9.7222222222deg);
  }

  figure:nth-child(10) .rmDetails {
    transform: scale(0.6) rotate(12.5deg);
  }

  figure .rmDetails {
    width: 100%;
    border-radius: 3px;
    background-color: black;
    padding: 50px;
    border: 5px solid gray;
  }

  .connect2Mobile {
    display: none;
  }

  .discordNone {
    transition: transform .2s;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: no-drop;
  }

  .discordNone:hover {
    transform: scale(1.1);
  }

  .linkedin {
    width: 30px;
    height: 30px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 10px;
    cursor: pointer;

  }

  .team1 {
    width: 50%;
  }

  .discord3 {
    display: none;
  }

  .discord4 {
    display: none;
  }

  .storyHRmM {
    display: none;
  }

  .iconsMob {
    display: none;
  }

  .mainBanner {
    display: none;
  }

  .icons {
    display: flex;
    flex-flow: row nowrap;
  }

  .cover52 {
    display: none;
  }

  .coverRm {
    width: 100vw;
  }

  .discordF2 {
    display: none;
  }

  .connectMobile {
    display: none;
  }

  .rightMobile {
    display: none;
  }

  .osPic {
    width: 30px;
    height: 30px;
    cursor: pointer;
    z-index: 10000;
  }

  .osPic2 {
    width: 40px;
    height: 40px;
    cursor: pointer;
  }

  .price {
    text-align: center;
    font-size: 20px;
    color: #aef6ff;
  }

  .discord2 {
    display: none;
  }

  .discord3 {
    padding-right: 20px;
    display: none;
  }


  .uRMobile {
    display: none;
  }

  .storyPicDivMobile {
    display: none;
  }

  .allWrap {

    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    contain: content;
  }

  .boxWrap2 {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background-color: black;
  }

  .boxWrap2Story {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: rgb(0, 88, 81);
    background: radial-gradient(circle, rgba(0, 88, 81, 1) 0%, rgba(0, 42, 47, 1) 34%, rgb(3, 14, 41) 100%);
    padding-left: 5%;
    padding-right: 5%;
  }

  .boxWrap2Utility {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: rgb(0, 45, 56);
    background: linear-gradient(0deg, rgba(0, 45, 56, 1) 0%, rgba(1, 6, 18, 1) 34%, rgba(1, 6, 18, 1) 100%);
    padding-left: 5%;
    padding-right: 5%;
  }

  .boxWrap2Team {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: rgb(0, 41, 79);
    background: radial-gradient(circle, rgba(0, 41, 79, 1) 0%, rgba(0, 10, 25, 1) 74%, rgba(0, 10, 25, 1) 100%);
    padding-left: 5%;
    padding-right: 5%;
  }

  .boxWrap2Platform {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: rgb(21, 38, 7);
    background: linear-gradient(309deg, rgba(21, 38, 7, 1) 0%, rgba(0, 10, 25, 1) 74%, rgba(0, 10, 25, 1) 100%);
    padding-left: 5%;
    padding-right: 5%;
  }

  .boxWrap2Tokenomic {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: rgb(0, 5, 27);
    background: linear-gradient(97deg, rgba(0, 5, 27, 1) 0%, rgba(43, 22, 56, 1) 74%, rgba(0, 5, 27, 1) 100%);
    padding-left: 5%;
    padding-right: 5%;
  }

  .sharks {
    width: 100%;
  }

  .boxWrapC {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    position: relative;

    padding-bottom: 5%;
  }

  .green {
    background-color: #97ba5e;

  }

  .aboutMob {
    margin-top: 30px;
    justify-content: space-evenly;
    display: flex;
    flex-flow: row nowrap;
  }

  .aboutVAndM {
    margin-top: 30px;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
  }

  .about2 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 90%;
  }

  .aboutImg {
    width: 50%;
    border: white 6px solid;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-bottom: 3%;
  }

  .aboutImg2 {
    width: 28%;
    height: 28%;
    border: white 6px solid;
    transition: transform .5s;
  }

  .aboutImg2:hover {
    transform: scale(1.2);
  }

  .utilityPics {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
  }

  .roadmapMain1 {
    top: 0;
    position: relative;
  }

  .storyPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .UtilDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .storyH {
    justify-content: left;
    display: flex;
    font-size: 15px;
    font-family: "Gordita";
    color: #00fae5;
    text-align: left;
  }

  .utilH {
    justify-content: left;
    display: flex;
    font-size: 15px;
    font-family: "Gordita";
    color: #00c8fa;
    text-align: left;
  }

  .TokenomicH {
    justify-content: left;
    display: flex;
    font-size: 15px;
    font-family: "Gordita";
    color: #c86ffc;
    text-align: left;
  }

  .platformH {
    justify-content: left;
    display: flex;
    font-size: 15px;
    font-family: "Gordita";
    color: #a2f55e;
    text-align: left;
  }

  .nftH {
    justify-content: left;
    display: flex;
    font-size: 15px;
    font-family: "Gordita";
    color: #0278ee;
    text-align: left;
  }

  .teamH {
    justify-content: left;
    display: flex;
    font-size: 15px;
    font-family: "Gordita";
    color: #ffc959;
    text-align: left;
  }

  .storyH2 {
    justify-content: left;
    display: flex;
    font-size: 30px;
    font-family: 'Poppins', sans-serif;
    text-align: left;
    color: white;
    font-weight: bold;
    margin-top: -20px;
  }

  .taxT {
    justify-content: center;
    display: flex;
    font-size: 20px;
    font-family: 'Poppins', sans-serif;
    text-align: left;
    color: white;
    font-weight: bold;
    margin-top: -20px;
  }

  .taxMain {
    margin-top: 80px;
  }

  .mintH {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    font-size: 90px;
    background: -webkit-linear-gradient(#f0d9a3, #ff9d01);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: "Gordita";
    -webkit-text-stroke-width: 3px;
    -webkit-text-stroke-color: black;
  }

  .storyRm img {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .tokenH {
    font-size: 70px;
    color: white;
    font-family: 'Poppins', sans-serif;
    text-align: center;
    margin-bottom: 2%;
  }


  .carouselH {
    font-size: 70px;
    color: white;
    font-family: 'Poppins', sans-serif;
    text-align: center;
    margin-bottom: 2%;
  }

  .rmH {
    font-size: 80px;
    color: white;
    font-family: 'Poppins', sans-serif;
    text-align: center;
    background-color: #fe8a00;
    padding-left: 20px;
    margin-bottom: 20px;
  }

  .rmH2 {
    color: grey;
    font-size: 18px;
    line-height: 30px;
  }

  .rmName {
    background: rgb(81, 190, 229);
    background: linear-gradient(0deg, rgba(81, 190, 229, 1) 2%, rgba(81, 190, 229, 1) 20%, rgba(6, 136, 198, 1) 63%, rgba(6, 136, 198, 1) 100%);
  }



  .storyCon {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 70%;
    margin-left: auto;
    margin-right: auto;

  }

  .storyCon-2 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    padding: 20px;
    border-radius: 50px;
    box-shadow: -10px 10px rgba(14, 21, 36, 0.363);
    background-color: rgba(30, 46, 80, 0.568);

  }

  .storyCon2 {
    font-size: 30px;
    font-family: 'Poppins', sans-serif;
    text-align: center;
  }

  .storyConP {
    font-size: 20px;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
  }

  .storyPic {
    width: 540px;
    height: 339px;
  }

  .storyRight {
    width: 45%;
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
  }

  .uR {
    width: 50%;
  }

  .intro {
    color: white;
    font-size: 100px;
    text-align: center;
    font-family: 'Riangriung', sans-serif;
  }

  .logoF {
    color: white;
    text-align: center;
    font-size: 50px;
    background: -webkit-linear-gradient(#f0d9a3, #ff9d01);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: "Gordita";
  }

  .logoF img {
    width: 50%;
  }

  .intro img {
    width: 500px;
    height: 500px;
  }

  .intro3 {
    font-style: italic;
    font-size: 20px;
    font-weight: 500;
  }

  .intro2 {
    color: rgb(0, 0, 0);
    font-size: 23px;
    text-align: left;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    font-weight: bold;
    width: 50%;
  }

  .mintDiv {
    padding-top: 3%;
    padding-bottom: 7%;
    width: 400px;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.493) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
    margin-bottom: 80px;
    border: 2px solid rgb(119, 222, 248);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(106, 184, 243);
    margin-top: 50px;
  }
  .errorMessage {
    margin-left: 3%;
    margin-top: 1%;
    font-size: 14px;
    color: #fc4741;
    text-align: center;
    text-shadow: -1px -1px 1px rgba(255, 255, 255, .1), 1px 1px 1px rgba(0, 0, 0, .5);
  }

  .loadingContainer {
    margin-top: 1%;
  }

  .loadingText {
    font-size: 20px;
    text-align: center;
    color: rgb(120, 184, 189);
    font-weight: bold;
  }

  .loadTextSub {
    text-align: center;
    color: rgb(157, 194, 199);
    font-size: 13px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;

  }

  .wPMobile {
    display: none;
  }

  .introductionMobile {
    display: none
  }

  .hl {
    color: #6ce34d;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
  }

  .right2 {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 1.5%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .discord {
    transition: transform .2s;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .discord:hover {
    transform: scale(1.1);
  }


  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(99, 31, 177);
  }



  .note {
    color: rgb(255, 255, 255);
  }

  .twitterSoc {
    margin-top: 10px;
    margin-right: 5px;
    /* z-index: -2;*/
    margin-left: 5px;

  }

  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/

  }

  /*.website{
    margin-top: 10px;
    margin-left: 10px;
   /* z-index: -2;*/
  /*color: #ffffffa2;
  }*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;

  }





  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    padding-left: 50px;
    padding-right: 50px;

    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);


  }

  .wallet:hover {
    background-color: white;
    color: rgb(73, 81, 190);
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-family: "Gordita";
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    display: none;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    font-family: "Gordita";
    align-items: center;
  }

  .connect div {
    margin-left: 15px;
    margin-right: 15px;
    font-family: "Gordita";
    color: #ffffff;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease;
    font-weight: 500;
  }


  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }




  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding-bottom: 50px;
    margin-left: auto;
    margin-right: auto;

  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-bottom: 20px;

  }

  .center {
    margin-top: auto;
    margin-bottom: auto;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }

  .btnfos-0-2 {
    font-size: 20px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 60px;
    height: 60px;
    border: none;
    font-family: 'Poppins', sans-serif;
    cursor: pointer;
    background-color: #3a9ae802;
    border: white 2px solid;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 20px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 60px;
    height: 60px;
    border: none;
    font-family: 'Poppins', sans-serif;
    cursor: pointer;
    background-color: #3a9ae802;
    border: white 2px solid;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }


  .btnfos-0-2:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(134, 134, 134, 0.288) -5px 5px;
  }


  .btnfos-0-2-2:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(134, 134, 134, 0.288) 5px 5px;
  }


  .btnfos-0-3 {
    margin-top: 20px;
    background-color: #6cfc19;
    color: #000000;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 28px;
    border-radius: 50px;
    padding-left: 30px;
    padding-right: 30px;
    transition: transform .2s;
    border: none;
    font-family: 'Poppins', sans-serif;
    transition: transform .2s;
  }

  .btnfos-0-3:hover {
    transform: scale(1.1);
  }

  .nftamount {
    color: cyan;
    font-size: 70px;
    font-family: 'Poppins', sans-serif;
   }

  .walletConnect {

    display: flex;
    justify-content: center;
  }

  .connectButton {

    font-size: 45px;
  }

  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;

  }

  .loadingContainer {
    text-align: center;
    padding-top: 20px;
  }

  .errorMessage {
    text-align: center;
    padding-top: 20px;
  }

  .successfully {
    text-align: center;
    padding-top: 20px;
    color: #1ee03e;
    font-family: 'Poppins', sans-serif;
    font-size: 25px;
    text-shadow: -1px -1px 1px rgba(255, 255, 255, .1), 1px 1px 1px rgba(0, 0, 0, .5);
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #000000;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
    border-bottom: 1px solid rgba(59, 45, 87, 0.863);
    padding: 20px;

  }

  .h1 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
    margin-top: auto;
    margin-bottom: auto;
  }

  .h2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    background-color: #5ebaad;

    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/

  }

  .introduction {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    z-index: 1000;
    background: rgba(3, 24, 107, 0.733);
    background: radial-gradient(circle, rgba(3, 24, 107, 0.747) 0%, rgba(3, 25, 54, 0.705) 74%, rgba(3, 20, 42, 0.781) 100%);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .wallet2 {
    z-index: 1000;
    background-color: #000000;
    color: #ffffff;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 20px;
    border-radius: 50px;
    width: 270px;
    transition: transform .2s;
    border: none;
    font-family: "Gordita";
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    transition: 0.5s ease;
    cursor: pointer;
    box-shadow: 0 3px #363636;
    letter-spacing: 2px;
  }

  .wallet2:hover {
    color: #000000;
    background-color: #ffffff;
  }

  .wallet2Btn {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    width: 400px;
    border: 2px solid #ffffff;
    margin-left: 10px;
    margin-right: 10px;
    border: 2px solid #2E8B57;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
  }

  .wallet2Btn:hover {
    color: rgb(156, 156, 156);
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }


  .wallet3 {
    background-image: linear-gradient(to right top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1);
    color: rgb(0, 0, 0);
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 30px;
    font-weight: bold;
    border-radius: 15px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 50px;
    padding-right: 50px;
    box-shadow: black 0px 5px;
  }

  .wallet3:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }

  @keyframes shake {

    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }

  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }

  .in2 {
    width: 100%;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .totalSupply {
    text-align: center;
    font-size: 50px;
    margin-left: auto;
    margin-right: auto;
    color: white;
    font-family: 'Poppins', sans-serif;
    text-shadow: 0 0 10px #ffffff93;
    letter-spacing: 1px;
    margin-top: 1%;
    font-weight: bold;
  }

  @keyframes Slide_Up {
    0% {
      transform: translateY(250px);
      opacity: 1;
    }

    10%,
    87.5% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Tag {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 1;
    }

    20%,
    92.5% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Buttons {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 0;
    }

    20% {
      opacity: 1;
    }

    25%,
    95% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  .copyright {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 18px;
    text-shadow: 0 0 10px #FFFFFF;
  }

  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }

  @keyframes shake {

    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }

  @keyframes Slide_Left {
    0% {
      transform: translateX(250px);
    }

    100% {
      transform: translateX(0);
    }
  }

  @keyframes Slide_Left_Right {

    0%,
    100% {
      transform: translateX(100px);
    }

    12.5%,
    75% {
      transform: translateX(0);
    }

    25% {
      transform: translateX(20px);
    }

  }

  @keyframes Opacity {

    0%,
    100% {
      opacity: 0;
    }

    25%,
    75% {
      opacity: 1;
    }


  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }

  @keyframes scaleIn {
    from {
      transform: scale(.5, .5);
      opacity: .5;
    }

    to {
      transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }

  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1, 1);
    }

    50% {
      transform: scale(1.05, 1.05);
    }

    100% {
      transform: scale(1, 1);
    }
  }

  .logo {
    width: 90px;
    height: 90px;
    margin-left: auto;
    margin-right: auto;
    border: #00c8fa 2px solid;
    justify-content: center;
    display: flex;
    border-radius: 100%;
  }

  .logo2 {
    width: 20%;
    cursor: pointer;
  }

  #fontSize {
    font-size: 12px;
    font-family: "Gordita";
    cursor: pointer;
    z-index: 10000;
    font-weight: 500;
  }

  #fontSize2 {
    font-size: 15px;
    font-family: "Gordita";
    cursor: pointer;
    z-index: 10000;
    font-weight: 500;
    color: white;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }

  #fontSize2:hover{
    color: #ffc000;

  }

  .parrotDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;

  }

  .pic1 {
    width: 32%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    left: 70%;
    bottom: 0;

  }

  .pic2 {
    width: 32%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    left: -5%;
    bottom: 0;

  }

  
  .cover {
    width: 100vw;
  }

  .cover2 {
    width: 100%;
  }

  .parrotPic {
    width: 100vw;
  }

  .parrot {
    width: 60%;
    border-radius: 30px;
  }

  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .rmMain {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: -100px;
  }

  .rmBox {
    text-align: right;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }

  .rmBox2 {
    text-align: right;
    width: 80%;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }

  .rmBox {
    font-weight: 700;
    color: rgb(58, 58, 58);
    margin-bottom: 60%;
  }

  .year {
    font-size: 18px;
    font-family: 'Poppins', sans-serif;
    color: rgb(8, 81, 94);
    letter-spacing: 1px;
  }


  .rmBoxMain2 {
    margin-top: 30%;
    width: 33.33%;
  }

  .rmBoxMain {
    width: max-content;
    justify-content: flex-start;
    width: 33.33%;

  }

  .rmBox2 {
    text-align: left;
    width: 30%;
  }

  .rmParrot {
    width: 130px;
    height: 186px;
    z-index: 1;
    justify-content: center;
    position: -webkit-sticky;
    position: sticky;
    top: 10%;
    margin-bottom: 25%;
    margin-right: auto;
    display: block;
    margin-left: 33%;
    margin-top: -50%;
  }

  .line {
    margin-left: auto;
    margin-right: auto;
    display: block;
    height: 270vh;
    margin-top: -30%;
  }

  .rmP {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 33.33%;
    height: fit-content;
  }

  .box {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: -15%;
  }

  .discordF {
    padding-left: 10px;
    padding-right: 10px;
  }

  .socialAndLogo {
    flex-flow: row nowrap;
    justify-content: space-between;
    display: flex;
    padding-left: 10%;
    padding-right: 10%;
    margin-bottom: 10%;
    padding-top: 10%;
  }

  .chartAndContent {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }

  .tCon {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    color: white;
    font-weight: 500;
  }

  .tCon p {
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 5px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 13px;
  }

  .chart {
    width: 60%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .boxWrapToken {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-left: 5%;
    padding-right: 5%;
    background: rgb(74, 175, 139);
    background: linear-gradient(0deg, rgba(74, 175, 139, 1) 0%, rgba(88, 176, 135, 1) 18%, rgba(74, 175, 128, 1) 100%);
  }

  .tH {
    color: #3b3b3b;
    font-weight: bold;
  }

  .cont {
    height: 100vh;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background: rgb(3, 23, 107);
    background: radial-gradient(circle, rgba(3, 23, 107, 1) 0%, rgba(3, 25, 54, 1) 74%, rgba(3, 20, 42, 1) 100%);
  }

  .web {
    width: 100%;
    height: 100%;
  }

  .cont .drip {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background: rgb(24, 70, 28);
    position: absolute;
    top: -100%;
    animation: falling 5s linear infinite;
  }

  @keyframes falling {
    0% {
      top: -100%;
    }

    50% {
      top: 0%;
    }

    80% {
      top: 80%;
    }

    100% {
      top: 100%;
    }
  }

  .cont .drip:nth-child(1) {
    border-color: black;
    height: 9px;
    width: 9px;
    animation-delay: -0.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 241px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(2) {
    border-color: black;
    height: 9px;
    width: 9px;
    animation-delay: -0.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 175px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(3) {
    border-color: black;
    height: 36px;
    width: 36px;
    animation-delay: -0.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 190px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(4) {
    border-color: black;
    height: 24px;
    width: 24px;
    animation-delay: -0.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 86px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(5) {
    border-color: black;
    height: 5px;
    width: 5px;
    animation-delay: -1s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 197px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(6) {
    border-color: black;
    height: 23px;
    width: 23px;
    animation-delay: -1.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 244px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(7) {
    border-color: black;
    height: 11px;
    width: 11px;
    animation-delay: -1.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 120px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(8) {
    border-color: black;
    height: 10px;
    width: 10px;
    animation-delay: -1.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 120px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(9) {
    border-color: black;
    height: 27px;
    width: 27px;
    animation-delay: -1.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 29px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(10) {
    border-color: black;
    height: 33px;
    width: 33px;
    animation-delay: -2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 154px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(11) {
    border-color: black;
    height: 39px;
    width: 39px;
    animation-delay: -2.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 109px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(12) {
    border-color: black;
    height: 23px;
    width: 23px;
    animation-delay: -2.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 272px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(13) {
    border-color: black;
    height: 13px;
    width: 13px;
    animation-delay: -2.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 41%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(14) {
    border-color: black;
    height: 21px;
    width: 21px;
    animation-delay: -2.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 71%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(15) {
    border-color: black;
    height: 1px;
    width: 1px;
    animation-delay: -3s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 95%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(16) {
    border-color: black;
    height: 12px;
    width: 12px;
    animation-delay: -3.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 76%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(17) {
    border-color: black;
    height: 12px;
    width: 12px;
    animation-delay: -3.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 71%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(18) {
    border-color: black;
    height: 38px;
    width: 38px;
    animation-delay: -3.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 46%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(19) {
    border-color: black;
    height: 1px;
    width: 1px;
    animation-delay: -3.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 41%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(20) {
    border-color: black;
    height: 19px;
    width: 19px;
    animation-delay: -4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 33%;
    margin-left: 60px;
  }


  .cont .drip:nth-child(21) {
    border-color: black;
    height: 9px;
    width: 9px;
    animation-delay: -0.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 42%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(22) {
    border-color: black;
    height: 9px;
    width: 9px;
    animation-delay: -0.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 58%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(23) {
    border-color: black;
    height: 36px;
    width: 36px;
    animation-delay: -0.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 49%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(24) {
    border-color: black;
    height: 24px;
    width: 24px;
    animation-delay: -0.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 73%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(25) {
    border-color: black;
    height: 5px;
    width: 5px;
    animation-delay: -1s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 83%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(26) {
    border-color: black;
    height: 23px;
    width: 23px;
    animation-delay: -1.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 28%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(27) {
    border-color: black;
    height: 11px;
    width: 11px;
    animation-delay: -1.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 14%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(28) {
    border-color: black;
    height: 10px;
    width: 10px;
    animation-delay: -1.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 43%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(29) {
    border-color: black;
    height: 27px;
    width: 27px;
    animation-delay: -1.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 68%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(30) {
    border-color: black;
    height: 33px;
    width: 33px;
    animation-delay: -2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 57%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(31) {
    border-color: black;
    height: 39px;
    width: 39px;
    animation-delay: -2.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 27%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(32) {
    border-color: black;
    height: 23px;
    width: 23px;
    animation-delay: -2.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 12%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(33) {
    border-color: black;
    height: 13px;
    width: 13px;
    animation-delay: -2.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 30%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(34) {
    border-color: black;
    height: 21px;
    width: 21px;
    animation-delay: -2.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 38%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(35) {
    border-color: black;
    height: 1px;
    width: 1px;
    animation-delay: -3s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 55%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(36) {
    border-color: black;
    height: 12px;
    width: 12px;
    animation-delay: -3.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 50%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(37) {
    border-color: black;
    height: 12px;
    width: 12px;
    animation-delay: -3.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 58%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(38) {
    border-color: black;
    height: 38px;
    width: 38px;
    animation-delay: -3.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 67%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(39) {
    border-color: black;
    height: 1px;
    width: 1px;
    animation-delay: -3.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 70%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(40) {
    border-color: black;
    height: 19px;
    width: 19px;
    animation-delay: -4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 90%;
    margin-left: 60px;
  }

  .donutAndInfo {
    display: flex;
    flex-flow: row nowrap;
  }

  .donut {
    width: 45px;
    height: 45px;
    margin-right: 2%;
  }

  .rmCon {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    width: 90%;
  }

  .phrases p {
    font-size: 18px;
    letter-spacing: 2px;

  }

  .shoe {
    width: 400px;
    height: 400px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .phrases {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: row nowrap;
    width: fit-content;
    margin-top: 3%;
  }

  .rmCon1 {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .teamCon {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    display: flex;
    flex-flow: column nowrap;

  }

  .teamCon img {
    width: 300px;
    height: 300px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    border: 3px solid white;
  }

  .memberDetails {
    color: white;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .memberName {
    font-size: 23px;
    text-align: center;
  }

  .memberCon {
    font-size: 20px;
    text-align: center;
  }


  * {
    box-sizing: border-box;
  }

  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 4rem;
    background-color: #aa5620;
    padding-left: 100%;
  }

  .ticker-wrap .ticker {
    display: inline-block;
    height: 4rem;
    line-height: 4rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 30s;
    animation-duration: 50s;
  }

  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 18px;
    color: #ffffff;
    font-weight: 800;
  }

  .shark {
    width: 400px;
    height: 572px;
    z-index: 1;
    margin-right: -3%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .teamMain {
    display: flex;
  }


  .memName {
    font-family: 'Poppins', sans-serif;
    color: #ffc000;
    font-size: 16px;
    text-align: center;
    padding-right: 2%;
    font-weight: 500;
  }

  .memNamePosition {
    color: #99bad6;
    font-size: 14px;
    text-align: center;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .tPic {
    width: 30px;
    height: 30px;
    cursor: pointer;
  }


  .man {
    width: 120px;
    height: 120px;
    border-radius: 100%;
    transition: transform .5s;
  }

  .man:hover {
    transform: scale(1.1);
    border: 2px solid #5496ec;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px #90bbf3;
  }

  .teamSection {
    display: none;
  }

  .teamSection2 {
    display: none;
  }

  .teamSectionMob {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    margin-top: 40px;

  }

  .teamSection2Mob {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    margin-top: 40px;
  }


  .tPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .subT {
    font-family: 'Poppins', sans-serif;
    color: #f9f305;
    font-size: 50px;
    text-align: left;
  }

  .manDiv {
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 15px;
  }

  .memName2 {
    color: rgb(255, 255, 255);
    text-align: center;
    font-weight: 500;
    text-shadow: 0 0 10px rgb(255, 255, 255);
  }

  .rmDetails p {
    color: rgb(209, 209, 209);
    padding-left: 20px;
    font-size: 45px;
  }

  .flowers {
    width: 100%;
    margin-top: -10%;
  }

  .rmPicAndCon {
    display: flex;
    justify-content: flex-start;
    margin-left: 20px;
  }

  .footPrint2 {
    width: 30px;
    height: 30px;
  }


  /* Background */
  .mainFoot {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    margin-top: -10%;
  }

  /* Footprint */
  .footprint {
    position: absolute;
    top: 20%;
    left: 0;
    animation: walk 5s steps(10, end) infinite, walkopacity 2s ease-out infinite;
    text-align: center;
  }

  /* Image Size */
  .foot {
    max-width: 20%;
  }

  /* Right Footprint */
  .rightFoot {
    margin: 5rem;
    animation-delay: 250ms;
  }

  /* Walking Animation */
  @keyframes walk {
    to {
      transform: translateX(100vw);
    }
  }

  /* Opacity During Walking */
  @keyframes walkopacity {

    0%,
    100% {
      opacity: 0;
    }

    50% {
      opacity: 0.4;
    }
  }

  .honeyComb {
    width: 450px;
    height: 345px;
    position: sticky;
    position: -webkit-sticky;
    left: 80%;
    bottom: 80px;
  }

  .dripCover {
    width: 100%;
  }

  .ball {
    width: 250px;
    height: 250px;
    animation: floating 5s ease-in-out infinite;
  }

  .ball2 {
    width: 250px;
    height: 250px;
    animation: floating2 5.25s ease-in-out infinite;
  }

  .ball3 {
    width: 250px;
    height: 250px;
    animation: floating3 5.5s ease-in-out infinite;
  }

  .ball4 {
    width: 130px;
    height: 130px;
    animation: floating4 2.1s ease-in-out infinite;
    margin-top: -200px;
    margin-left: 250px;
  }

  .ball5 {
    width: 130px;
    height: 130px;
    animation: floating4 2.3s ease-in-out infinite;
    margin-top: -400px;
    margin-left: 200px;
  }

  .ball6 {
    width: 130px;
    height: 130px;
    animation: floating4 2.8s ease-in-out infinite;
    margin-top: -1100px;
    margin-right: 600px;
  }

  .ball7 {
    width: 130px;
    height: 130px;
    animation: floating4 3s ease-in-out infinite;
    margin-top: -1100px;
    margin-left: 1200px;
  }

  /* Define the keyframes for the floating animation */
  @keyframes floating {
    0% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-10px);
    }

    100% {
      transform: translateY(0);
    }
  }

  @keyframes floating2 {
    0% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-10px);
    }

    100% {
      transform: translateY(0);
    }
  }

  @keyframes floating3 {
    0% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-10px);
    }

    100% {
      transform: translateY(0);
    }
  }

  @keyframes floating4 {
    0% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-10px);
    }

    100% {
      transform: translateY(0);
    }
  }

  .title {
    text-align: center;
    font-weight: bolder;
    font-size: 90px;
    font-family: 'Poppins', sans-serif;
    color: #d1d1e7;
    text-transform: uppercase;
    text-shadow: 0 0 10px #ffffff75;
    z-index: 1000;

  }

  .title2 {
    text-align: center;
    font-weight: bolder;
    font-size: 30px;
    font-family: 'Poppins', sans-serif;
    color: #d1d1e7;
    text-transform: uppercase;
    margin-top: -20px;
    text-shadow: 0 0 10px #ffffff75;
    z-index: 1000;

  }

  .title3 {
    font-size: 13px;
    color: rgb(202, 201, 201);
    width: 80%;
    justify-content: center;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    z-index: 1000;

  }

  .balls {
    display: flex;
    justify-content: center;
    margin-top: 90px;
  }

  .visionBox {
    color: white;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    width: 400px;
    padding: 20px;
    border-radius: 20px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 40px;
    font-size: 14px;
  }

  .visionBox:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }

  .missionBox {
    color: #a5f8f1;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    width: 400px;
    padding: 20px;
    border-radius: 20px;
    margin-left: auto;
    margin-right: auto;
    font-size: 14px;
  }

  .missionBox:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }

  .visionT {
    color: #a5f8f1;
    font-size: 30px;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    text-align: center;
  }

  .utilCon {
    color: white;
    font-size: 13px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  .utilMainCon {
    width: 100%;
  }

  .utilMainCon img {
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }

  .tokenCon {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
  }

  .platformMain {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
  }

  .tokenCon p {
    color: white;
    font-weight: 500;
  }

  th {
    font-weight: bold;
    font-size: 18px;
    color: #e3bcfa;
  }

  td {
    color: white;
    font-size: 15px;
  }

  table {
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-bottom: 50px;
  }

  .chart {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .taxMain2 {
    display: none;
  }

  .taxMain2Mob {
    display: flex;
    margin-top: 50px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  #customers {
    font-family: 'Poppins', sans-serif;
    border-collapse: collapse;
    width: 100%;
  }

  #customers td,
  #customers th {
    border: 1px solid #ddd;
    padding: 8px;
    font-size: 11px;
  }

  #customers tr:nth-child(even) {
    background-color: #f2f2f225;
  }

  #customers th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #ffc000;
    color: rgb(0, 0, 0);
  }

  #customers2 {
    font-family: 'Poppins', sans-serif;
    border-collapse: collapse;
    width: 90%;
    margin-right: 60px;
    margin-left: auto;
    margin-right: auto;
  }

  #customers2 td,
  #customers2 th {
    border: 1px solid #ddd;
    padding: 8px;
    font-size: 11px;
  }

  #customers2 tr:nth-child(even) {
    background-color: #b7d5f725;
  }

  #customers2 th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #8297f7;
    color: rgb(0, 0, 0);
  }


  #customers3 {
    font-family: 'Poppins', sans-serif;
    border-collapse: collapse;
    width: 100%;
    margin-right: 60px;
  }

  #customers3 td,
  #customers3 th {
    border: 1px solid #ddd;
    padding: 8px;
    font-size: 11px;
  }

  #customers3 tr:nth-child(even) {
    background-color: #b7d5f725;
  }

  #customers3 th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #8297f7;
    color: rgb(0, 0, 0);
  }

  .platformMain2 {
    display: flex;
    justify-content: center;
    margin-top: 50px;
    flex-flow: column nowrap;
  }

  .platformMain3 {
    justify-content: center;
    margin-top: 50px;
  }

  .pT2 {
    font-family: 'Poppins', sans-serif;
    color: #ffc000;
    font-size: 20px;
  }

  .tableCon2 p {
    color: rgb(145, 196, 245);
  }

  .tableCon2 {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .tableCon3 {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .alien {

    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
    display: flex;
    transform-origin: center;
    transform: perspective(800px) rotateY(25deg);
    transition: 0.5s;
    background-color: rgba(85, 22, 22, 0.267);
    width: 100%;
  }

  .alien:hover {
    transform: perspective(800px) rotateY(0deg);
    opacity: 1;
  }

  .tableCon2Main {
    margin-top: auto;
    margin-bottom: auto;
  }

  .winnerNote {
    color: rgb(145, 196, 245);
    text-align: center;
    margin-top: 10px;
  }

  .nftT {
    font-weight: bold;
    color: white;
    font-size: 16px;
  }

  .nftT2 {
    color: white;
    font-size: 14px;

  }

  .nftCon {
    margin-bottom: 20px
  }

  #customers4 {
    font-family: 'Poppins', sans-serif;
    border-collapse: collapse;
    width: 100%;
  }

  #customers4 td,
  #customers4 th {
    border: 1px solid #ddd;
    padding: 8px;
    font-size: 11px;
  }

  #customers4 tr:nth-child(even) {
    background-color: #7ec5ff25;
  }

  #customers4 th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #ffc000;
    color: rgb(0, 0, 0);
  }

  .alien {
    width: 300px;
    height: 300px;
    border-radius: 20px;
    margin-left: auto;
    margin-right: auto;
  }

  .nftInfoT {
    color: #ffc000;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    font-size: 18px;
  }

  .nftInfo {
    color: rgb(162, 208, 252);
    font-size: 15px;
  }

  .note {
    width: 25px;
    height: 25px;
  }

  .com {
    color: #767698;
    font-size: 12px;
  }

  a {
    color: #767698;
    font-size: 12px;
    text-decoration: none;
  }

  a:hover {
    color: #a2a2c4;
    font-size: 12px;
    text-decoration: underline;
  }

  .comInfo {
    display: flex;
    justify-content: space-between;
    margin-top: 60px;
  }

  .lineF {
    width: 100%;
    height: 1px;
    background-color: rgba(75, 63, 78, 0.3);
    margin-bottom: 20px;
  }

  .lineF2 {
    width: 100%;
    height: 1px;
    background-color: rgba(75, 63, 78, 0.3);
    margin-top: 20px;
  }

  .cs {
    color: #5c6c70;
    margin-top: -20px;
    margin-bottom: 60px;
    font-size: 14px;
  }

}

@media screen and (min-width: 768px) and (max-width: 991px) {
  #fontSize2{
    display: none;
  }
  
  .lotteryMain4{
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  .lotteryMain2-2 {
    justify-content: center;
   display: flex;
   flex-flow: column nowrap;
  /* From https://css.glass */
  background: url('./assets/Container.png') no-repeat center center fixed; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  padding: 60px;
   border: 2px solid rgb(160, 160, 255);
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(120, 120, 226);
  padding-top: 10px;
  margin-bottom: 120px;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
  }
  
  .totalTickets{
    color: white;
    font-size: 25px;
    margin-bottom: 80px;
  }

  .pomPrice{
    color: #4ac7fc;
    text-align: center;
    margin-top: 25px;
    font-size: 18px;
   }
/* CSS */
.button-29 {
  align-items: center;
  appearance: none;
  background-image: radial-gradient(100% 100% at 100% 0, #fff2b7 0, #ffd000 100%);
  border: 0;
  border-radius: 6px;
  box-shadow: rgba(45, 35, 66, .4) 0 2px 4px,rgba(45, 35, 66, .3) 0 7px 13px -3px,rgba(58, 65, 111, .5) 0 -3px 0 inset;
  box-sizing: border-box;
  color: rgb(0, 0, 0);
  cursor: pointer;
  display: inline-flex;
  font-family: "JetBrains Mono",monospace;
  height: 48px;
  justify-content: center;
  line-height: 1;
  list-style: none;
  overflow: hidden;
  padding-left: 16px;
  padding-right: 16px;
  position: relative;
  text-align: left;
  text-decoration: none;
  transition: box-shadow .15s,transform .15s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  will-change: box-shadow,transform;
  font-size: 18px;
  width: 250px;
}

.button-29:focus {
  box-shadow: #dab200 0 0 0 1.5px inset, rgba(45, 35, 66, .4) 0 2px 4px, rgba(45, 35, 66, .3) 0 7px 13px -3px, #f3cc1d 0 -3px 0 inset;
}

.button-29:hover {
  box-shadow: rgba(45, 35, 66, .4) 0 4px 8px, rgba(45, 35, 66, .3) 0 7px 13px -3px, #9c8001 0 -3px 0 inset;
  transform: translateY(-2px);
}

/*.............. FAQ ..............*/

.ticketsSeparately{
  display: flex;
  flex-flow: column nowrap;
  margin-bottom: 10%;
}


summary {
  font-size: 20px;
  background: #af3d330c;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  color: rgb(0, 0, 0);
  padding: 1rem;
  margin-bottom: 1rem;
  outline: none;
  text-align: left;
  cursor: pointer;
  color: white;
  border: 2px solid rgb(197, 197, 197);
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(255, 255, 255, 0.61);
}

details>summary::-webkit-details-marker {
  display: none;
}

details[open] summary~* {
  animation: sweep .5s ease-in-out;
}

@keyframes sweep {
  0% {
    opacity: 0;
    margin-top: -10px
  }

  100% {
    opacity: 1;
    margin-top: 0px
  }
}

.accordin {
  width: 70%;
  color: white;
  display: inline-block;
  flex-direction: column;
  margin-bottom: 85px;
}

.faq__content {

  text-shadow: 0 0 10px #FFFFFF;
  padding-bottom: 20px;
  text-align: left;
  font-size: 17px;
}

.faqTitle {
  margin-bottom: 20px;
  text-align: center;
  font-size: 70px;
  color: white;
  font-family: 'Poppins', sans-serif;
}

.faqbg {
  width: 100%;
  text-align: left;
  padding-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  width: 100%;
  padding-bottom: 10%;

}

.lotterySubH{
  justify-content: center;
  display: flex;
  font-size: 30px;
  font-family: 'Poppins', sans-serif;
  text-align: left;
  color: white;
  font-weight: bold;
  margin-top: -20px;
    border: 2px solid rgb(160, 160, 255);
   box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(120, 120, 226);
   width: 400px;
   margin-left: auto;
   margin-right: auto;
   border-bottom: 0px;
}

.connect div:hover {
  color: #ffc000;
}

.boxWrap2Lottery {
  display: flex;
  flex-flow: column nowrap;
  padding-bottom: 5%;
  padding-top: 5%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  width: 100%;
  background: rgb(0, 29, 56);
  background: linear-gradient(10deg, rgb(0, 37, 54) 0%, rgb(18, 17, 1) 34%, rgba(1, 6, 18, 1) 100%);
  padding-left: 5%;
  padding-right: 5%;
  
}

.lottery{
  width: 270px;
  height: 300px;
  display: flex;
  margin-left: auto;
  margin-right: auto;
}

.ticketCategory{
  color: white;
  margin-left: auto;
  margin-right: auto;
}

.lotteryMain {
  margin-top: 30px;
   display: flex;
  flex-flow: column nowrap;
  margin-bottom: 5%;

}

.lotteryMain2 {
   justify-content: space-evenly;
  display: flex;
  flex-flow: column nowrap;
 /* From https://css.glass */
 background: url('./assets/Container.png') no-repeat center center fixed; 
 -webkit-background-size: cover;
 -moz-background-size: cover;
 -o-background-size: cover;
 background-size: cover;
 padding: 60px;
  border: 2px solid rgb(160, 160, 255);
 box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(120, 120, 226);
 padding-top: 10px;
 margin-bottom: 120px;
}

.lotteryMain3 {
  justify-content: space-evenly;
  flex-flow: column nowrap;
/* From https://css.glass */
background: url('./assets/Container.png') no-repeat center center fixed; 
-webkit-background-size: cover;
-moz-background-size: cover;
-o-background-size: cover;
background-size: cover;
padding: 60px;
 border: 2px solid rgb(160, 160, 255);
box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(120, 120, 226);
padding-top: 10px;
margin-bottom: 120px;
}

.drawBtn{
  background-color: white;
  color: black;
  border: none;
  padding: 15px;
  font-weight: 500;  
}

.autoPickedMain{
  background: url('./assets/Container.png') no-repeat center center fixed; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  padding: 60px;
   border: 2px solid rgb(160, 160, 255);
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(120, 120, 226);
   margin-bottom: 120px;
}

.autoPickedMain p{
  color: white;
  text-align: center;
}

.kave-btn {
  position: relative;
  border: solid 1px rgba(194, 191, 193, 0.3);
  background: none;
  color: white;
  letter-spacing: 1.8px;
  width: 250px;
  height: 50px;
  padding: 0;
  transition: all 0.25s ease;
  outline: none;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 60px;
}

.kave-btn:before {
  content: "";
  position: absolute;
  top: 0;
  left: 10%;
  width: 60px;
  height: 1px;
  transform: translateY(-1px);
  background: #93edfd;
  transition: all 0.25s ease;
}

.kave-btn:after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 10%;
  width: 60px;
  height: 1px;
  transform: translateY(1px);
  background: #00d6fc;
  transition: all 0.25s ease;
}

.kave-btn:hover {
  box-shadow: 1px 1px 8px rgba(0, 214, 252, 0.3);
  color: #00d6fc;
  text-shadow: 0 0 8px rgba(0, 214, 252, 0.4);
}

.kave-btn:hover.kave-btn:before {
  left: 0;
  width: 20px;
}

.kave-btn:hover.kave-btn:after {
  right: 0;
  width: 20px;
}

.kave-btn:hover .kave-line:before {
  bottom: 0;
}

.kave-btn:hover .kave-line:after {
  top: 0;
}

.kave-line:before {
  content: "";
  position: absolute;
  bottom: 30%;
  right: 0;
  width: 1px;
  height: 20px;
  transform: translateX(1px);
  background: #00d6fc;
  transition: all 0.25s ease;
}

.kave-line:after {
  content: "";
  position: absolute;
  top: 30%;
  left: 0;
  width: 1px;
  height: 20px;
  transform: translateX(-1px);
  background: #00d6fc;
  transition: all 0.25s ease;
}

  /* CSS */
  .button-92 {
    --c: #fff;
    /* text color */
    background: linear-gradient(90deg, #0000 33%, #fff5, #0000 67%) var(--_p,100%)/300% no-repeat,
      #004dff;
    /* background color */
    color: #0000;
    border: none;
    transform: perspective(500px) rotateY(calc(20deg * var(--_i,-1)));
    text-shadow: calc(var(--_i,-1) * 0.08em) -.01em 0   var(--c),
      calc(var(--_i,-1) * -0.08em)  .01em 2px #0004;
    outline-offset: .1em;
    transition: 0.3s;
  }

.button-92:hover,
.button-92:focus-visible {
  --_p: 0%;
  --_i: 1;
}

.button-92:active {
  text-shadow: none;
  color: var(--c);
  box-shadow: inset 0 0 9e9q #0005;
  transition: 0s;
}

.button-92 {
  font-weight: bold;
  font-size: 2rem;
  margin: 0;
  cursor: pointer;
  padding: .1em .3em;
}
  
  .aboutMob {
    display: none;
  }
  .boxWrap2Mint {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: rgb(0, 41, 79);
    background: radial-gradient(circle, rgba(0, 41, 79, 1) 0%, rgba(0, 10, 25, 1) 74%, rgba(0, 10, 25, 1) 100%);
    padding-left: 5%;
    padding-right: 5%;
  }
  .special:hover {
    background-color: rgb(228, 194, 0);

  }

  .taxMain2Mob {
    display: none;
  }

  .teamSectionMob {
    display: none;
  }

  .teamSection2Mob {
    display: none;
  }

  .logoW {
    width: 30px;
    height: 30px;
  }

  .special:active {
    background-color: rgb(228, 194, 0);
    box-shadow: -0px 7px rgb(153, 131, 4);
    transform: translateY(4px);
  }

  .special2:hover {
    background-color: rgb(228, 194, 0);

  }

  .special2:active {
    background-color: rgb(228, 194, 0);
    box-shadow: -0px 7px rgb(153, 131, 4);
    transform: translateY(4px);
  }

  .placeAndWL {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 50px;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    background: rgba(82, 82, 82, 0.2);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 30px;
  }

  .special {
    font-size: 20px;
    margin-left: 30px;
    border-radius: 10px;
    background-color: gold;
    border: none;
    padding: 10px;
    box-shadow: -0px 5px rgb(194, 165, 5);
  }

  .special2 {
    font-size: 20px;
    border-radius: 10px;
    background-color: gold;
    border: none;
    padding: 10px;
    box-shadow: -0px 5px rgb(194, 165, 5);
  }

  .mintingAmount {
    border: black 2px solid;
    border-radius: 10px;
    box-shadow: -0px 5px black;
    padding: 10px;
  }

  h1 {
    text-align: center;
    margin-bottom: 200px;
    margin-top: 100px;
    font-size: 50px;
    font-weight: 600;
    color: #0b72d3;
  }

  .timeline-container {
    display: flex;
    justify-content: center;
    margin-top: 80px;
  }

  .timeline-container .vertical-scrollable-timeline {
    width: 700px;
    list-style-type: none;
  }

  .timeline-container .vertical-scrollable-timeline li {
    position: relative;
    padding: 35px 0px 125px 158px;
  }

  .timeline-container .vertical-scrollable-timeline li:last-child {
    margin-bottom: 0;
  }

  .timeline-container .vertical-scrollable-timeline li h2 {
    font-size: 18px;
    margin-bottom: 10px;
    color: #ffc000;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
  }

  .timeline-container .vertical-scrollable-timeline li p {
    line-height: 1.5;
    color: rgb(198, 221, 255);
    font-size: 15px;
  }

  .timeline-container .vertical-scrollable-timeline li p:last-child {
    margin-bottom: 0;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder {
    position: absolute;
    left: 0;
    top: 0;
    width: 104px;
    height: 104px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #c5c3cb;
    border-radius: 50%;
    z-index: 1;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder::before {
    content: "";
    width: 80px;
    height: 80px;
    border: 4px solid #fff;
    position: absolute;
    background-color: #c5c3cb;
    border-radius: 50%;
    z-index: -1;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder i {
    font-size: 25px;
    color: #fff;
  }

  .timeline-container .vertical-scrollable-timeline li::after {
    content: "";
    position: absolute;
    height: 0;
    width: 8px;
    background-color: #3f96e7;
    left: 48px;
    top: 34px;
    z-index: 0;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 8px;
    background-color: #c5c3cb;
    left: 48px;
    z-index: 0;
  }

  .timeline-container .vertical-scrollable-timeline li:last-child::before {
    content: unset;
  }

  .timeline-container .vertical-scrollable-timeline li.active .icon-holder {
    background-color: #ffc000;
  }

  .timeline-container .vertical-scrollable-timeline li.active .icon-holder::before {
    background-color: #4372c3;
  }

  .timeline-container .vertical-scrollable-timeline li.active::after {
    height: 100%;
  }

  .timeline-container .vertical-scrollable-timeline li.active:last-child::after {
    content: unset;
  }


  figure {
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
  }

  figure:nth-child(1) .rmDetails {
    transform: scale(0.6) rotate(-12.5deg);
  }

  figure:nth-child(2) .rmDetails {
    transform: scale(0.6) rotate(-9.7222222222deg);
  }

  figure:nth-child(3) .rmDetails {
    transform: scale(0.6) rotate(-6.9444444444deg);
  }

  figure:nth-child(4) .rmDetails {
    transform: scale(0.6) rotate(-4.1666666667deg);
  }

  figure:nth-child(5) .rmDetails {
    transform: scale(0.6) rotate(-1.3888888889deg);
  }

  figure:nth-child(6) .rmDetails {
    transform: scale(0.6) rotate(1.3888888889deg);
  }

  figure:nth-child(7) .rmDetails {
    transform: scale(0.6) rotate(4.1666666667deg);
  }

  figure:nth-child(8) .rmDetails {
    transform: scale(0.6) rotate(6.9444444444deg);
  }

  figure:nth-child(9) .rmDetails {
    transform: scale(0.6) rotate(9.7222222222deg);
  }

  figure:nth-child(10) .rmDetails {
    transform: scale(0.6) rotate(12.5deg);
  }

  figure .rmDetails {
    width: 100%;
    border-radius: 3px;
    background-color: black;
    padding: 50px;
    border: 5px solid gray;
  }

  .connect2Mobile {
    display: none;
  }

  .discordNone {
    transition: transform .2s;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: no-drop;
  }

  .discordNone:hover {
    transform: scale(1.1);
  }

  .linkedin {
    width: 30px;
    height: 30px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 10px;
    cursor: pointer;

  }

  .team1 {
    width: 50%;
  }

  .discord3 {
    display: none;
  }

  .discord4 {
    display: none;
  }

  .storyHRmM {
    display: none;
  }

  .iconsMob {
    display: none;
  }

  .mainBanner {
    display: none;
  }

  .icons {
    display: flex;
    flex-flow: row nowrap;
  }

  .cover52 {
    display: none;
  }

  .coverRm {
    width: 100vw;
  }

  .discordF2 {
    display: none;
  }

  .connectMobile {
    display: none;
  }

  .rightMobile {
    display: none;
  }

  .osPic {
    width: 30px;
    height: 30px;
    cursor: pointer;
    z-index: 10000;
  }

  .osPic2 {
    width: 40px;
    height: 40px;
    cursor: pointer;
  }

  .price {
    text-align: center;
    font-size: 22px;
    color: #aef6ff;
  }

  .discord2 {
    display: none;
  }

  .discord3 {
    padding-right: 20px;
    display: none;
  }


  .uRMobile {
    display: none;
  }

  .storyPicDivMobile {
    display: none;
  }

  .allWrap {

    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    contain: content;
  }

  .boxWrap2 {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background-color: black;
  }

  .boxWrap2Story {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: rgb(0, 88, 81);
    background: radial-gradient(circle, rgba(0, 88, 81, 1) 0%, rgba(0, 42, 47, 1) 34%, rgb(3, 14, 41) 100%);
    padding-left: 5%;
    padding-right: 5%;
  }

  .boxWrap2Utility {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: rgb(0, 45, 56);
    background: linear-gradient(0deg, rgba(0, 45, 56, 1) 0%, rgba(1, 6, 18, 1) 34%, rgba(1, 6, 18, 1) 100%);
    padding-left: 5%;
    padding-right: 5%;
  }

  .boxWrap2Team {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: rgb(0, 41, 79);
    background: radial-gradient(circle, rgba(0, 41, 79, 1) 0%, rgba(0, 10, 25, 1) 74%, rgba(0, 10, 25, 1) 100%);
    padding-left: 5%;
    padding-right: 5%;
  }

  .boxWrap2Platform {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: rgb(21, 38, 7);
    background: linear-gradient(309deg, rgba(21, 38, 7, 1) 0%, rgba(0, 10, 25, 1) 74%, rgba(0, 10, 25, 1) 100%);
    padding-left: 5%;
    padding-right: 5%;
  }

  .boxWrap2Tokenomic {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: rgb(0, 5, 27);
    background: linear-gradient(97deg, rgba(0, 5, 27, 1) 0%, rgba(43, 22, 56, 1) 74%, rgba(0, 5, 27, 1) 100%);
    padding-left: 5%;
    padding-right: 5%;
  }

  .sharks {
    width: 100%;
  }

  .boxWrapC {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    position: relative;

    padding-bottom: 5%;
  }

  .green {
    background-color: #97ba5e;

  }

  .about {
    margin-top: 30px;
    justify-content: space-evenly;
    display: flex;
    flex-flow: row nowrap;
  }

  .aboutVAndM {
    margin-top: 30px;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
  }

  .about2 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 90%;
  }

  .aboutImg {
    width: 50%;
    border: white 6px solid;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-bottom: 3%;
  }

  .aboutImg2 {
    width: 28%;
    height: 28%;
    border: white 6px solid;
    transition: transform .5s;
  }

  .aboutImg2:hover {
    transform: scale(1.2);
  }

  .utilityPics {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
  }

  .roadmapMain1 {
    top: 0;
    position: relative;
  }

  .storyPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .UtilDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .storyH {
    justify-content: left;
    display: flex;
    font-size: 16px;
    font-family: "Gordita";
    color: #00fae5;
    text-align: left;
  }

  .utilH {
    justify-content: left;
    display: flex;
    font-size: 16px;
    font-family: "Gordita";
    color: #00c8fa;
    text-align: left;
  }

  .TokenomicH {
    justify-content: left;
    display: flex;
    font-size: 16px;
    font-family: "Gordita";
    color: #c86ffc;
    text-align: left;
  }

  .platformH {
    justify-content: left;
    display: flex;
    font-size: 16px;
    font-family: "Gordita";
    color: #a2f55e;
    text-align: left;
  }

  .nftH {
    justify-content: left;
    display: flex;
    font-size: 16px;
    font-family: "Gordita";
    color: #0278ee;
    text-align: left;
  }

  .teamH {
    justify-content: left;
    display: flex;
    font-size: 16px;
    font-family: "Gordita";
    color: #ffc959;
    text-align: left;
  }

  .storyH2 {
    justify-content: left;
    display: flex;
    font-size: 40px;
    font-family: 'Poppins', sans-serif;
    text-align: left;
    color: white;
    font-weight: bold;
    margin-top: -20px;
  }

  .taxT {
    justify-content: center;
    display: flex;
    font-size: 30px;
    font-family: 'Poppins', sans-serif;
    text-align: left;
    color: white;
    font-weight: bold;
    margin-top: -20px;
  }

  .taxMain {
    margin-top: 150px;
  }

  .mintH {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    font-size: 90px;
    background: -webkit-linear-gradient(#f0d9a3, #ff9d01);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: "Gordita";
    -webkit-text-stroke-width: 3px;
    -webkit-text-stroke-color: black;
  }

  .storyRm img {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .tokenH {
    font-size: 70px;
    color: white;
    font-family: 'Poppins', sans-serif;
    text-align: center;
    margin-bottom: 2%;
  }


  .carouselH {
    font-size: 70px;
    color: white;
    font-family: 'Poppins', sans-serif;
    text-align: center;
    margin-bottom: 2%;
  }

  .rmH {
    font-size: 80px;
    color: white;
    font-family: 'Poppins', sans-serif;
    text-align: center;
    background-color: #fe8a00;
    padding-left: 20px;
    margin-bottom: 20px;
  }

  .rmH2 {
    color: grey;
    font-size: 18px;
    line-height: 30px;
  }

  .rmName {
    background: rgb(81, 190, 229);
    background: linear-gradient(0deg, rgba(81, 190, 229, 1) 2%, rgba(81, 190, 229, 1) 20%, rgba(6, 136, 198, 1) 63%, rgba(6, 136, 198, 1) 100%);
  }



  .storyCon {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 70%;
    margin-left: auto;
    margin-right: auto;

  }

  .storyCon-2 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    padding: 20px;
    border-radius: 50px;
    box-shadow: -10px 10px rgba(14, 21, 36, 0.363);
    background-color: rgba(30, 46, 80, 0.568);

  }

  .storyCon2 {
    font-size: 30px;
    font-family: 'Poppins', sans-serif;
    text-align: center;
  }

  .storyConP {
    font-size: 20px;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
  }

  .storyPic {
    width: 540px;
    height: 339px;
  }

  .storyRight {
    width: 45%;
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
  }

  .uR {
    width: 50%;
  }

  .intro {
    color: white;
    font-size: 100px;
    text-align: center;
    font-family: 'Riangriung', sans-serif;
  }

  .logoF {
    color: white;
    text-align: center;
    font-size: 50px;
    background: -webkit-linear-gradient(#f0d9a3, #ff9d01);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: "Gordita";
  }

  .logoF img {
    width: 50%;
  }

  .intro img {
    width: 500px;
    height: 500px;
  }

  .intro3 {
    font-style: italic;
    font-size: 20px;
    font-weight: 500;
  }

  .intro2 {
    color: rgb(0, 0, 0);
    font-size: 23px;
    text-align: left;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    font-weight: bold;
    width: 50%;
  }

  .mintDiv {
    padding-top: 3%;
    padding-bottom: 7%;
    width: 630px;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.493) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
    margin-bottom: 80px;
    border: 2px solid rgb(119, 222, 248);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(106, 184, 243);
    margin-top: 50px;
  }

  .errorMessage {
    margin-left: 3%;
    margin-top: 1%;
    font-size: 18px;
    color: #fc4741;
    text-align: center;
    text-shadow: -1px -1px 1px rgba(255, 255, 255, .1), 1px 1px 1px rgba(0, 0, 0, .5);
  }

  .loadingContainer {
    margin-top: 1%;
  }

  .loadingText {
    font-size: 20px;
    text-align: center;
    color: rgb(120, 184, 189);
    font-weight: bold;
  }

  .loadTextSub {
    text-align: center;
    color: rgb(157, 194, 199);
    font-size: 13px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;

  }

  .wPMobile {
    display: none;
  }

  .introductionMobile {
    display: none
  }

  .hl {
    color: #6ce34d;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
  }

  .right2 {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 1.5%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .discord {
    transition: transform .2s;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .discord:hover {
    transform: scale(1.1);
  }


  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(99, 31, 177);
  }



  .note {
    color: rgb(255, 255, 255);
  }

  .twitterSoc {
    margin-top: 10px;
    margin-right: 5px;
    /* z-index: -2;*/
    margin-left: 5px;

  }

  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/

  }

  /*.website{
    margin-top: 10px;
    margin-left: 10px;
   /* z-index: -2;*/
  /*color: #ffffffa2;
  }*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;

  }





  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    padding-left: 50px;
    padding-right: 50px;

    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);


  }

  .wallet:hover {
    background-color: white;
    color: rgb(73, 81, 190);
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-family: "Gordita";
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    font-family: "Gordita";
    align-items: center;
  }

  .connect div {
    margin-left: 15px;
    margin-right: 15px;
    font-family: "Gordita";
    color: #ffffff;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease;
    font-weight: 500;
  }


  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }




  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding-bottom: 50px;
    margin-left: auto;
    margin-right: auto;

  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-bottom: 20px;

  }

  .center {
    margin-top: auto;
    margin-bottom: auto;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }

  .btnfos-0-2 {
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    height: 70px;
    border: none;
    font-family: 'Poppins', sans-serif;
    cursor: pointer;
    background-color: #3a9ae802;
    border: white 2px solid;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    height: 70px;
    border: none;
    font-family: 'Poppins', sans-serif;
    cursor: pointer;
    background-color: #3a9ae802;
    border: white 2px solid;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }


  .btnfos-0-2:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(134, 134, 134, 0.288) -5px 5px;
  }


  .btnfos-0-2-2:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(134, 134, 134, 0.288) 5px 5px;
  }


  .btnfos-0-3 {
    margin-top: 20px;
    background-color: #6cfc19;
    color: #000000;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 28px;
    border-radius: 50px;
    padding-left: 30px;
    padding-right: 30px;
    transition: transform .2s;
    border: none;
    font-family: 'Poppins', sans-serif;
    transition: transform .2s;
  }

  .btnfos-0-3:hover {
    transform: scale(1.1);
  }

  .nftamount {
    color: cyan;
    font-size: 85px;
    font-family: 'Poppins', sans-serif;
   }
  .walletConnect {

    display: flex;
    justify-content: center;
  }

  .connectButton {

    font-size: 45px;
  }

  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;

  }

  .loadingContainer {
    text-align: center;
    padding-top: 20px;
  }

  .errorMessage {
    text-align: center;
    padding-top: 20px;
  }

  .successfully {
    text-align: center;
    padding-top: 20px;
    color: #1ee03e;
    font-family: 'Poppins', sans-serif;
    font-size: 30px;
    text-shadow: -1px -1px 1px rgba(255, 255, 255, .1), 1px 1px 1px rgba(0, 0, 0, .5);
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #000000;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
    border-bottom: 1px solid rgba(59, 45, 87, 0.863);
    padding: 20px;

  }

  .h1 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
    margin-top: auto;
    margin-bottom: auto;
  }

  .h2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    background-color: #5ebaad;

    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/

  }

  .introduction {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    z-index: 1000;
    background: rgba(3, 24, 107, 0.733);
    background: radial-gradient(circle, rgba(3, 24, 107, 0.747) 0%, rgba(3, 25, 54, 0.705) 74%, rgba(3, 20, 42, 0.781) 100%);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    padding-top: 20px;
    padding-bottom: 50px;
  }

  .wallet2 {
    z-index: 1000;
    background-color: #000000;
    color: #ffffff;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 20px;
    border-radius: 50px;
    width: 270px;
    transition: transform .2s;
    border: none;
    font-family: "Gordita";
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    transition: 0.5s ease;
    cursor: pointer;
    box-shadow: 0 3px #363636;
    letter-spacing: 2px;
  }

  .wallet2:hover {
    color: #000000;
    background-color: #ffffff;
  }

  .wallet2Btn {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    width: 400px;
    border: 2px solid #ffffff;
    margin-left: 10px;
    margin-right: 10px;
    border: 2px solid #2E8B57;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
  }

  .wallet2Btn:hover {
    color: rgb(156, 156, 156);
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }


  .wallet3 {
    background-image: linear-gradient(to right top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1);
    color: rgb(0, 0, 0);
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 30px;
    font-weight: bold;
    border-radius: 15px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 50px;
    padding-right: 50px;
    box-shadow: black 0px 5px;
  }

  .wallet3:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }

  @keyframes shake {

    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }

  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }

  .in2 {
    width: 100%;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .totalSupply {
    text-align: center;
    font-size: 60px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    font-family: 'Poppins', sans-serif;
    text-shadow: 0 0 10px #ffffff93;
    letter-spacing: 1px;
    margin-top: 1%;
    font-weight: bold;
  }

  @keyframes Slide_Up {
    0% {
      transform: translateY(250px);
      opacity: 1;
    }

    10%,
    87.5% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Tag {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 1;
    }

    20%,
    92.5% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Buttons {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 0;
    }

    20% {
      opacity: 1;
    }

    25%,
    95% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  .copyright {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 18px;
    text-shadow: 0 0 10px #FFFFFF;
  }

  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }

  @keyframes shake {

    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }

  @keyframes Slide_Left {
    0% {
      transform: translateX(250px);
    }

    100% {
      transform: translateX(0);
    }
  }

  @keyframes Slide_Left_Right {

    0%,
    100% {
      transform: translateX(100px);
    }

    12.5%,
    75% {
      transform: translateX(0);
    }

    25% {
      transform: translateX(20px);
    }

  }

  @keyframes Opacity {

    0%,
    100% {
      opacity: 0;
    }

    25%,
    75% {
      opacity: 1;
    }


  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }

  @keyframes scaleIn {
    from {
      transform: scale(.5, .5);
      opacity: .5;
    }

    to {
      transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }

  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1, 1);
    }

    50% {
      transform: scale(1.05, 1.05);
    }

    100% {
      transform: scale(1, 1);
    }
  }

  .logo {
    width: 90px;
    height: 90px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    margin-bottom: 10px;
  }

  .logo2 {
    width: 20%;
    cursor: pointer;
  }

  #fontSize {
    font-size: 12px;
    font-family: "Gordita";
    cursor: pointer;
    z-index: 10000;
    font-weight: 500;
  }

  .parrotDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;

  }

  .pic1 {
    width: 32%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    left: 70%;
    bottom: 0;

  }

  .pic2 {
    width: 32%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    left: -5%;
    bottom: 0;

  }

  
  .cover {
    width: 100vw;
  }

  .cover2 {
    width: 100%;
  }

  .parrotPic {
    width: 100vw;
  }

  .parrot {
    width: 70%;
    border-radius: 30px;
  }

  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .rmMain {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: -100px;
  }

  .rmBox {
    text-align: right;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }

  .rmBox2 {
    text-align: right;
    width: 80%;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }

  .rmBox {
    font-weight: 700;
    color: rgb(58, 58, 58);
    margin-bottom: 60%;
  }

  .year {
    font-size: 18px;
    font-family: 'Poppins', sans-serif;
    color: rgb(8, 81, 94);
    letter-spacing: 1px;
  }


  .rmBoxMain2 {
    margin-top: 30%;
    width: 33.33%;
  }

  .rmBoxMain {
    width: max-content;
    justify-content: flex-start;
    width: 33.33%;

  }

  .rmBox2 {
    text-align: left;
    width: 30%;
  }

  .rmParrot {
    width: 130px;
    height: 186px;
    z-index: 1;
    justify-content: center;
    position: -webkit-sticky;
    position: sticky;
    top: 10%;
    margin-bottom: 25%;
    margin-right: auto;
    display: block;
    margin-left: 33%;
    margin-top: -50%;
  }

  .line {
    margin-left: auto;
    margin-right: auto;
    display: block;
    height: 270vh;
    margin-top: -30%;
  }

  .rmP {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 33.33%;
    height: fit-content;
  }

  .box {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: -15%;
  }

  .discordF {
    padding-left: 10px;
    padding-right: 10px;
  }

  .socialAndLogo {
    flex-flow: row nowrap;
    justify-content: space-between;
    display: flex;
    padding-left: 10%;
    padding-right: 10%;
    margin-bottom: 10%;
    padding-top: 10%;
  }

  .chartAndContent {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }

  .tCon {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    color: white;
    font-weight: 500;
  }

  .tCon p {
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 5px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 13px;
  }

  .chart {
    width: 60%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .boxWrapToken {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-left: 5%;
    padding-right: 5%;
    background: rgb(74, 175, 139);
    background: linear-gradient(0deg, rgba(74, 175, 139, 1) 0%, rgba(88, 176, 135, 1) 18%, rgba(74, 175, 128, 1) 100%);
  }

  .tH {
    color: #3b3b3b;
    font-weight: bold;
  }

  .cont {
    height: 100vh;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background: rgb(3, 23, 107);
    background: radial-gradient(circle, rgba(3, 23, 107, 1) 0%, rgba(3, 25, 54, 1) 74%, rgba(3, 20, 42, 1) 100%);
  }

  .web {
    width: 100%;
    height: 100%;
  }

  .cont .drip {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background: rgb(24, 70, 28);
    position: absolute;
    top: -100%;
    animation: falling 5s linear infinite;
  }

  @keyframes falling {
    0% {
      top: -100%;
    }

    50% {
      top: 0%;
    }

    80% {
      top: 80%;
    }

    100% {
      top: 100%;
    }
  }

  .cont .drip:nth-child(1) {
    border-color: black;
    height: 9px;
    width: 9px;
    animation-delay: -0.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 241px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(2) {
    border-color: black;
    height: 9px;
    width: 9px;
    animation-delay: -0.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 175px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(3) {
    border-color: black;
    height: 36px;
    width: 36px;
    animation-delay: -0.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 190px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(4) {
    border-color: black;
    height: 24px;
    width: 24px;
    animation-delay: -0.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 86px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(5) {
    border-color: black;
    height: 5px;
    width: 5px;
    animation-delay: -1s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 197px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(6) {
    border-color: black;
    height: 23px;
    width: 23px;
    animation-delay: -1.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 244px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(7) {
    border-color: black;
    height: 11px;
    width: 11px;
    animation-delay: -1.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 120px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(8) {
    border-color: black;
    height: 10px;
    width: 10px;
    animation-delay: -1.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 120px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(9) {
    border-color: black;
    height: 27px;
    width: 27px;
    animation-delay: -1.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 29px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(10) {
    border-color: black;
    height: 33px;
    width: 33px;
    animation-delay: -2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 154px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(11) {
    border-color: black;
    height: 39px;
    width: 39px;
    animation-delay: -2.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 109px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(12) {
    border-color: black;
    height: 23px;
    width: 23px;
    animation-delay: -2.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 272px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(13) {
    border-color: black;
    height: 13px;
    width: 13px;
    animation-delay: -2.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 41%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(14) {
    border-color: black;
    height: 21px;
    width: 21px;
    animation-delay: -2.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 71%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(15) {
    border-color: black;
    height: 1px;
    width: 1px;
    animation-delay: -3s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 95%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(16) {
    border-color: black;
    height: 12px;
    width: 12px;
    animation-delay: -3.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 76%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(17) {
    border-color: black;
    height: 12px;
    width: 12px;
    animation-delay: -3.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 71%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(18) {
    border-color: black;
    height: 38px;
    width: 38px;
    animation-delay: -3.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 46%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(19) {
    border-color: black;
    height: 1px;
    width: 1px;
    animation-delay: -3.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 41%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(20) {
    border-color: black;
    height: 19px;
    width: 19px;
    animation-delay: -4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 33%;
    margin-left: 60px;
  }


  .cont .drip:nth-child(21) {
    border-color: black;
    height: 9px;
    width: 9px;
    animation-delay: -0.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 42%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(22) {
    border-color: black;
    height: 9px;
    width: 9px;
    animation-delay: -0.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 58%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(23) {
    border-color: black;
    height: 36px;
    width: 36px;
    animation-delay: -0.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 49%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(24) {
    border-color: black;
    height: 24px;
    width: 24px;
    animation-delay: -0.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 73%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(25) {
    border-color: black;
    height: 5px;
    width: 5px;
    animation-delay: -1s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 83%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(26) {
    border-color: black;
    height: 23px;
    width: 23px;
    animation-delay: -1.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 28%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(27) {
    border-color: black;
    height: 11px;
    width: 11px;
    animation-delay: -1.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 14%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(28) {
    border-color: black;
    height: 10px;
    width: 10px;
    animation-delay: -1.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 43%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(29) {
    border-color: black;
    height: 27px;
    width: 27px;
    animation-delay: -1.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 68%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(30) {
    border-color: black;
    height: 33px;
    width: 33px;
    animation-delay: -2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 57%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(31) {
    border-color: black;
    height: 39px;
    width: 39px;
    animation-delay: -2.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 27%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(32) {
    border-color: black;
    height: 23px;
    width: 23px;
    animation-delay: -2.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 12%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(33) {
    border-color: black;
    height: 13px;
    width: 13px;
    animation-delay: -2.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 30%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(34) {
    border-color: black;
    height: 21px;
    width: 21px;
    animation-delay: -2.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 38%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(35) {
    border-color: black;
    height: 1px;
    width: 1px;
    animation-delay: -3s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 55%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(36) {
    border-color: black;
    height: 12px;
    width: 12px;
    animation-delay: -3.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 50%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(37) {
    border-color: black;
    height: 12px;
    width: 12px;
    animation-delay: -3.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 58%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(38) {
    border-color: black;
    height: 38px;
    width: 38px;
    animation-delay: -3.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 67%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(39) {
    border-color: black;
    height: 1px;
    width: 1px;
    animation-delay: -3.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 70%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(40) {
    border-color: black;
    height: 19px;
    width: 19px;
    animation-delay: -4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 90%;
    margin-left: 60px;
  }

  .donutAndInfo {
    display: flex;
    flex-flow: row nowrap;
  }

  .donut {
    width: 45px;
    height: 45px;
    margin-right: 2%;
  }

  .rmCon {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    width: 90%;
  }

  .phrases p {
    font-size: 18px;
    letter-spacing: 2px;

  }

  .shoe {
    width: 400px;
    height: 400px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .phrases {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: row nowrap;
    width: fit-content;
    margin-top: 3%;
  }

  .rmCon1 {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .teamCon {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    display: flex;
    flex-flow: column nowrap;

  }

  .teamCon img {
    width: 300px;
    height: 300px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    border: 3px solid white;
  }

  .memberDetails {
    color: white;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .memberName {
    font-size: 23px;
    text-align: center;
  }

  .memberCon {
    font-size: 20px;
    text-align: center;
  }


  * {
    box-sizing: border-box;
  }

  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 4rem;
    background-color: #aa5620;
    padding-left: 100%;
  }

  .ticker-wrap .ticker {
    display: inline-block;
    height: 4rem;
    line-height: 4rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 30s;
    animation-duration: 50s;
  }

  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 18px;
    color: #ffffff;
    font-weight: 800;
  }

  .shark {
    width: 400px;
    height: 572px;
    z-index: 1;
    margin-right: -3%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .teamMain {
    display: flex;
  }


  .memName {
    font-family: 'Poppins', sans-serif;
    color: #ffc000;
    font-size: 16px;
    text-align: center;
    padding-right: 2%;
    font-weight: 500;
  }

  .memNamePosition {
    color: #99bad6;
    font-size: 14px;
    text-align: center;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .tPic {
    width: 30px;
    height: 30px;
    cursor: pointer;
  }


  .man {
    width: 120px;
    height: 120px;
    border-radius: 100%;
    transition: transform .5s;
  }

  .man:hover {
    transform: scale(1.1);
    border: 2px solid #5496ec;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px #90bbf3;
  }

  .teamSection {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 70%;
    margin-top: 40px;

  }

  .teamSection2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    margin-top: 40px;
  }


  .tPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .subT {
    font-family: 'Poppins', sans-serif;
    color: #f9f305;
    font-size: 50px;
    text-align: left;
  }

  .manDiv {
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 15px;
  }

  .memName2 {
    color: rgb(255, 255, 255);
    text-align: center;
    font-weight: 500;
    text-shadow: 0 0 10px rgb(255, 255, 255);
  }

  .rmDetails p {
    color: rgb(209, 209, 209);
    padding-left: 20px;
    font-size: 45px;
  }

  .flowers {
    width: 100%;
    margin-top: -10%;
  }

  .rmPicAndCon {
    display: flex;
    justify-content: flex-start;
    margin-left: 20px;
  }

  .footPrint2 {
    width: 30px;
    height: 30px;
  }


  /* Background */
  .mainFoot {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    margin-top: -10%;
  }

  /* Footprint */
  .footprint {
    position: absolute;
    top: 20%;
    left: 0;
    animation: walk 5s steps(10, end) infinite, walkopacity 2s ease-out infinite;
    text-align: center;
  }

  /* Image Size */
  .foot {
    max-width: 20%;
  }

  /* Right Footprint */
  .rightFoot {
    margin: 5rem;
    animation-delay: 250ms;
  }

  /* Walking Animation */
  @keyframes walk {
    to {
      transform: translateX(100vw);
    }
  }

  /* Opacity During Walking */
  @keyframes walkopacity {

    0%,
    100% {
      opacity: 0;
    }

    50% {
      opacity: 0.4;
    }
  }

  .honeyComb {
    width: 450px;
    height: 345px;
    position: sticky;
    position: -webkit-sticky;
    left: 80%;
    bottom: 80px;
  }

  .dripCover {
    width: 100%;
  }

  .ball {
    width: 250px;
    height: 250px;
    animation: floating 5s ease-in-out infinite;
  }

  .ball2 {
    width: 250px;
    height: 250px;
    animation: floating2 5.25s ease-in-out infinite;
  }

  .ball3 {
    width: 250px;
    height: 250px;
    animation: floating3 5.5s ease-in-out infinite;
  }

  .ball4 {
    width: 130px;
    height: 130px;
    animation: floating4 2.1s ease-in-out infinite;
    margin-top: -200px;
    margin-left: 250px;
  }

  .ball5 {
    width: 130px;
    height: 130px;
    animation: floating4 2.3s ease-in-out infinite;
    margin-top: -400px;
    margin-left: 200px;
  }

  .ball6 {
    width: 130px;
    height: 130px;
    animation: floating4 2.8s ease-in-out infinite;
    margin-top: -1100px;
    margin-right: 600px;
  }

  .ball7 {
    width: 130px;
    height: 130px;
    animation: floating4 3s ease-in-out infinite;
    margin-top: -1100px;
    margin-left: 1200px;
  }

  /* Define the keyframes for the floating animation */
  @keyframes floating {
    0% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-10px);
    }

    100% {
      transform: translateY(0);
    }
  }

  @keyframes floating2 {
    0% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-10px);
    }

    100% {
      transform: translateY(0);
    }
  }

  @keyframes floating3 {
    0% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-10px);
    }

    100% {
      transform: translateY(0);
    }
  }

  @keyframes floating4 {
    0% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-10px);
    }

    100% {
      transform: translateY(0);
    }
  }

  .title {
    text-align: center;
    font-weight: bolder;
    font-size: 140px;
    font-family: 'Poppins', sans-serif;
    color: #d1d1e7;
    text-transform: uppercase;
    text-shadow: 0 0 10px #ffffff75;
    z-index: 1000;

  }

  .title2 {
    text-align: center;
    font-weight: bolder;
    font-size: 50px;
    font-family: 'Poppins', sans-serif;
    color: #d1d1e7;
    text-transform: uppercase;
    margin-top: -60px;
    text-shadow: 0 0 10px #ffffff75;
    z-index: 1000;

  }

  .title3 {
    font-size: 15px;
    color: rgb(202, 201, 201);
    width: 70%;
    justify-content: center;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    z-index: 1000;

  }

  .balls {
    display: flex;
    justify-content: center;
    margin-top: 90px;
  }

  .visionBox {
    color: white;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    width: 500px;
    padding: 20px;
    border-radius: 20px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 40px;
  }

  .visionBox:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }

  .missionBox {
    color: #a5f8f1;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    width: 500px;
    padding: 20px;
    border-radius: 20px;
    margin-left: auto;
    margin-right: auto;
  }

  .missionBox:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }

  .visionT {
    color: #a5f8f1;
    font-size: 30px;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    text-align: center;
  }

  .utilCon {
    color: white;
    font-size: 14px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    width: 80%;
  }

  .utilMainCon {
    width: 50%;
  }

  .utilMainCon img {
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }

  .tokenCon {
    display: flex;
    justify-content: center;
    flex-flow: row nowrap;
  }

  .platformMain {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
  }

  .tokenCon p {
    color: white;
    font-weight: 500;
  }

  th {
    font-weight: bold;
    font-size: 18px;
    color: #e3bcfa;
  }

  td {
    color: white;
    font-size: 15px;
  }

  table {
    width: 40%;
  }

  .chart {
    width: 60%;
    justify-content: flex-end;
  }

  .taxMain2 {
    display: flex;
    margin-top: 50px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  #customers {
    font-family: 'Poppins', sans-serif;
    border-collapse: collapse;
    width: 100%;
  }

  #customers td,
  #customers th {
    border: 1px solid #ddd;
    padding: 8px;
    font-size: 15px;
  }

  #customers tr:nth-child(even) {
    background-color: #f2f2f225;
  }

  #customers th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #ffc000;
    color: rgb(0, 0, 0);
  }

  #customers2 {
    font-family: 'Poppins', sans-serif;
    border-collapse: collapse;
    width: 90%;
    margin-right: 60px;
    margin-left: auto;
    margin-right: auto;
  }

  #customers2 td,
  #customers2 th {
    border: 1px solid #ddd;
    padding: 8px;
    font-size: 15px;
  }

  #customers2 tr:nth-child(even) {
    background-color: #b7d5f725;
  }

  #customers2 th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #8297f7;
    color: rgb(0, 0, 0);
  }


  #customers3 {
    font-family: 'Poppins', sans-serif;
    border-collapse: collapse;
    width: 100%;
    margin-right: 60px;
  }

  #customers3 td,
  #customers3 th {
    border: 1px solid #ddd;
    padding: 8px;
    font-size: 15px;
  }

  #customers3 tr:nth-child(even) {
    background-color: #b7d5f725;
  }

  #customers3 th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #8297f7;
    color: rgb(0, 0, 0);
  }

  .platformMain2 {
    display: flex;
    justify-content: center;
    margin-top: 50px;
    flex-flow: column nowrap;
  }

  .platformMain3 {
    justify-content: center;
    margin-top: 50px;
  }

  .pT2 {
    font-family: 'Poppins', sans-serif;
    color: #ffc000;
    font-size: 20px;
  }

  .tableCon2 p {
    color: rgb(145, 196, 245);
  }

  .tableCon2 {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .tableCon3 {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .alien {

    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
    display: flex;
    transform-origin: center;
    transform: perspective(800px) rotateY(25deg);
    transition: 0.5s;
    background-color: rgba(85, 22, 22, 0.267);
    width: 100%;
  }

  .alien:hover {
    transform: perspective(800px) rotateY(0deg);
    opacity: 1;
  }

  .tableCon2Main {
    margin-top: auto;
    margin-bottom: auto;
  }

  .winnerNote {
    color: rgb(145, 196, 245);
    text-align: center;
    margin-top: 10px;
  }

  .nftT {
    font-weight: bold;
    color: white;
    font-size: 17px;
  }

  .nftT2 {
    color: white;
    font-size: 16px;

  }

  .nftCon {
    margin-bottom: 20px
  }

  #customers4 {
    font-family: 'Poppins', sans-serif;
    border-collapse: collapse;
    width: 100%;
  }

  #customers4 td,
  #customers4 th {
    border: 1px solid #ddd;
    padding: 8px;
    font-size: 15px;
  }

  #customers4 tr:nth-child(even) {
    background-color: #7ec5ff25;
  }

  #customers4 th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #ffc000;
    color: rgb(0, 0, 0);
  }

  .alien {
    width: 350px;
    height: 350px;
    border-radius: 20px;
    margin-left: auto;
    margin-right: auto;
  }

  .nftInfoT {
    color: #ffc000;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    font-size: 20px;
  }

  .nftInfo {
    color: rgb(162, 208, 252);
    font-size: 16px;
  }

  .note {
    width: 30px;
    height: 30px;
  }

  .com {
    color: #767698;
    font-size: 12px;
  }

  a {
    color: #767698;
    font-size: 12px;
    text-decoration: none;
  }

  a:hover {
    color: #a2a2c4;
    font-size: 12px;
    text-decoration: underline;
  }

  .comInfo {
    display: flex;
    justify-content: space-between;
    margin-top: 60px;
  }

  .lineF {
    width: 100%;
    height: 1px;
    background-color: rgba(75, 63, 78, 0.3);
    margin-bottom: 20px;
  }

  .lineF2 {
    width: 100%;
    height: 1px;
    background-color: rgba(75, 63, 78, 0.3);
    margin-top: 20px;
  }

  .cs {
    color: #5c6c70;
    margin-top: -20px;
    margin-bottom: 60px;
    font-size: 15px;
  }

}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  
  #fontSize2{
    display: none;
  }

  .lotteryMain4{
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
.lotteryMain2-2 {
  justify-content: center;
 display: flex;
 flex-flow: column nowrap;
/* From https://css.glass */
background: url('./assets/Container.png') no-repeat center center fixed; 
-webkit-background-size: cover;
-moz-background-size: cover;
-o-background-size: cover;
background-size: cover;
padding: 60px;
 border: 2px solid rgb(160, 160, 255);
box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(120, 120, 226);
padding-top: 10px;
margin-bottom: 120px;
margin-left: auto;
margin-right: auto;
}
/*.............. FAQ ..............*/
.totalTickets{
  color: white;
  font-size: 30px;
  margin-bottom: 80px;
}

.pomPrice{
  color: #4ac7fc;
  text-align: center;
  margin-top: 25px;
  font-size: 18px;
 }
/* CSS */
.button-29 {
  align-items: center;
  appearance: none;
  background-image: radial-gradient(100% 100% at 100% 0, #fff2b7 0, #ffd000 100%);
  border: 0;
  border-radius: 6px;
  box-shadow: rgba(45, 35, 66, .4) 0 2px 4px,rgba(45, 35, 66, .3) 0 7px 13px -3px,rgba(58, 65, 111, .5) 0 -3px 0 inset;
  box-sizing: border-box;
  color: rgb(0, 0, 0);
  cursor: pointer;
  display: inline-flex;
  font-family: "JetBrains Mono",monospace;
  height: 48px;
  justify-content: center;
  line-height: 1;
  list-style: none;
  overflow: hidden;
  padding-left: 16px;
  padding-right: 16px;
  position: relative;
  text-align: left;
  text-decoration: none;
  transition: box-shadow .15s,transform .15s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  will-change: box-shadow,transform;
  font-size: 18px;
  width: 230px;
}

.button-29:focus {
  box-shadow: #dab200 0 0 0 1.5px inset, rgba(45, 35, 66, .4) 0 2px 4px, rgba(45, 35, 66, .3) 0 7px 13px -3px, #f3cc1d 0 -3px 0 inset;
}

.button-29:hover {
  box-shadow: rgba(45, 35, 66, .4) 0 4px 8px, rgba(45, 35, 66, .3) 0 7px 13px -3px, #9c8001 0 -3px 0 inset;
  transform: translateY(-2px);
}

.ticketsSeparately{
  display: flex;
  flex-flow: column nowrap;
}

summary {
  font-size: 20px;
  background: #af3d330c;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  color: rgb(0, 0, 0);
  padding: 1rem;
  margin-bottom: 1rem;
  outline: none;
  text-align: left;
  cursor: pointer;
  color: white;
  border: 2px solid rgb(197, 197, 197);
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(255, 255, 255, 0.61);
}

details>summary::-webkit-details-marker {
  display: none;
}

details[open] summary~* {
  animation: sweep .5s ease-in-out;
}

@keyframes sweep {
  0% {
    opacity: 0;
    margin-top: -10px
  }

  100% {
    opacity: 1;
    margin-top: 0px
  }
}

.accordin {
  width: 70%;
  color: white;
  display: inline-block;
  flex-direction: column;
  margin-bottom: 85px;
}

.faq__content {

  text-shadow: 0 0 10px #FFFFFF;
  padding-bottom: 20px;
  text-align: left;
  font-size: 17px;
}

.faqTitle {
  margin-bottom: 20px;
  text-align: center;
  font-size: 70px;
  color: white;
  font-family: 'Poppins', sans-serif;
}

.faqbg {
  width: 100%;
  text-align: left;
  padding-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  width: 100%;
  padding-bottom: 10%;

}

.lotterySubH{
  justify-content: center;
  display: flex;
  font-size: 40px;
  font-family: 'Poppins', sans-serif;
  text-align: left;
  color: white;
  font-weight: bold;
  margin-top: -20px;
    border: 2px solid rgb(160, 160, 255);
   box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(120, 120, 226);
   width: 600px;
   margin-left: auto;
   margin-right: auto;
   border-bottom: 0px;
}

.connect div:hover {
  color: #ffc000;
}

.boxWrap2Lottery {
  display: flex;
  flex-flow: column nowrap;
  padding-bottom: 5%;
  padding-top: 5%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  width: 100%;
  background: rgb(0, 29, 56);
  background: linear-gradient(10deg, rgb(0, 37, 54) 0%, rgb(18, 17, 1) 34%, rgba(1, 6, 18, 1) 100%);
  padding-left: 5%;
  padding-right: 5%;
  
}

.lottery{
  width: 270px;
  height: 300px;
}

.ticketCategory{
  color: white;
  margin-left: auto;
  margin-right: auto;
}

.lotteryMain {
  margin-top: 30px;
   display: flex;
  flex-flow: column nowrap;
  margin-bottom: 5%;

}

.lotteryMain2 {
   justify-content: space-evenly;
  display: flex;
  flex-flow: row nowrap;
 /* From https://css.glass */
 background: url('./assets/Container.png') no-repeat center center fixed; 
 -webkit-background-size: cover;
 -moz-background-size: cover;
 -o-background-size: cover;
 background-size: cover;
 padding: 60px;
  border: 2px solid rgb(160, 160, 255);
 box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(120, 120, 226);
 padding-top: 10px;
 margin-bottom: 120px;
}

.lotteryMain3 {
  justify-content: space-evenly;
  flex-flow: column nowrap;
/* From https://css.glass */
background: url('./assets/Container.png') no-repeat center center fixed; 
-webkit-background-size: cover;
-moz-background-size: cover;
-o-background-size: cover;
background-size: cover;
padding: 60px;
 border: 2px solid rgb(160, 160, 255);
box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(120, 120, 226);
padding-top: 10px;
margin-bottom: 120px;
}

.drawBtn{
  background-color: white;
  color: black;
  border: none;
  padding: 15px;
  font-weight: 500;  
}

.autoPickedMain{
  background: url('./assets/Container.png') no-repeat center center fixed; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  padding: 60px;
   border: 2px solid rgb(160, 160, 255);
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(120, 120, 226);
   margin-bottom: 120px;
}

.autoPickedMain p{
  color: white;
  text-align: center;
}

.kave-btn {
  position: relative;
  border: solid 1px rgba(194, 191, 193, 0.3);
  background: none;
  color: white;
  letter-spacing: 1.8px;
  width: 250px;
  height: 50px;
  padding: 0;
  transition: all 0.25s ease;
  outline: none;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 60px;
}

.kave-btn:before {
  content: "";
  position: absolute;
  top: 0;
  left: 10%;
  width: 60px;
  height: 1px;
  transform: translateY(-1px);
  background: #93edfd;
  transition: all 0.25s ease;
}

.kave-btn:after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 10%;
  width: 60px;
  height: 1px;
  transform: translateY(1px);
  background: #00d6fc;
  transition: all 0.25s ease;
}

.kave-btn:hover {
  box-shadow: 1px 1px 8px rgba(0, 214, 252, 0.3);
  color: #00d6fc;
  text-shadow: 0 0 8px rgba(0, 214, 252, 0.4);
}

.kave-btn:hover.kave-btn:before {
  left: 0;
  width: 20px;
}

.kave-btn:hover.kave-btn:after {
  right: 0;
  width: 20px;
}

.kave-btn:hover .kave-line:before {
  bottom: 0;
}

.kave-btn:hover .kave-line:after {
  top: 0;
}

.kave-line:before {
  content: "";
  position: absolute;
  bottom: 30%;
  right: 0;
  width: 1px;
  height: 20px;
  transform: translateX(1px);
  background: #00d6fc;
  transition: all 0.25s ease;
}

.kave-line:after {
  content: "";
  position: absolute;
  top: 30%;
  left: 0;
  width: 1px;
  height: 20px;
  transform: translateX(-1px);
  background: #00d6fc;
  transition: all 0.25s ease;
}

  /* CSS */
  .button-92 {
    --c: #fff;
    /* text color */
    background: linear-gradient(90deg, #0000 33%, #fff5, #0000 67%) var(--_p,100%)/300% no-repeat,
      #004dff;
    /* background color */
    color: #0000;
    border: none;
    transform: perspective(500px) rotateY(calc(20deg * var(--_i,-1)));
    text-shadow: calc(var(--_i,-1) * 0.08em) -.01em 0   var(--c),
      calc(var(--_i,-1) * -0.08em)  .01em 2px #0004;
    outline-offset: .1em;
    transition: 0.3s;
  }

.button-92:hover,
.button-92:focus-visible {
  --_p: 0%;
  --_i: 1;
}

.button-92:active {
  text-shadow: none;
  color: var(--c);
  box-shadow: inset 0 0 9e9q #0005;
  transition: 0s;
}

.button-92 {
  font-weight: bold;
  font-size: 2rem;
  margin: 0;
  cursor: pointer;
  padding: .1em .3em;
}
  
  
  .aboutMob {
    display: none;
  }
  .boxWrap2Mint {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: rgb(0, 41, 79);
    background: radial-gradient(circle, rgba(0, 41, 79, 1) 0%, rgba(0, 10, 25, 1) 74%, rgba(0, 10, 25, 1) 100%);
    padding-left: 5%;
    padding-right: 5%;
  }
  .special:hover {
    background-color: rgb(228, 194, 0);

  }

  .taxMain2Mob {
    display: none;
  }

  .teamSectionMob {
    display: none;
  }

  .teamSection2Mob {
    display: none;
  }

  .special:active {
    background-color: rgb(228, 194, 0);
    box-shadow: -0px 7px rgb(153, 131, 4);
    transform: translateY(4px);
  }

  .special2:hover {
    background-color: rgb(228, 194, 0);

  }

  .special2:active {
    background-color: rgb(228, 194, 0);
    box-shadow: -0px 7px rgb(153, 131, 4);
    transform: translateY(4px);
  }

  .placeAndWL {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 50px;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    background: rgba(82, 82, 82, 0.2);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 30px;
  }

  .special {
    font-size: 20px;
    margin-left: 30px;
    border-radius: 10px;
    background-color: gold;
    border: none;
    padding: 10px;
    box-shadow: -0px 5px rgb(194, 165, 5);
  }

  .special2 {
    font-size: 20px;
    border-radius: 10px;
    background-color: gold;
    border: none;
    padding: 10px;
    box-shadow: -0px 5px rgb(194, 165, 5);
  }

  .mintingAmount {
    border: black 2px solid;
    border-radius: 10px;
    box-shadow: -0px 5px black;
    padding: 10px;
  }

  h1 {
    text-align: center;
    margin-bottom: 200px;
    margin-top: 100px;
    font-size: 50px;
    font-weight: 600;
    color: #0b72d3;
  }

  .timeline-container {
    display: flex;
    justify-content: center;
    margin-top: 80px;
  }

  .timeline-container .vertical-scrollable-timeline {
    width: 700px;
    list-style-type: none;
  }

  .timeline-container .vertical-scrollable-timeline li {
    position: relative;
    padding: 35px 0px 125px 158px;
  }

  .timeline-container .vertical-scrollable-timeline li:last-child {
    margin-bottom: 0;
  }

  .timeline-container .vertical-scrollable-timeline li h2 {
    font-size: 20px;
    margin-bottom: 10px;
    color: #ffc000;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
  }

  .timeline-container .vertical-scrollable-timeline li p {
    line-height: 1.5;
    color: rgb(198, 221, 255);
    font-size: 16px;
  }

  .timeline-container .vertical-scrollable-timeline li p:last-child {
    margin-bottom: 0;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder {
    position: absolute;
    left: 0;
    top: 0;
    width: 104px;
    height: 104px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #c5c3cb;
    border-radius: 50%;
    z-index: 1;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder::before {
    content: "";
    width: 80px;
    height: 80px;
    border: 4px solid #fff;
    position: absolute;
    background-color: #c5c3cb;
    border-radius: 50%;
    z-index: -1;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder i {
    font-size: 25px;
    color: #fff;
  }

  .timeline-container .vertical-scrollable-timeline li::after {
    content: "";
    position: absolute;
    height: 0;
    width: 8px;
    background-color: #3f96e7;
    left: 48px;
    top: 34px;
    z-index: 0;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 8px;
    background-color: #c5c3cb;
    left: 48px;
    z-index: 0;
  }

  .timeline-container .vertical-scrollable-timeline li:last-child::before {
    content: unset;
  }

  .timeline-container .vertical-scrollable-timeline li.active .icon-holder {
    background-color: #ffc000;
  }

  .timeline-container .vertical-scrollable-timeline li.active .icon-holder::before {
    background-color: #4372c3;
  }

  .timeline-container .vertical-scrollable-timeline li.active::after {
    height: 100%;
  }

  .timeline-container .vertical-scrollable-timeline li.active:last-child::after {
    content: unset;
  }


  figure {
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
  }

  figure:nth-child(1) .rmDetails {
    transform: scale(0.6) rotate(-12.5deg);
  }

  figure:nth-child(2) .rmDetails {
    transform: scale(0.6) rotate(-9.7222222222deg);
  }

  figure:nth-child(3) .rmDetails {
    transform: scale(0.6) rotate(-6.9444444444deg);
  }

  figure:nth-child(4) .rmDetails {
    transform: scale(0.6) rotate(-4.1666666667deg);
  }

  figure:nth-child(5) .rmDetails {
    transform: scale(0.6) rotate(-1.3888888889deg);
  }

  figure:nth-child(6) .rmDetails {
    transform: scale(0.6) rotate(1.3888888889deg);
  }

  figure:nth-child(7) .rmDetails {
    transform: scale(0.6) rotate(4.1666666667deg);
  }

  figure:nth-child(8) .rmDetails {
    transform: scale(0.6) rotate(6.9444444444deg);
  }

  figure:nth-child(9) .rmDetails {
    transform: scale(0.6) rotate(9.7222222222deg);
  }

  figure:nth-child(10) .rmDetails {
    transform: scale(0.6) rotate(12.5deg);
  }

  figure .rmDetails {
    width: 100%;
    border-radius: 3px;
    background-color: black;
    padding: 50px;
    border: 5px solid gray;
  }

  .connect2Mobile {
    display: none;
  }

  .discordNone {
    transition: transform .2s;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: no-drop;
  }

  .discordNone:hover {
    transform: scale(1.1);
  }

  .linkedin {
    width: 30px;
    height: 30px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 10px;
    cursor: pointer;

  }

  .team1 {
    width: 50%;
  }

  .discord3 {
    display: none;
  }

  .discord4 {
    display: none;
  }

  .storyHRmM {
    display: none;
  }

  .iconsMob {
    display: none;
  }

  .mainBanner {
    display: none;
  }

  .icons {
    display: flex;
    flex-flow: row nowrap;
  }

  .cover52 {
    display: none;
  }

  .coverRm {
    width: 100vw;
  }

  .discordF2 {
    display: none;
  }

  .connectMobile {
    display: none;
  }

  .rightMobile {
    display: none;
  }

  .osPic {
    width: 30px;
    height: 30px;
    cursor: pointer;
    z-index: 10000;
  }

  .osPic2 {
    width: 40px;
    height: 40px;
    cursor: pointer;
  }

  .price {
    text-align: center;
    font-size: 22px;
    color: #aef6ff;
  }

  .discord2 {
    display: none;
  }

  .discord3 {
    padding-right: 20px;
    display: none;
  }


  .uRMobile {
    display: none;
  }

  .storyPicDivMobile {
    display: none;
  }

  .allWrap {

    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    contain: content;
  }

  .boxWrap2 {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background-color: black;
  }

  .boxWrap2Story {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: rgb(0, 88, 81);
    background: radial-gradient(circle, rgba(0, 88, 81, 1) 0%, rgba(0, 42, 47, 1) 34%, rgb(3, 14, 41) 100%);
    padding-left: 5%;
    padding-right: 5%;
  }

  .boxWrap2Utility {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: rgb(0, 45, 56);
    background: linear-gradient(0deg, rgba(0, 45, 56, 1) 0%, rgba(1, 6, 18, 1) 34%, rgba(1, 6, 18, 1) 100%);
    padding-left: 5%;
    padding-right: 5%;
  }

  .boxWrap2Team {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: rgb(0, 41, 79);
    background: radial-gradient(circle, rgba(0, 41, 79, 1) 0%, rgba(0, 10, 25, 1) 74%, rgba(0, 10, 25, 1) 100%);
    padding-left: 5%;
    padding-right: 5%;
  }

  .boxWrap2Platform {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: rgb(21, 38, 7);
    background: linear-gradient(309deg, rgba(21, 38, 7, 1) 0%, rgba(0, 10, 25, 1) 74%, rgba(0, 10, 25, 1) 100%);
    padding-left: 5%;
    padding-right: 5%;
  }

  .boxWrap2Tokenomic {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: rgb(0, 5, 27);
    background: linear-gradient(97deg, rgba(0, 5, 27, 1) 0%, rgba(43, 22, 56, 1) 74%, rgba(0, 5, 27, 1) 100%);
    padding-left: 5%;
    padding-right: 5%;
  }

  .sharks {
    width: 100%;
  }

  .boxWrapC {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    position: relative;

    padding-bottom: 5%;
  }

  .green {
    background-color: #97ba5e;

  }

  .about {
    margin-top: 30px;
    justify-content: space-evenly;
    display: flex;
    flex-flow: row nowrap;
  }

  .aboutVAndM {
    margin-top: 30px;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
  }

  .about2 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 90%;
  }

  .aboutImg {
    width: 50%;
    border: white 6px solid;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-bottom: 3%;
  }

  .aboutImg2 {
    width: 28%;
    height: 28%;
    border: white 6px solid;
    transition: transform .5s;
  }

  .aboutImg2:hover {
    transform: scale(1.2);
  }

  .utilityPics {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
  }

  .roadmapMain1 {
    top: 0;
    position: relative;
  }

  .storyPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .UtilDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .storyH {
    justify-content: left;
    display: flex;
    font-size: 18px;
    font-family: "Gordita";
    color: #00fae5;
    text-align: left;
  }

  .utilH {
    justify-content: left;
    display: flex;
    font-size: 18px;
    font-family: "Gordita";
    color: #00c8fa;
    text-align: left;
  }

  .TokenomicH {
    justify-content: left;
    display: flex;
    font-size: 18px;
    font-family: "Gordita";
    color: #c86ffc;
    text-align: left;
  }

  .platformH {
    justify-content: left;
    display: flex;
    font-size: 18px;
    font-family: "Gordita";
    color: #a2f55e;
    text-align: left;
  }

  .nftH {
    justify-content: left;
    display: flex;
    font-size: 18px;
    font-family: "Gordita";
    color: #0278ee;
    text-align: left;
  }

  .teamH {
    justify-content: left;
    display: flex;
    font-size: 18px;
    font-family: "Gordita";
    color: #ffc959;
    text-align: left;
  }

  .storyH2 {
    justify-content: left;
    display: flex;
    font-size: 50px;
    font-family: 'Poppins', sans-serif;
    text-align: left;
    color: white;
    font-weight: bold;
    margin-top: -20px;
  }

  .taxT {
    justify-content: center;
    display: flex;
    font-size: 40px;
    font-family: 'Poppins', sans-serif;
    text-align: left;
    color: white;
    font-weight: bold;
    margin-top: -20px;
  }

  .taxMain {
    margin-top: 150px;
  }

  .mintH {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    font-size: 90px;
    background: -webkit-linear-gradient(#f0d9a3, #ff9d01);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: "Gordita";
    -webkit-text-stroke-width: 3px;
    -webkit-text-stroke-color: black;
  }

  .storyRm img {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .tokenH {
    font-size: 70px;
    color: white;
    font-family: 'Poppins', sans-serif;
    text-align: center;
    margin-bottom: 2%;
  }


  .carouselH {
    font-size: 70px;
    color: white;
    font-family: 'Poppins', sans-serif;
    text-align: center;
    margin-bottom: 2%;
  }

  .rmH {
    font-size: 80px;
    color: white;
    font-family: 'Poppins', sans-serif;
    text-align: center;
    background-color: #fe8a00;
    padding-left: 20px;
    margin-bottom: 20px;
  }

  .rmH2 {
    color: grey;
    font-size: 18px;
    line-height: 30px;
  }

  .rmName {
    background: rgb(81, 190, 229);
    background: linear-gradient(0deg, rgba(81, 190, 229, 1) 2%, rgba(81, 190, 229, 1) 20%, rgba(6, 136, 198, 1) 63%, rgba(6, 136, 198, 1) 100%);
  }



  .storyCon {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 70%;
    margin-left: auto;
    margin-right: auto;

  }

  .storyCon-2 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    padding: 20px;
    border-radius: 50px;
    box-shadow: -10px 10px rgba(14, 21, 36, 0.363);
    background-color: rgba(30, 46, 80, 0.568);

  }

  .storyCon2 {
    font-size: 30px;
    font-family: 'Poppins', sans-serif;
    text-align: center;
  }

  .storyConP {
    font-size: 20px;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
  }

  .storyPic {
    width: 540px;
    height: 339px;
  }

  .storyRight {
    width: 45%;
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
  }

  .uR {
    width: 50%;
  }

  .intro {
    color: white;
    font-size: 100px;
    text-align: center;
    font-family: 'Riangriung', sans-serif;
  }

  .logoF {
    color: white;
    text-align: center;
    font-size: 50px;
    background: -webkit-linear-gradient(#f0d9a3, #ff9d01);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: "Gordita";
  }

  .logoF img {
    width: 50%;
  }

  .intro img {
    width: 500px;
    height: 500px;
  }

  .intro3 {
    font-style: italic;
    font-size: 20px;
    font-weight: 500;
  }

  .intro2 {
    color: rgb(0, 0, 0);
    font-size: 23px;
    text-align: left;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    font-weight: bold;
    width: 50%;
  }

  .mintDiv {
    padding-top: 3%;
    padding-bottom: 3%;
    width: 630px;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.493) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
    margin-bottom: 80px;
    border: 2px solid rgb(119, 222, 248);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(106, 184, 243);
    margin-top: 50px;
  }

  .errorMessage {
    margin-left: 3%;
    margin-top: 1%;
    font-size: 18px;
    color: #fc4741;
    text-align: center;
    text-shadow: -1px -1px 1px rgba(255, 255, 255, .1), 1px 1px 1px rgba(0, 0, 0, .5);
  }

  .loadingContainer {
    margin-top: 1%;
  }

  .loadingText {
    font-size: 20px;
    text-align: center;
    color: rgb(120, 184, 189);
    font-weight: bold;
  }

  .loadTextSub {
    text-align: center;
    color: rgb(157, 194, 199);
    font-size: 13px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;

  }

  .wPMobile {
    display: none;
  }

  .introductionMobile {
    display: none
  }

  .hl {
    color: #6ce34d;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
  }

  .right2 {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 1.5%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .discord {
    transition: transform .2s;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .discord:hover {
    transform: scale(1.1);
  }


  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(99, 31, 177);
  }



  .note {
    color: rgb(255, 255, 255);
  }

  .twitterSoc {
    margin-top: 10px;
    margin-right: 5px;
    /* z-index: -2;*/
    margin-left: 5px;

  }

  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/

  }

  /*.website{
    margin-top: 10px;
    margin-left: 10px;
   /* z-index: -2;*/
  /*color: #ffffffa2;
  }*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;

  }





  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    padding-left: 50px;
    padding-right: 50px;

    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);


  }

  .wallet:hover {
    background-color: white;
    color: rgb(73, 81, 190);
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-family: "Gordita";
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    font-family: "Gordita";
    align-items: center;
  }

  .connect div {
    margin-left: 10px;
    margin-right: 10px;
    font-family: "Gordita";
    color: #ffffff;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease;
    font-weight: 500;
  }


  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }




  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding-bottom: 50px;
    margin-left: auto;
    margin-right: auto;

  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-bottom: 20px;

  }

  .center {
    margin-top: auto;
    margin-bottom: auto;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }

  .btnfos-0-2 {
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    height: 70px;
    border: none;
    font-family: 'Poppins', sans-serif;
    cursor: pointer;
    background-color: #3a9ae802;
    border: white 2px solid;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    height: 70px;
    border: none;
    font-family: 'Poppins', sans-serif;
    cursor: pointer;
    background-color: #3a9ae802;
    border: white 2px solid;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }


  .btnfos-0-2:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(134, 134, 134, 0.288) -5px 5px;
  }


  .btnfos-0-2-2:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(134, 134, 134, 0.288) 5px 5px;
  }


  .btnfos-0-3 {
    margin-top: 20px;
    background-color: #6cfc19;
    color: #000000;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 28px;
    border-radius: 50px;
    padding-left: 30px;
    padding-right: 30px;
    transition: transform .2s;
    border: none;
    font-family: 'Poppins', sans-serif;
    transition: transform .2s;
  }

  .btnfos-0-3:hover {
    transform: scale(1.1);
  }

  .nftamount {
    color: cyan;
    font-size: 85px;
    font-family: 'Poppins', sans-serif;
   }

  .walletConnect {

    display: flex;
    justify-content: center;
  }

  .connectButton {

    font-size: 45px;
  }

  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;

  }

  .loadingContainer {
    text-align: center;
    padding-top: 20px;
  }

  .errorMessage {
    text-align: center;
    padding-top: 20px;
  }

  .successfully {
    text-align: center;
    padding-top: 20px;
    color: #1ee03e;
    font-family: 'Poppins', sans-serif;
    font-size: 30px;
    text-shadow: -1px -1px 1px rgba(255, 255, 255, .1), 1px 1px 1px rgba(0, 0, 0, .5);
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #000000;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
    border-bottom: 1px solid rgba(59, 45, 87, 0.863);
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
    margin-top: auto;
    margin-bottom: auto;
  }

  .h2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    background-color: #5ebaad;

    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/

  }

  .introduction {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    z-index: 1000;
    background: rgba(3, 24, 107, 0.733);
    background: radial-gradient(circle, rgba(3, 24, 107, 0.747) 0%, rgba(3, 25, 54, 0.705) 74%, rgba(3, 20, 42, 0.781) 100%);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    padding-bottom: 120px;
  }

  .wallet2 {
    z-index: 1000;
    background-color: #000000;
    color: #ffffff;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 20px;
    border-radius: 50px;
    width: 270px;
    transition: transform .2s;
    border: none;
    font-family: "Gordita";
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    transition: 0.5s ease;
    cursor: pointer;
    box-shadow: 0 3px #363636;
    letter-spacing: 2px;
  }

  .wallet2:hover {
    color: #000000;
    background-color: #ffffff;
  }

  .wallet2Btn {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    width: 400px;
    border: 2px solid #ffffff;
    margin-left: 10px;
    margin-right: 10px;
    border: 2px solid #2E8B57;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
  }

  .wallet2Btn:hover {
    color: rgb(156, 156, 156);
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }


  .wallet3 {
    background-image: linear-gradient(to right top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1);
    color: rgb(0, 0, 0);
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 30px;
    font-weight: bold;
    border-radius: 15px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 50px;
    padding-right: 50px;
    box-shadow: black 0px 5px;
  }

  .wallet3:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }

  @keyframes shake {

    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }

  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }

  .in2 {
    width: 100%;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .totalSupply {
    text-align: center;
    font-size: 60px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    font-family: 'Poppins', sans-serif;
    text-shadow: 0 0 10px #ffffff93;
    letter-spacing: 1px;
    margin-top: 1%;
    font-weight: bold;
  }

  @keyframes Slide_Up {
    0% {
      transform: translateY(250px);
      opacity: 1;
    }

    10%,
    87.5% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Tag {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 1;
    }

    20%,
    92.5% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Buttons {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 0;
    }

    20% {
      opacity: 1;
    }

    25%,
    95% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  .copyright {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 18px;
    text-shadow: 0 0 10px #FFFFFF;
  }

  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }

  @keyframes shake {

    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }

  @keyframes Slide_Left {
    0% {
      transform: translateX(250px);
    }

    100% {
      transform: translateX(0);
    }
  }

  @keyframes Slide_Left_Right {

    0%,
    100% {
      transform: translateX(100px);
    }

    12.5%,
    75% {
      transform: translateX(0);
    }

    25% {
      transform: translateX(20px);
    }

  }

  @keyframes Opacity {

    0%,
    100% {
      opacity: 0;
    }

    25%,
    75% {
      opacity: 1;
    }


  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }

  @keyframes scaleIn {
    from {
      transform: scale(.5, .5);
      opacity: .5;
    }

    to {
      transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }

  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1, 1);
    }

    50% {
      transform: scale(1.05, 1.05);
    }

    100% {
      transform: scale(1, 1);
    }
  }

  .logo {
    width: 90px;
    height: 90px;
    display: none;

  }

  .logo2 {
    width: 20%;
    cursor: pointer;
  }

  #fontSize {
    font-size: 16px;
    font-family: "Gordita";
    cursor: pointer;
    z-index: 10000;
    font-weight: 500;
  }

  .parrotDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;

  }

  .pic1 {
    width: 32%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    left: 70%;
    bottom: 0;

  }

  .pic2 {
    width: 32%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    left: -5%;
    bottom: 0;

  }

  
  .cover {
    width: 100vw;
  }

  .cover2 {
    width: 100%;
  }

  .parrotPic {
    width: 100vw;
  }

  .parrot {
    width: 70%;
    border-radius: 30px;
  }

  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .rmMain {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: -100px;
  }

  .rmBox {
    text-align: right;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }

  .rmBox2 {
    text-align: right;
    width: 80%;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }

  .rmBox {
    font-weight: 700;
    color: rgb(58, 58, 58);
    margin-bottom: 60%;
  }

  .year {
    font-size: 18px;
    font-family: 'Poppins', sans-serif;
    color: rgb(8, 81, 94);
    letter-spacing: 1px;
  }


  .rmBoxMain2 {
    margin-top: 30%;
    width: 33.33%;
  }

  .rmBoxMain {
    width: max-content;
    justify-content: flex-start;
    width: 33.33%;

  }

  .rmBox2 {
    text-align: left;
    width: 30%;
  }

  .rmParrot {
    width: 130px;
    height: 186px;
    z-index: 1;
    justify-content: center;
    position: -webkit-sticky;
    position: sticky;
    top: 10%;
    margin-bottom: 25%;
    margin-right: auto;
    display: block;
    margin-left: 33%;
    margin-top: -50%;
  }

  .line {
    margin-left: auto;
    margin-right: auto;
    display: block;
    height: 270vh;
    margin-top: -30%;
  }

  .rmP {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 33.33%;
    height: fit-content;
  }

  .box {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: -15%;
  }

  .discordF {
    padding-left: 10px;
    padding-right: 10px;
  }

  .socialAndLogo {
    flex-flow: row nowrap;
    justify-content: space-between;
    display: flex;
    padding-left: 10%;
    padding-right: 10%;
    margin-bottom: 10%;
    padding-top: 10%;
  }

  .chartAndContent {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }

  .tCon {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    color: white;
    font-weight: 500;
  }

  .tCon p {
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 5px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 13px;
  }

  .chart {
    width: 60%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .boxWrapToken {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-left: 5%;
    padding-right: 5%;
    background: rgb(74, 175, 139);
    background: linear-gradient(0deg, rgba(74, 175, 139, 1) 0%, rgba(88, 176, 135, 1) 18%, rgba(74, 175, 128, 1) 100%);
  }

  .tH {
    color: #3b3b3b;
    font-weight: bold;
  }

  .cont {
    height: 100vh;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background: rgb(3, 23, 107);
    background: radial-gradient(circle, rgba(3, 23, 107, 1) 0%, rgba(3, 25, 54, 1) 74%, rgba(3, 20, 42, 1) 100%);
  }

  .web {
    width: 100%;
    height: 100%;
  }

  .cont .drip {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background: rgb(24, 70, 28);
    position: absolute;
    top: -100%;
    animation: falling 5s linear infinite;
  }

  @keyframes falling {
    0% {
      top: -100%;
    }

    50% {
      top: 0%;
    }

    80% {
      top: 80%;
    }

    100% {
      top: 100%;
    }
  }

  .cont .drip:nth-child(1) {
    border-color: black;
    height: 9px;
    width: 9px;
    animation-delay: -0.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 241px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(2) {
    border-color: black;
    height: 9px;
    width: 9px;
    animation-delay: -0.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 175px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(3) {
    border-color: black;
    height: 36px;
    width: 36px;
    animation-delay: -0.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 190px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(4) {
    border-color: black;
    height: 24px;
    width: 24px;
    animation-delay: -0.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 86px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(5) {
    border-color: black;
    height: 5px;
    width: 5px;
    animation-delay: -1s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 197px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(6) {
    border-color: black;
    height: 23px;
    width: 23px;
    animation-delay: -1.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 244px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(7) {
    border-color: black;
    height: 11px;
    width: 11px;
    animation-delay: -1.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 120px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(8) {
    border-color: black;
    height: 10px;
    width: 10px;
    animation-delay: -1.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 120px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(9) {
    border-color: black;
    height: 27px;
    width: 27px;
    animation-delay: -1.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 29px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(10) {
    border-color: black;
    height: 33px;
    width: 33px;
    animation-delay: -2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 154px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(11) {
    border-color: black;
    height: 39px;
    width: 39px;
    animation-delay: -2.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 109px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(12) {
    border-color: black;
    height: 23px;
    width: 23px;
    animation-delay: -2.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 272px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(13) {
    border-color: black;
    height: 13px;
    width: 13px;
    animation-delay: -2.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 41%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(14) {
    border-color: black;
    height: 21px;
    width: 21px;
    animation-delay: -2.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 71%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(15) {
    border-color: black;
    height: 1px;
    width: 1px;
    animation-delay: -3s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 95%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(16) {
    border-color: black;
    height: 12px;
    width: 12px;
    animation-delay: -3.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 76%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(17) {
    border-color: black;
    height: 12px;
    width: 12px;
    animation-delay: -3.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 71%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(18) {
    border-color: black;
    height: 38px;
    width: 38px;
    animation-delay: -3.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 46%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(19) {
    border-color: black;
    height: 1px;
    width: 1px;
    animation-delay: -3.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 41%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(20) {
    border-color: black;
    height: 19px;
    width: 19px;
    animation-delay: -4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 33%;
    margin-left: 60px;
  }


  .cont .drip:nth-child(21) {
    border-color: black;
    height: 9px;
    width: 9px;
    animation-delay: -0.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 42%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(22) {
    border-color: black;
    height: 9px;
    width: 9px;
    animation-delay: -0.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 58%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(23) {
    border-color: black;
    height: 36px;
    width: 36px;
    animation-delay: -0.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 49%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(24) {
    border-color: black;
    height: 24px;
    width: 24px;
    animation-delay: -0.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 73%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(25) {
    border-color: black;
    height: 5px;
    width: 5px;
    animation-delay: -1s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 83%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(26) {
    border-color: black;
    height: 23px;
    width: 23px;
    animation-delay: -1.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 28%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(27) {
    border-color: black;
    height: 11px;
    width: 11px;
    animation-delay: -1.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 14%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(28) {
    border-color: black;
    height: 10px;
    width: 10px;
    animation-delay: -1.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 43%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(29) {
    border-color: black;
    height: 27px;
    width: 27px;
    animation-delay: -1.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 68%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(30) {
    border-color: black;
    height: 33px;
    width: 33px;
    animation-delay: -2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 57%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(31) {
    border-color: black;
    height: 39px;
    width: 39px;
    animation-delay: -2.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 27%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(32) {
    border-color: black;
    height: 23px;
    width: 23px;
    animation-delay: -2.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 12%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(33) {
    border-color: black;
    height: 13px;
    width: 13px;
    animation-delay: -2.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 30%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(34) {
    border-color: black;
    height: 21px;
    width: 21px;
    animation-delay: -2.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 38%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(35) {
    border-color: black;
    height: 1px;
    width: 1px;
    animation-delay: -3s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 55%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(36) {
    border-color: black;
    height: 12px;
    width: 12px;
    animation-delay: -3.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 50%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(37) {
    border-color: black;
    height: 12px;
    width: 12px;
    animation-delay: -3.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 58%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(38) {
    border-color: black;
    height: 38px;
    width: 38px;
    animation-delay: -3.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 67%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(39) {
    border-color: black;
    height: 1px;
    width: 1px;
    animation-delay: -3.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 70%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(40) {
    border-color: black;
    height: 19px;
    width: 19px;
    animation-delay: -4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 90%;
    margin-left: 60px;
  }

  .donutAndInfo {
    display: flex;
    flex-flow: row nowrap;
  }

  .donut {
    width: 45px;
    height: 45px;
    margin-right: 2%;
  }

  .rmCon {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    width: 90%;
  }

  .phrases p {
    font-size: 18px;
    letter-spacing: 2px;

  }

  .shoe {
    width: 400px;
    height: 400px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .phrases {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: row nowrap;
    width: fit-content;
    margin-top: 3%;
  }

  .rmCon1 {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .teamCon {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    display: flex;
    flex-flow: column nowrap;

  }

  .teamCon img {
    width: 300px;
    height: 300px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    border: 3px solid white;
  }

  .memberDetails {
    color: white;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .memberName {
    font-size: 23px;
    text-align: center;
  }

  .memberCon {
    font-size: 20px;
    text-align: center;
  }


  * {
    box-sizing: border-box;
  }

  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 4rem;
    background-color: #aa5620;
    padding-left: 100%;
  }

  .ticker-wrap .ticker {
    display: inline-block;
    height: 4rem;
    line-height: 4rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 30s;
    animation-duration: 50s;
  }

  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 18px;
    color: #ffffff;
    font-weight: 800;
  }

  .shark {
    width: 400px;
    height: 572px;
    z-index: 1;
    margin-right: -3%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .teamMain {
    display: flex;
  }


  .memName {
    font-family: 'Poppins', sans-serif;
    color: #ffc000;
    font-size: 18px;
    text-align: center;
    padding-right: 2%;
    font-weight: 500;
  }

  .memNamePosition {
    color: #99bad6;
    font-size: 15px;
    text-align: center;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .tPic {
    width: 30px;
    height: 30px;
    cursor: pointer;
  }


  .man {
    width: 150px;
    height: 150px;
    border-radius: 100%;
    transition: transform .5s;
  }

  .man:hover {
    transform: scale(1.1);
    border: 2px solid #5496ec;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px #90bbf3;
  }

  .teamSection {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 70%;
    margin-top: 40px;

  }

  .teamSection2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    margin-top: 40px;
  }


  .tPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .subT {
    font-family: 'Poppins', sans-serif;
    color: #f9f305;
    font-size: 50px;
    text-align: left;
  }

  .manDiv {
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 15px;
  }

  .memName2 {
    color: rgb(255, 255, 255);
    text-align: center;
    font-weight: 500;
    text-shadow: 0 0 10px rgb(255, 255, 255);
  }

  .rmDetails p {
    color: rgb(209, 209, 209);
    padding-left: 20px;
    font-size: 45px;
  }

  .flowers {
    width: 100%;
    margin-top: -10%;
  }

  .rmPicAndCon {
    display: flex;
    justify-content: flex-start;
    margin-left: 20px;
  }

  .footPrint2 {
    width: 30px;
    height: 30px;
  }


  /* Background */
  .mainFoot {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    margin-top: -10%;
  }

  /* Footprint */
  .footprint {
    position: absolute;
    top: 20%;
    left: 0;
    animation: walk 5s steps(10, end) infinite, walkopacity 2s ease-out infinite;
    text-align: center;
  }

  /* Image Size */
  .foot {
    max-width: 20%;
  }

  /* Right Footprint */
  .rightFoot {
    margin: 5rem;
    animation-delay: 250ms;
  }

  /* Walking Animation */
  @keyframes walk {
    to {
      transform: translateX(100vw);
    }
  }

  /* Opacity During Walking */
  @keyframes walkopacity {

    0%,
    100% {
      opacity: 0;
    }

    50% {
      opacity: 0.4;
    }
  }

  .honeyComb {
    width: 450px;
    height: 345px;
    position: sticky;
    position: -webkit-sticky;
    left: 80%;
    bottom: 80px;
  }

  .dripCover {
    width: 100%;
  }

  .ball {
    width: 400px;
    height: 400px;
    animation: floating 5s ease-in-out infinite;
  }

  .ball2 {
    width: 400px;
    height: 400px;
    animation: floating2 5.25s ease-in-out infinite;
  }

  .ball3 {
    width: 400px;
    height: 400px;
    animation: floating3 5.5s ease-in-out infinite;
  }

  .ball4 {
    width: 130px;
    height: 130px;
    animation: floating4 2.1s ease-in-out infinite;
    margin-top: -200px;
    margin-left: 250px;
  }

  .ball5 {
    width: 130px;
    height: 130px;
    animation: floating4 2.3s ease-in-out infinite;
    margin-top: -400px;
    margin-left: 200px;
  }

  .ball6 {
    width: 130px;
    height: 130px;
    animation: floating4 2.8s ease-in-out infinite;
    margin-top: -1100px;
    margin-right: 600px;
  }

  .ball7 {
    width: 130px;
    height: 130px;
    animation: floating4 3s ease-in-out infinite;
    margin-top: -1100px;
    margin-left: 1200px;
  }

  /* Define the keyframes for the floating animation */
  @keyframes floating {
    0% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-10px);
    }

    100% {
      transform: translateY(0);
    }
  }

  @keyframes floating2 {
    0% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-10px);
    }

    100% {
      transform: translateY(0);
    }
  }

  @keyframes floating3 {
    0% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-10px);
    }

    100% {
      transform: translateY(0);
    }
  }

  @keyframes floating4 {
    0% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-10px);
    }

    100% {
      transform: translateY(0);
    }
  }

  .title {
    text-align: center;
    font-weight: bolder;
    font-size: 160px;
    font-family: 'Poppins', sans-serif;
    color: #d1d1e7;
    text-transform: uppercase;
    text-shadow: 0 0 10px #ffffff75;
    z-index: 1000;

  }

  .title2 {
    text-align: center;
    font-weight: bolder;
    font-size: 60px;
    font-family: 'Poppins', sans-serif;
    color: #d1d1e7;
    text-transform: uppercase;
    margin-top: -60px;
    text-shadow: 0 0 10px #ffffff75;
    z-index: 1000;

  }

  .title3 {
    font-size: 16px;
    color: rgb(202, 201, 201);
    width: 70%;
    justify-content: center;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    z-index: 1000;

  }

  .balls {
    display: flex;
    justify-content: center;
    margin-top: 90px;
  }

  .visionBox {
    color: white;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    width: 500px;
    padding: 20px;
    border-radius: 20px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 40px;
  }

  .visionBox:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }

  .missionBox {
    color: #a5f8f1;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    width: 500px;
    padding: 20px;
    border-radius: 20px;
    margin-left: auto;
    margin-right: auto;
  }

  .missionBox:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }

  .visionT {
    color: #a5f8f1;
    font-size: 30px;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    text-align: center;
  }

  .utilCon {
    color: white;
    font-size: 15px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    width: 80%;
  }

  .utilMainCon {
    width: 50%;
  }

  .utilMainCon img {
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }

  .tokenCon {
    display: flex;
    justify-content: space-between;
    flex-flow: row nowrap;
  }

  .platformMain {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
  }

  .tokenCon p {
    color: white;
    font-weight: 500;
  }

  th {
    font-weight: bold;
    font-size: 20px;
    color: #e3bcfa;
  }

  td {
    color: white;
    font-size: 16px;
  }

  table {
    width: 40%;
  }

  .chart {
    width: 55%;
    justify-content: flex-end;
  }

  .taxMain2 {
    display: flex;
    margin-top: 50px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  #customers {
    font-family: 'Poppins', sans-serif;
    border-collapse: collapse;
    width: 100%;
  }

  #customers td,
  #customers th {
    border: 1px solid #ddd;
    padding: 8px;
    font-size: 15px;
  }

  #customers tr:nth-child(even) {
    background-color: #f2f2f225;
  }

  #customers th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #ffc000;
    color: rgb(0, 0, 0);
  }

  #customers2 {
    font-family: 'Poppins', sans-serif;
    border-collapse: collapse;
    width: 90%;
    margin-right: 60px;
  }

  #customers2 td,
  #customers2 th {
    border: 1px solid #ddd;
    padding: 8px;
    font-size: 15px;
  }

  #customers2 tr:nth-child(even) {
    background-color: #b7d5f725;
  }

  #customers2 th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #8297f7;
    color: rgb(0, 0, 0);
  }


  #customers3 {
    font-family: 'Poppins', sans-serif;
    border-collapse: collapse;
    width: 100%;
    margin-right: 60px;
  }

  #customers3 td,
  #customers3 th {
    border: 1px solid #ddd;
    padding: 8px;
    font-size: 15px;
  }

  #customers3 tr:nth-child(even) {
    background-color: #b7d5f725;
  }

  #customers3 th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #8297f7;
    color: rgb(0, 0, 0);
  }

  .platformMain2 {
    display: flex;
    justify-content: space-evenly;
    margin-top: 50px;
  }

  .platformMain3 {
    justify-content: center;
    margin-top: 50px;
  }

  .pT2 {
    font-family: 'Poppins', sans-serif;
    color: #ffc000;
    font-size: 20px;
  }

  .tableCon2 p {
    color: rgb(145, 196, 245);
  }

  .tableCon2 {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .tableCon3 {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .alien {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);

    transform-origin: center;
    transform: perspective(800px) rotateY(25deg);
    transition: 0.5s;
  }

  .alien:hover {
    transform: perspective(800px) rotateY(0deg);
    opacity: 1;
  }

  .tableCon2Main {
    margin-top: auto;
    margin-bottom: auto;
  }

  .winnerNote {
    color: rgb(145, 196, 245);
    text-align: center;
    margin-top: 10px;
  }

  .nftT {
    font-weight: bold;
    color: white;
    font-size: 17px;
  }

  .nftT2 {
    color: white;
    font-size: 16px;

  }

  .nftCon {
    margin-bottom: 20px
  }

  #customers4 {
    font-family: 'Poppins', sans-serif;
    border-collapse: collapse;
    width: 100%;
  }

  #customers4 td,
  #customers4 th {
    border: 1px solid #ddd;
    padding: 8px;
    font-size: 15px;
  }

  #customers4 tr:nth-child(even) {
    background-color: #7ec5ff25;
  }

  #customers4 th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #ffc000;
    color: rgb(0, 0, 0);
  }

  .alien {
    width: 350px;
    height: 350px;
    border-radius: 20px;
    margin-right: 30px;
  }

  .nftInfoT {
    color: #ffc000;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    font-size: 20px;
  }

  .nftInfo {
    color: rgb(162, 208, 252);
    font-size: 16px;
  }

  .note {
    width: 30px;
    height: 30px;
  }

  .com {
    color: #767698;
    font-size: 13px;
  }

  a {
    color: #767698;
    font-size: 14px;
    text-decoration: none;
  }

  a:hover {
    color: #a2a2c4;
    font-size: 14px;
    text-decoration: underline;
  }

  .comInfo {
    display: flex;
    justify-content: space-between;
    margin-top: 60px;
  }

  .lineF {
    width: 100%;
    height: 1px;
    background-color: rgba(75, 63, 78, 0.3);
    margin-bottom: 20px;
  }

  .lineF2 {
    width: 100%;
    height: 1px;
    background-color: rgba(75, 63, 78, 0.3);
    margin-top: 20px;
  }

  .cs {
    color: #5c6c70;
    margin-top: -20px;
    margin-bottom: 60px;
    font-size: 15px;
  }

}

@media screen and (min-width: 1200px) and (max-width: 1919px) {
  #fontSize2{
    display: none;
  }
  .lotteryMain4{
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

/*.............. FAQ ..............*/
.totalTickets{
  color: white;
  font-size: 30px;
  margin-bottom: 80px;
}
.pomPrice{
  color: #4ac7fc;
  text-align: center;
  margin-top: 25px;
  font-size: 18px;
 }
/* CSS */
.button-29 {
  align-items: center;
  appearance: none;
  background-image: radial-gradient(100% 100% at 100% 0, #fff2b7 0, #ffd000 100%);
  border: 0;
  border-radius: 6px;
  box-shadow: rgba(45, 35, 66, .4) 0 2px 4px,rgba(45, 35, 66, .3) 0 7px 13px -3px,rgba(58, 65, 111, .5) 0 -3px 0 inset;
  box-sizing: border-box;
  color: rgb(0, 0, 0);
  cursor: pointer;
  display: inline-flex;
  font-family: "JetBrains Mono",monospace;
  height: 48px;
  justify-content: center;
  line-height: 1;
  list-style: none;
  overflow: hidden;
  padding-left: 16px;
  padding-right: 16px;
  position: relative;
  text-align: left;
  text-decoration: none;
  transition: box-shadow .15s,transform .15s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  will-change: box-shadow,transform;
  font-size: 18px;
  width: 230px;
}

.button-29:focus {
  box-shadow: #dab200 0 0 0 1.5px inset, rgba(45, 35, 66, .4) 0 2px 4px, rgba(45, 35, 66, .3) 0 7px 13px -3px, #f3cc1d 0 -3px 0 inset;
}

.button-29:hover {
  box-shadow: rgba(45, 35, 66, .4) 0 4px 8px, rgba(45, 35, 66, .3) 0 7px 13px -3px, #9c8001 0 -3px 0 inset;
  transform: translateY(-2px);
}

.ticketsSeparately{
  display: flex;
  flex-flow: column nowrap;
}


summary {
  font-size: 20px;
  background: #af3d330c;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  color: rgb(0, 0, 0);
  padding: 1rem;
  margin-bottom: 1rem;
  outline: none;
  text-align: left;
  cursor: pointer;
  color: white;
  border: 2px solid rgb(197, 197, 197);
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(255, 255, 255, 0.61);
}

details>summary::-webkit-details-marker {
  display: none;
}

details[open] summary~* {
  animation: sweep .5s ease-in-out;
}

@keyframes sweep {
  0% {
    opacity: 0;
    margin-top: -10px
  }

  100% {
    opacity: 1;
    margin-top: 0px
  }
}

.accordin {
  width: 70%;
  color: white;
  display: inline-block;
  flex-direction: column;
  margin-bottom: 85px;
}

.faq__content {

  text-shadow: 0 0 10px #FFFFFF;
  padding-bottom: 20px;
  text-align: left;
  font-size: 17px;
}

.faqTitle {
  margin-bottom: 20px;
  text-align: center;
  font-size: 70px;
  color: white;
  font-family: 'Poppins', sans-serif;
}

.faqbg {
  width: 100%;
  text-align: left;
  padding-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  width: 100%;
  padding-bottom: 10%;

}

.lotterySubH{
  justify-content: center;
  display: flex;
  font-size: 40px;
  font-family: 'Poppins', sans-serif;
  text-align: left;
  color: white;
  font-weight: bold;
  margin-top: -20px;
    border: 2px solid rgb(160, 160, 255);
   box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(120, 120, 226);
   width: 600px;
   margin-left: auto;
   margin-right: auto;
   border-bottom: 0px;
}

.connect div:hover {
  color: #ffc000;
}

.boxWrap2Lottery {
  display: flex;
  flex-flow: column nowrap;
  padding-bottom: 5%;
  padding-top: 5%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  width: 100%;
  background: rgb(0, 29, 56);
  background: linear-gradient(10deg, rgb(0, 37, 54) 0%, rgb(18, 17, 1) 34%, rgba(1, 6, 18, 1) 100%);
  padding-left: 5%;
  padding-right: 5%;
  
}

.lottery{
  width: 270px;
  height: 300px;
}

.ticketCategory{
  color: white;
  margin-left: auto;
  margin-right: auto;
}

.lotteryMain {
  margin-top: 30px;
   display: flex;
  flex-flow: column nowrap;
  margin-bottom: 5%;
}

.lotteryMain2 {
   justify-content: space-evenly;
  display: flex;
  flex-flow: row nowrap;
 /* From https://css.glass */
 background: url('./assets/Container.png') no-repeat center center fixed; 
 -webkit-background-size: cover;
 -moz-background-size: cover;
 -o-background-size: cover;
 background-size: cover;
 padding: 60px;
  border: 2px solid rgb(160, 160, 255);
 box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(120, 120, 226);
 padding-top: 10px;
 margin-bottom: 120px;
}

.lotteryMain2 {
  justify-content: space-evenly;
 display: flex;
 flex-flow: row nowrap;
/* From https://css.glass */
background: url('./assets/Container.png') no-repeat center center fixed; 
-webkit-background-size: cover;
-moz-background-size: cover;
-o-background-size: cover;
background-size: cover;
padding: 60px;
 border: 2px solid rgb(160, 160, 255);
box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(120, 120, 226);
padding-top: 10px;
margin-bottom: 120px;
}

.lotteryMain2-2 {
  justify-content: center;
 display: flex;
 flex-flow: column nowrap;
/* From https://css.glass */
background: url('./assets/Container.png') no-repeat center center fixed; 
-webkit-background-size: cover;
-moz-background-size: cover;
-o-background-size: cover;
background-size: cover;
padding: 60px;
 border: 2px solid rgb(160, 160, 255);
box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(120, 120, 226);
padding-top: 10px;
margin-bottom: 120px;
margin-left: auto;
margin-right: auto;
width: 90%;
}

.lotteryMain3 {
  justify-content: space-evenly;
  flex-flow: column nowrap;
/* From https://css.glass */
background: url('./assets/Container.png') no-repeat center center fixed; 
-webkit-background-size: cover;
-moz-background-size: cover;
-o-background-size: cover;
background-size: cover;
padding: 60px;
 border: 2px solid rgb(160, 160, 255);
box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(120, 120, 226);
padding-top: 10px;
margin-bottom: 120px;
}

.drawBtn{
  background-color: white;
  color: black;
  border: none;
  padding: 15px;
  font-weight: 500;  
}

.autoPickedMain{
  background: url('./assets/Container.png') no-repeat center center fixed; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  padding: 60px;
   border: 2px solid rgb(160, 160, 255);
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(120, 120, 226);
   margin-bottom: 120px;
}

.autoPickedMain p{
  color: white;
  text-align: center;
}

.kave-btn {
  position: relative;
  border: solid 1px rgba(194, 191, 193, 0.3);
  background: none;
  color: white;
  letter-spacing: 1.8px;
  width: 250px;
  height: 50px;
  padding: 0;
  transition: all 0.25s ease;
  outline: none;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 60px;
}

.kave-btn:before {
  content: "";
  position: absolute;
  top: 0;
  left: 10%;
  width: 60px;
  height: 1px;
  transform: translateY(-1px);
  background: #93edfd;
  transition: all 0.25s ease;
}

.kave-btn:after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 10%;
  width: 60px;
  height: 1px;
  transform: translateY(1px);
  background: #00d6fc;
  transition: all 0.25s ease;
}

.kave-btn:hover {
  box-shadow: 1px 1px 8px rgba(0, 214, 252, 0.3);
  color: #00d6fc;
  text-shadow: 0 0 8px rgba(0, 214, 252, 0.4);
}

.kave-btn:hover.kave-btn:before {
  left: 0;
  width: 20px;
}

.kave-btn:hover.kave-btn:after {
  right: 0;
  width: 20px;
}

.kave-btn:hover .kave-line:before {
  bottom: 0;
}

.kave-btn:hover .kave-line:after {
  top: 0;
}

.kave-line:before {
  content: "";
  position: absolute;
  bottom: 30%;
  right: 0;
  width: 1px;
  height: 20px;
  transform: translateX(1px);
  background: #00d6fc;
  transition: all 0.25s ease;
}

.kave-line:after {
  content: "";
  position: absolute;
  top: 30%;
  left: 0;
  width: 1px;
  height: 20px;
  transform: translateX(-1px);
  background: #00d6fc;
  transition: all 0.25s ease;
}

/* CSS */
  .button-92 {
    --c: #fff;
    /* text color */
    background: linear-gradient(90deg, #0000 33%, #fff5, #0000 67%) var(--_p,100%)/300% no-repeat,
      #004dff;
    /* background color */
    color: #0000;
    border: none;
    transform: perspective(500px) rotateY(calc(20deg * var(--_i,-1)));
    text-shadow: calc(var(--_i,-1) * 0.08em) -.01em 0   var(--c),
      calc(var(--_i,-1) * -0.08em)  .01em 2px #0004;
    outline-offset: .1em;
    transition: 0.3s;
  }

.button-92:hover,
.button-92:focus-visible {
  --_p: 0%;
  --_i: 1;
}

.button-92:active {
  text-shadow: none;
  color: var(--c);
  box-shadow: inset 0 0 9e9q #0005;
  transition: 0s;
}

.button-92 {
  font-weight: bold;
  font-size: 2rem;
  margin: 0;
  cursor: pointer;
  padding: .1em .3em;
}
  
  .aboutMob {
    display: none;
  }

  .special:hover {
    background-color: rgb(228, 194, 0);

  }

  .taxMain2Mob {
    display: none;
  }

  .teamSectionMob {
    display: none;
  }

  .teamSection2Mob {
    display: none;
  }

  .special:active {
    background-color: rgb(228, 194, 0);
    box-shadow: -0px 7px rgb(153, 131, 4);
    transform: translateY(4px);
  }

  .special2:hover {
    background-color: rgb(228, 194, 0);

  }

  .special2:active {
    background-color: rgb(228, 194, 0);
    box-shadow: -0px 7px rgb(153, 131, 4);
    transform: translateY(4px);
  }

  .placeAndWL {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 50px;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    background: rgba(82, 82, 82, 0.2);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 30px;
  }

  .special {
    font-size: 20px;
    margin-left: 30px;
    border-radius: 10px;
    background-color: gold;
    border: none;
    padding: 10px;
    box-shadow: -0px 5px rgb(194, 165, 5);
  }

  .special2 {
    font-size: 20px;
    border-radius: 10px;
    background-color: gold;
    border: none;
    padding: 10px;
    box-shadow: -0px 5px rgb(194, 165, 5);
  }

  .mintingAmount {
    border: black 2px solid;
    border-radius: 10px;
    box-shadow: -0px 5px black;
    padding: 10px;
  }

  h1 {
    text-align: center;
    margin-bottom: 200px;
    margin-top: 100px;
    font-size: 50px;
    font-weight: 600;
    color: #0b72d3;
  }

  .timeline-container {
    display: flex;
    justify-content: center;
    margin-top: 80px;
  }

  .timeline-container .vertical-scrollable-timeline {
    width: 700px;
    list-style-type: none;
  }

  .timeline-container .vertical-scrollable-timeline li {
    position: relative;
    padding: 35px 0px 125px 158px;
  }

  .timeline-container .vertical-scrollable-timeline li:last-child {
    margin-bottom: 0;
  }

  .timeline-container .vertical-scrollable-timeline li h2 {
    font-size: 20px;
    margin-bottom: 10px;
    color: #ffc000;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
  }

  .timeline-container .vertical-scrollable-timeline li p {
    line-height: 1.5;
    color: rgb(198, 221, 255);
    font-size: 16px;
  }

  .timeline-container .vertical-scrollable-timeline li p:last-child {
    margin-bottom: 0;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder {
    position: absolute;
    left: 0;
    top: 0;
    width: 104px;
    height: 104px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #c5c3cb;
    border-radius: 50%;
    z-index: 1;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder::before {
    content: "";
    width: 80px;
    height: 80px;
    border: 4px solid #fff;
    position: absolute;
    background-color: #c5c3cb;
    border-radius: 50%;
    z-index: -1;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder i {
    font-size: 25px;
    color: #fff;
  }

  .timeline-container .vertical-scrollable-timeline li::after {
    content: "";
    position: absolute;
    height: 0;
    width: 8px;
    background-color: #3f96e7;
    left: 48px;
    top: 34px;
    z-index: 0;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 8px;
    background-color: #c5c3cb;
    left: 48px;
    z-index: 0;
  }

  .timeline-container .vertical-scrollable-timeline li:last-child::before {
    content: unset;
  }

  .timeline-container .vertical-scrollable-timeline li.active .icon-holder {
    background-color: #ffc000;
  }

  .timeline-container .vertical-scrollable-timeline li.active .icon-holder::before {
    background-color: #4372c3;
  }

  .timeline-container .vertical-scrollable-timeline li.active::after {
    height: 100%;
  }

  .timeline-container .vertical-scrollable-timeline li.active:last-child::after {
    content: unset;
  }


  figure {
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
  }

  figure:nth-child(1) .rmDetails {
    transform: scale(0.6) rotate(-12.5deg);
  }

  figure:nth-child(2) .rmDetails {
    transform: scale(0.6) rotate(-9.7222222222deg);
  }

  figure:nth-child(3) .rmDetails {
    transform: scale(0.6) rotate(-6.9444444444deg);
  }

  figure:nth-child(4) .rmDetails {
    transform: scale(0.6) rotate(-4.1666666667deg);
  }

  figure:nth-child(5) .rmDetails {
    transform: scale(0.6) rotate(-1.3888888889deg);
  }

  figure:nth-child(6) .rmDetails {
    transform: scale(0.6) rotate(1.3888888889deg);
  }

  figure:nth-child(7) .rmDetails {
    transform: scale(0.6) rotate(4.1666666667deg);
  }

  figure:nth-child(8) .rmDetails {
    transform: scale(0.6) rotate(6.9444444444deg);
  }

  figure:nth-child(9) .rmDetails {
    transform: scale(0.6) rotate(9.7222222222deg);
  }

  figure:nth-child(10) .rmDetails {
    transform: scale(0.6) rotate(12.5deg);
  }

  figure .rmDetails {
    width: 100%;
    border-radius: 3px;
    background-color: black;
    padding: 50px;
    border: 5px solid gray;
  }

  .connect2Mobile {
    display: none;
  }

  .discordNone {
    transition: transform .2s;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: no-drop;
  }

  .discordNone:hover {
    transform: scale(1.1);
  }

  .linkedin {
    width: 30px;
    height: 30px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 10px;
    cursor: pointer;

  }

  .team1 {
    width: 50%;
  }

  .discord3 {
    display: none;
  }

  .discord4 {
    display: none;
  }

  .storyHRmM {
    display: none;
  }

  .iconsMob {
    display: none;
  }

  .mainBanner {
    display: none;
  }

  .icons {
    display: flex;
    flex-flow: row nowrap;
  }

  .cover52 {
    display: none;
  }

  .coverRm {
    width: 100vw;
  }

  .discordF2 {
    display: none;
  }

  .connectMobile {
    display: none;
  }

  .rightMobile {
    display: none;
  }

  .osPic {
    width: 30px;
    height: 30px;
    cursor: pointer;
    z-index: 10000;
  }

  .osPic2 {
    width: 40px;
    height: 40px;
    cursor: pointer;
  }

  .price {
    text-align: center;
    font-size: 22px;
    color: #aef6ff;
  }

  .discord2 {
    display: none;
  }

  .discord3 {
    padding-right: 20px;
    display: none;
  }


  .uRMobile {
    display: none;
  }

  .storyPicDivMobile {
    display: none;
  }

  .allWrap {

    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    contain: content;
  }

  .boxWrap2 {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background-color: black;
  }

  .boxWrap2Story {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: rgb(0, 88, 81);
    background: radial-gradient(circle, rgba(0, 88, 81, 1) 0%, rgba(0, 42, 47, 1) 34%, rgb(3, 14, 41) 100%);
    padding-left: 5%;
    padding-right: 5%;
  }

  .boxWrap2Utility {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: rgb(0, 45, 56);
    background: linear-gradient(0deg, rgba(0, 45, 56, 1) 0%, rgba(1, 6, 18, 1) 34%, rgba(1, 6, 18, 1) 100%);
    padding-left: 5%;
    padding-right: 5%;
  }

  .boxWrap2Team {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: rgb(0, 41, 79);
    background: radial-gradient(circle, rgba(0, 41, 79, 1) 0%, rgba(0, 10, 25, 1) 74%, rgba(0, 10, 25, 1) 100%);
    padding-left: 5%;
    padding-right: 5%;
  }

  .boxWrap2Mint {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: rgb(0, 41, 79);
    background: radial-gradient(circle, rgba(0, 41, 79, 1) 0%, rgba(0, 10, 25, 1) 74%, rgba(0, 10, 25, 1) 100%);
    padding-left: 5%;
    padding-right: 5%;
  }

  .boxWrap2Platform {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: rgb(21, 38, 7);
    background: linear-gradient(309deg, rgba(21, 38, 7, 1) 0%, rgba(0, 10, 25, 1) 74%, rgba(0, 10, 25, 1) 100%);
    padding-left: 5%;
    padding-right: 5%;
  }

  .boxWrap2Tokenomic {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: rgb(0, 5, 27);
    background: linear-gradient(97deg, rgba(0, 5, 27, 1) 0%, rgba(43, 22, 56, 1) 74%, rgba(0, 5, 27, 1) 100%);
    padding-left: 5%;
    padding-right: 5%;
  }

  .sharks {
    width: 100%;
  }

  .boxWrapC {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    position: relative;

    padding-bottom: 5%;
  }

  .green {
    background-color: #97ba5e;

  }

  .about {
    margin-top: 30px;
    justify-content: space-evenly;
    display: flex;
    flex-flow: row nowrap;
  }

  .aboutVAndM {
    margin-top: 30px;
    justify-content: space-evenly;
    display: flex;
    flex-flow: row nowrap;
  }

  .about2 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 90%;
  }

  .aboutImg {
    width: 50%;
    border: white 6px solid;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-bottom: 3%;
  }

  .aboutImg2 {
    width: 28%;
    height: 28%;
    border: white 6px solid;
    transition: transform .5s;
  }

  .aboutImg2:hover {
    transform: scale(1.2);
  }

  .utilityPics {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
  }

  .roadmapMain1 {
    top: 0;
    position: relative;
  }

  .storyPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .UtilDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .storyH {
    justify-content: left;
    display: flex;
    font-size: 18px;
    font-family: "Gordita";
    color: #00fae5;
    text-align: left;
  }

  .utilH {
    justify-content: left;
    display: flex;
    font-size: 18px;
    font-family: "Gordita";
    color: #00c8fa;
    text-align: left;
  }

  .TokenomicH {
    justify-content: left;
    display: flex;
    font-size: 18px;
    font-family: "Gordita";
    color: #c86ffc;
    text-align: left;
  }

  .platformH {
    justify-content: left;
    display: flex;
    font-size: 18px;
    font-family: "Gordita";
    color: #a2f55e;
    text-align: left;
  }

  .nftH {
    justify-content: left;
    display: flex;
    font-size: 18px;
    font-family: "Gordita";
    color: #0278ee;
    text-align: left;
  }

  .teamH {
    justify-content: left;
    display: flex;
    font-size: 18px;
    font-family: "Gordita";
    color: #ffc959;
    text-align: left;
  }

  .storyH2 {
    justify-content: left;
    display: flex;
    font-size: 50px;
    font-family: 'Poppins', sans-serif;
    text-align: left;
    color: white;
    font-weight: bold;
    margin-top: -20px;
  }

  .taxT {
    justify-content: center;
    display: flex;
    font-size: 40px;
    font-family: 'Poppins', sans-serif;
    text-align: left;
    color: white;
    font-weight: bold;
    margin-top: -20px;
  }

  .taxMain {
    margin-top: 150px;
  }

  .mintH {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    font-size: 90px;
    background: -webkit-linear-gradient(#f0d9a3, #ff9d01);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: "Gordita";
    -webkit-text-stroke-width: 3px;
    -webkit-text-stroke-color: black;
  }

  .storyRm img {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .tokenH {
    font-size: 70px;
    color: white;
    font-family: 'Poppins', sans-serif;
    text-align: center;
    margin-bottom: 2%;
  }


  .carouselH {
    font-size: 70px;
    color: white;
    font-family: 'Poppins', sans-serif;
    text-align: center;
    margin-bottom: 2%;
  }

  .rmH {
    font-size: 80px;
    color: white;
    font-family: 'Poppins', sans-serif;
    text-align: center;
    background-color: #fe8a00;
    padding-left: 20px;
    margin-bottom: 20px;
  }

  .rmH2 {
    color: grey;
    font-size: 18px;
    line-height: 30px;
  }

  .rmName {
    background: rgb(81, 190, 229);
    background: linear-gradient(0deg, rgba(81, 190, 229, 1) 2%, rgba(81, 190, 229, 1) 20%, rgba(6, 136, 198, 1) 63%, rgba(6, 136, 198, 1) 100%);
  }



  .storyCon {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 70%;
    margin-left: auto;
    margin-right: auto;

  }

  .storyCon-2 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    padding: 20px;
    border-radius: 50px;
    box-shadow: -10px 10px rgba(14, 21, 36, 0.363);
    background-color: rgba(30, 46, 80, 0.568);

  }

  .storyCon2 {
    font-size: 30px;
    font-family: 'Poppins', sans-serif;
    text-align: center;
  }

  .storyConP {
    font-size: 20px;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
  }

  .storyPic {
    width: 540px;
    height: 339px;
  }

  .storyRight {
    width: 45%;
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
  }

  .uR {
    width: 50%;
  }

  .intro {
    color: white;
    font-size: 100px;
    text-align: center;
    font-family: 'Riangriung', sans-serif;
  }

  .logoF {
    color: white;
    text-align: center;
    font-size: 50px;
    background: -webkit-linear-gradient(#f0d9a3, #ff9d01);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: "Gordita";
  }

  .logoF img {
    width: 50%;
  }

  .intro img {
    width: 500px;
    height: 500px;
  }

  .intro3 {
    font-style: italic;
    font-size: 20px;
    font-weight: 500;
  }

  .intro2 {
    color: rgb(0, 0, 0);
    font-size: 23px;
    text-align: left;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    font-weight: bold;
    width: 50%;
  }

  .mintDiv {
    padding-top: 3%;
    padding-bottom: 3%;
    width: 700px;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.493) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
    margin-bottom: 80px;
    border: 2px solid rgb(119, 222, 248);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(106, 184, 243);
    margin-top: 50px;
  }

  .errorMessage {
    margin-left: 3%;
    margin-top: 1%;
    font-size: 18px;
    color: #fc4741;
    text-align: center;
    text-shadow: -1px -1px 1px rgba(255, 255, 255, .1), 1px 1px 1px rgba(0, 0, 0, .5);
  }

  .loadingContainer {
    margin-top: 1%;
  }

  .loadingText {
    font-size: 20px;
    text-align: center;
    color: rgb(120, 184, 189);
    font-weight: bold;
  }

  .loadTextSub {
    text-align: center;
    color: rgb(157, 194, 199);
    font-size: 13px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;

  }

  .wPMobile {
    display: none;
  }

  .introductionMobile {
    display: none
  }

  .hl {
    color: #6ce34d;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
  }

  .right2 {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 1.5%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .discord {
    transition: transform .2s;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .discord:hover {
    transform: scale(1.1);
  }


  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(99, 31, 177);
  }



  .note {
    color: rgb(255, 255, 255);
  }

  .twitterSoc {
    margin-top: 10px;
    margin-right: 5px;
    /* z-index: -2;*/
    margin-left: 5px;

  }

  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/

  }

  /*.website{
  margin-top: 10px;
  margin-left: 10px;
 /* z-index: -2;*/
  /*color: #ffffffa2;
}*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;

  }





  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    padding-left: 50px;
    padding-right: 50px;

    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);


  }

  .wallet:hover {
    background-color: white;
    color: rgb(73, 81, 190);
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-family: "Gordita";
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    font-family: "Gordita";
    align-items: center;
  }

  .connect div {
    margin-left: 10px;
    margin-right: 10px;
    font-family: "Gordita";
    color: #ffffff;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease;
    font-weight: 500;
  }


  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }




  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding-bottom: 50px;
    margin-left: auto;
    margin-right: auto;

  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-bottom: 20px;
  }

  .center {
    margin-top: auto;
    margin-bottom: auto;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }

  .btnfos-0-2 {
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    height: 70px;
    border: none;
    font-family: 'Poppins', sans-serif;
    cursor: pointer;
    background-color: #3a9ae802;
    border: white 2px solid;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    height: 70px;
    border: none;
    font-family: 'Poppins', sans-serif;
    cursor: pointer;
    background-color: #3a9ae802;
    border: white 2px solid;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }


  .btnfos-0-2:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(134, 134, 134, 0.288) -5px 5px;
  }


  .btnfos-0-2-2:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(134, 134, 134, 0.288) 5px 5px;
  }


  .btnfos-0-3 {
    margin-top: 20px;
    background-color: #6cfc19;
    color: #000000;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 28px;
    border-radius: 50px;
    padding-left: 30px;
    padding-right: 30px;
    transition: transform .2s;
    border: none;
    font-family: 'Poppins', sans-serif;
    transition: transform .2s;
  }

  .btnfos-0-3:hover {
    transform: scale(1.1);
  }

  .nftamount {
    color: cyan;
    font-size: 85px;
    font-family: 'Poppins', sans-serif;
   }

  .walletConnect {

    display: flex;
    justify-content: center;
  }

  .connectButton {

    font-size: 45px;
  }

  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;

  }

  .loadingContainer {
    text-align: center;
    padding-top: 20px;
  }

  .errorMessage {
    text-align: center;
    padding-top: 20px;
  }

  .successfully {
    text-align: center;
    padding-top: 20px;
    color: #1ee03e;
    font-family: 'Poppins', sans-serif;
    font-size: 30px;
    text-shadow: -1px -1px 1px rgba(255, 255, 255, .1), 1px 1px 1px rgba(0, 0, 0, .5);
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #000000;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    /* border-left: #2d363600;
  border-right: #2d363600;
  border-top: #2d363600;*/
    border-bottom: 1px solid rgba(59, 45, 87, 0.863);
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    /* border-left: #2d363600;
  border-right: #2d363600;
  border-top: #2d363600;*/
    margin-top: auto;
    margin-bottom: auto;
 
  }

  .h2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    background-color: #5ebaad;

    /* border-left: #2d363600;
  border-right: #2d363600;
  border-top: #2d363600;*/

  }

  .introduction {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    z-index: 1000;
    background: rgba(3, 24, 107, 0.733);
    background: radial-gradient(circle, rgba(3, 24, 107, 0.747) 0%, rgba(3, 25, 54, 0.705) 74%, rgba(3, 20, 42, 0.781) 100%);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    padding-bottom: 50px;
  }

  .wallet2 {
    z-index: 1000;
    background-color: #000000;
    color: #ffffff;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 20px;
    border-radius: 50px;
    width: 270px;
    transition: transform .2s;
    border: none;
    font-family: "Gordita";
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    transition: 0.5s ease;
    cursor: pointer;
    box-shadow: 0 3px #363636;
    letter-spacing: 2px;
  }

  .wallet2:hover {
    color: #000000;
    background-color: #ffffff;
  }

  .wallet2Btn {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    width: 400px;
    border: 2px solid #ffffff;
    margin-left: 10px;
    margin-right: 10px;
    border: 2px solid #2E8B57;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
  }

  .wallet2Btn:hover {
    color: rgb(156, 156, 156);
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }


  .wallet3 {
    background-image: linear-gradient(to right top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1);
    color: rgb(0, 0, 0);
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 30px;
    font-weight: bold;
    border-radius: 15px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 50px;
    padding-right: 50px;
    box-shadow: black 0px 5px;
  }

  .wallet3:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }

  @keyframes shake {

    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }

  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }

  .in2 {
    width: 100%;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .totalSupply {
    text-align: center;
    font-size: 60px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    font-family: 'Poppins', sans-serif;
    text-shadow: 0 0 10px #ffffff93;
    letter-spacing: 1px;
    margin-top: 1%;
    font-weight: bold;
  }

  @keyframes Slide_Up {
    0% {
      transform: translateY(250px);
      opacity: 1;
    }

    10%,
    87.5% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Tag {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 1;
    }

    20%,
    92.5% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Buttons {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 0;
    }

    20% {
      opacity: 1;
    }

    25%,
    95% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  .copyright {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 18px;
    text-shadow: 0 0 10px #FFFFFF;
  }

  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }

  @keyframes shake {

    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }

  @keyframes Slide_Left {
    0% {
      transform: translateX(250px);
    }

    100% {
      transform: translateX(0);
    }
  }

  @keyframes Slide_Left_Right {

    0%,
    100% {
      transform: translateX(100px);
    }

    12.5%,
    75% {
      transform: translateX(0);
    }

    25% {
      transform: translateX(20px);
    }

  }

  @keyframes Opacity {

    0%,
    100% {
      opacity: 0;
    }

    25%,
    75% {
      opacity: 1;
    }


  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }

  @keyframes scaleIn {
    from {
      transform: scale(.5, .5);
      opacity: .5;
    }

    to {
      transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }

  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1, 1);
    }

    50% {
      transform: scale(1.05, 1.05);
    }

    100% {
      transform: scale(1, 1);
    }
  }

  .logo {
    width: 100px;
    height: 100px;
    display: none;

  }

  .logo2 {
    width: 20%;
    cursor: pointer;
  }

  #fontSize {
    font-size: 17px;
    font-family: "Gordita";
    cursor: pointer;
    z-index: 10000;
    font-weight: 500;
  }

  .parrotDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;

  }

  .pic1 {
    width: 32%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    left: 70%;
    bottom: 0;

  }

  .pic2 {
    width: 32%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    left: -5%;
    bottom: 0;

  }

  
  .cover {
    width: 100vw;
  }

  .cover2 {
    width: 100%;
  }

  .parrotPic {
    width: 100vw;
  }

  .parrot {
    width: 60%;
    border-radius: 30px;
  }

  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .rmMain {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: -100px;
  }

  .rmBox {
    text-align: right;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }

  .rmBox2 {
    text-align: right;
    width: 80%;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }

  .rmBox {
    font-weight: 700;
    color: rgb(58, 58, 58);
    margin-bottom: 60%;
  }

  .year {
    font-size: 18px;
    font-family: 'Poppins', sans-serif;
    color: rgb(8, 81, 94);
    letter-spacing: 1px;
  }


  .rmBoxMain2 {
    margin-top: 30%;
    width: 33.33%;
  }

  .rmBoxMain {
    width: max-content;
    justify-content: flex-start;
    width: 33.33%;

  }

  .rmBox2 {
    text-align: left;
    width: 30%;
  }

  .rmParrot {
    width: 130px;
    height: 186px;
    z-index: 1;
    justify-content: center;
    position: -webkit-sticky;
    position: sticky;
    top: 10%;
    margin-bottom: 25%;
    margin-right: auto;
    display: block;
    margin-left: 33%;
    margin-top: -50%;
  }

  .line {
    margin-left: auto;
    margin-right: auto;
    display: block;
    height: 270vh;
    margin-top: -30%;
  }

  .rmP {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 33.33%;
    height: fit-content;
  }

  .box {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: -15%;
  }

  .discordF {
    padding-left: 10px;
    padding-right: 10px;
  }

  .socialAndLogo {
    flex-flow: row nowrap;
    justify-content: space-between;
    display: flex;
    padding-left: 10%;
    padding-right: 10%;
    margin-bottom: 10%;
    padding-top: 10%;
  }

  .chartAndContent {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }

  .tCon {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    color: white;
    font-weight: 500;
  }

  .tCon p {
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 5px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 13px;
  }

  .chart {
    width: 60%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .boxWrapToken {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-left: 5%;
    padding-right: 5%;
    background: rgb(74, 175, 139);
    background: linear-gradient(0deg, rgba(74, 175, 139, 1) 0%, rgba(88, 176, 135, 1) 18%, rgba(74, 175, 128, 1) 100%);
  }

  .tH {
    color: #3b3b3b;
    font-weight: bold;
  }

  .cont {
    height: 100vh;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background: rgb(3, 23, 107);
    background: radial-gradient(circle, rgba(3, 23, 107, 1) 0%, rgba(3, 25, 54, 1) 74%, rgba(3, 20, 42, 1) 100%);
  }

  .web {
    width: 100%;
    height: 100%;
  }

  .cont .drip {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background: rgb(24, 70, 28);
    position: absolute;
    top: -100%;
    animation: falling 5s linear infinite;
  }

  @keyframes falling {
    0% {
      top: -100%;
    }

    50% {
      top: 0%;
    }

    80% {
      top: 80%;
    }

    100% {
      top: 100%;
    }
  }

  .cont .drip:nth-child(1) {
    border-color: black;
    height: 9px;
    width: 9px;
    animation-delay: -0.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 241px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(2) {
    border-color: black;
    height: 9px;
    width: 9px;
    animation-delay: -0.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 175px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(3) {
    border-color: black;
    height: 36px;
    width: 36px;
    animation-delay: -0.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 190px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(4) {
    border-color: black;
    height: 24px;
    width: 24px;
    animation-delay: -0.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 86px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(5) {
    border-color: black;
    height: 5px;
    width: 5px;
    animation-delay: -1s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 197px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(6) {
    border-color: black;
    height: 23px;
    width: 23px;
    animation-delay: -1.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 244px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(7) {
    border-color: black;
    height: 11px;
    width: 11px;
    animation-delay: -1.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 120px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(8) {
    border-color: black;
    height: 10px;
    width: 10px;
    animation-delay: -1.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 120px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(9) {
    border-color: black;
    height: 27px;
    width: 27px;
    animation-delay: -1.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 29px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(10) {
    border-color: black;
    height: 33px;
    width: 33px;
    animation-delay: -2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 154px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(11) {
    border-color: black;
    height: 39px;
    width: 39px;
    animation-delay: -2.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 109px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(12) {
    border-color: black;
    height: 23px;
    width: 23px;
    animation-delay: -2.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 272px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(13) {
    border-color: black;
    height: 13px;
    width: 13px;
    animation-delay: -2.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 41%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(14) {
    border-color: black;
    height: 21px;
    width: 21px;
    animation-delay: -2.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 71%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(15) {
    border-color: black;
    height: 1px;
    width: 1px;
    animation-delay: -3s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 95%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(16) {
    border-color: black;
    height: 12px;
    width: 12px;
    animation-delay: -3.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 76%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(17) {
    border-color: black;
    height: 12px;
    width: 12px;
    animation-delay: -3.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 71%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(18) {
    border-color: black;
    height: 38px;
    width: 38px;
    animation-delay: -3.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 46%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(19) {
    border-color: black;
    height: 1px;
    width: 1px;
    animation-delay: -3.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 41%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(20) {
    border-color: black;
    height: 19px;
    width: 19px;
    animation-delay: -4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 33%;
    margin-left: 60px;
  }


  .cont .drip:nth-child(21) {
    border-color: black;
    height: 9px;
    width: 9px;
    animation-delay: -0.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 42%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(22) {
    border-color: black;
    height: 9px;
    width: 9px;
    animation-delay: -0.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 58%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(23) {
    border-color: black;
    height: 36px;
    width: 36px;
    animation-delay: -0.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 49%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(24) {
    border-color: black;
    height: 24px;
    width: 24px;
    animation-delay: -0.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 73%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(25) {
    border-color: black;
    height: 5px;
    width: 5px;
    animation-delay: -1s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 83%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(26) {
    border-color: black;
    height: 23px;
    width: 23px;
    animation-delay: -1.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 28%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(27) {
    border-color: black;
    height: 11px;
    width: 11px;
    animation-delay: -1.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 14%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(28) {
    border-color: black;
    height: 10px;
    width: 10px;
    animation-delay: -1.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 43%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(29) {
    border-color: black;
    height: 27px;
    width: 27px;
    animation-delay: -1.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 68%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(30) {
    border-color: black;
    height: 33px;
    width: 33px;
    animation-delay: -2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 57%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(31) {
    border-color: black;
    height: 39px;
    width: 39px;
    animation-delay: -2.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 27%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(32) {
    border-color: black;
    height: 23px;
    width: 23px;
    animation-delay: -2.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 12%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(33) {
    border-color: black;
    height: 13px;
    width: 13px;
    animation-delay: -2.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 30%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(34) {
    border-color: black;
    height: 21px;
    width: 21px;
    animation-delay: -2.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 38%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(35) {
    border-color: black;
    height: 1px;
    width: 1px;
    animation-delay: -3s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 55%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(36) {
    border-color: black;
    height: 12px;
    width: 12px;
    animation-delay: -3.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 50%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(37) {
    border-color: black;
    height: 12px;
    width: 12px;
    animation-delay: -3.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 58%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(38) {
    border-color: black;
    height: 38px;
    width: 38px;
    animation-delay: -3.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 67%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(39) {
    border-color: black;
    height: 1px;
    width: 1px;
    animation-delay: -3.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 70%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(40) {
    border-color: black;
    height: 19px;
    width: 19px;
    animation-delay: -4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 90%;
    margin-left: 60px;
  }

  .donutAndInfo {
    display: flex;
    flex-flow: row nowrap;
  }

  .donut {
    width: 45px;
    height: 45px;
    margin-right: 2%;
  }

  .rmCon {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    width: 90%;
  }

  .phrases p {
    font-size: 18px;
    letter-spacing: 2px;

  }

  .shoe {
    width: 400px;
    height: 400px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .phrases {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: row nowrap;
    width: fit-content;
    margin-top: 3%;
  }

  .rmCon1 {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .teamCon {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    display: flex;
    flex-flow: column nowrap;

  }

  .teamCon img {
    width: 300px;
    height: 300px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    border: 3px solid white;
  }

  .memberDetails {
    color: white;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .memberName {
    font-size: 23px;
    text-align: center;
  }

  .memberCon {
    font-size: 20px;
    text-align: center;
  }


  * {
    box-sizing: border-box;
  }

  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 4rem;
    background-color: #aa5620;
    padding-left: 100%;
  }

  .ticker-wrap .ticker {
    display: inline-block;
    height: 4rem;
    line-height: 4rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 30s;
    animation-duration: 50s;
  }

  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 18px;
    color: #ffffff;
    font-weight: 800;
  }

  .shark {
    width: 400px;
    height: 572px;
    z-index: 1;
    margin-right: -3%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .teamMain {
    display: flex;
  }


  .memName {
    font-family: 'Poppins', sans-serif;
    color: #ffc000;
    font-size: 20px;
    text-align: center;
    padding-right: 2%;
    font-weight: 500;
  }

  .memNamePosition {
    color: #99bad6;
    font-size: 16px;
    text-align: center;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .tPic {
    width: 30px;
    height: 30px;
    cursor: pointer;
  }


  .man {
    width: 200px;
    height: 200px;
    border-radius: 100%;
    transition: transform .5s;
  }

  .man:hover {
    transform: scale(1.1);
    border: 2px solid #5496ec;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px #90bbf3;
  }

  .teamSection {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 70%;
    margin-top: 40px;

  }

  .teamSection2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    margin-top: 40px;
  }


  .tPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .subT {
    font-family: 'Poppins', sans-serif;
    color: #f9f305;
    font-size: 50px;
    text-align: left;
  }

  .manDiv {
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 15px;
  }

  .memName2 {
    color: rgb(255, 255, 255);
    text-align: center;
    font-weight: 500;
    text-shadow: 0 0 10px rgb(255, 255, 255);
  }

  .rmDetails p {
    color: rgb(209, 209, 209);
    padding-left: 20px;
    font-size: 45px;
  }

  .flowers {
    width: 100%;
    margin-top: -10%;
  }

  .rmPicAndCon {
    display: flex;
    justify-content: flex-start;
    margin-left: 20px;
  }

  .footPrint2 {
    width: 30px;
    height: 30px;
  }


  /* Background */
  .mainFoot {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    margin-top: -10%;
  }

  /* Footprint */
  .footprint {
    position: absolute;
    top: 20%;
    left: 0;
    animation: walk 5s steps(10, end) infinite, walkopacity 2s ease-out infinite;
    text-align: center;
  }

  /* Image Size */
  .foot {
    max-width: 20%;
  }

  /* Right Footprint */
  .rightFoot {
    margin: 5rem;
    animation-delay: 250ms;
  }

  /* Walking Animation */
  @keyframes walk {
    to {
      transform: translateX(100vw);
    }
  }

  /* Opacity During Walking */
  @keyframes walkopacity {

    0%,
    100% {
      opacity: 0;
    }

    50% {
      opacity: 0.4;
    }
  }

  .honeyComb {
    width: 450px;
    height: 345px;
    position: sticky;
    position: -webkit-sticky;
    left: 80%;
    bottom: 80px;
  }

  .dripCover {
    width: 100%;
  }

  .ball {
    width: 400px;
    height: 400px;
    animation: floating 5s ease-in-out infinite;
  }

  .ball2 {
    width: 400px;
    height: 400px;
    animation: floating2 5.25s ease-in-out infinite;
  }

  .ball3 {
    width: 400px;
    height: 400px;
    animation: floating3 5.5s ease-in-out infinite;
  }

  .ball4 {
    width: 130px;
    height: 130px;
    animation: floating4 2.1s ease-in-out infinite;
    margin-top: -200px;
    margin-left: 250px;
  }

  .ball5 {
    width: 130px;
    height: 130px;
    animation: floating4 2.3s ease-in-out infinite;
    margin-top: -400px;
    margin-left: 200px;
  }

  .ball6 {
    width: 130px;
    height: 130px;
    animation: floating4 2.8s ease-in-out infinite;
    margin-top: -1100px;
    margin-right: 600px;
  }

  .ball7 {
    width: 130px;
    height: 130px;
    animation: floating4 3s ease-in-out infinite;
    margin-top: -1100px;
    margin-left: 1200px;
  }

  /* Define the keyframes for the floating animation */
  @keyframes floating {
    0% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-10px);
    }

    100% {
      transform: translateY(0);
    }
  }

  @keyframes floating2 {
    0% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-10px);
    }

    100% {
      transform: translateY(0);
    }
  }

  @keyframes floating3 {
    0% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-10px);
    }

    100% {
      transform: translateY(0);
    }
  }

  @keyframes floating4 {
    0% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-10px);
    }

    100% {
      transform: translateY(0);
    }
  }

  .title {
    text-align: center;
    font-weight: bolder;
    font-size: 180px;
    font-family: 'Poppins', sans-serif;
    color: #d1d1e7;
    text-transform: uppercase;
    text-shadow: 0 0 10px #ffffff75;
    z-index: 1000;

  }

  .title2 {
    text-align: center;
    font-weight: bolder;
    font-size: 70px;
    font-family: 'Poppins', sans-serif;
    color: #d1d1e7;
    text-transform: uppercase;
    margin-top: -60px;
    text-shadow: 0 0 10px #ffffff75;
    z-index: 1000;

  }

  .title3 {
    font-size: 18px;
    color: rgb(202, 201, 201);
    width: 50%;
    justify-content: center;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    z-index: 1000;

  }

  .balls {
    display: flex;
    justify-content: center;
    margin-top: 90px;
  }

  .visionBox {
    color: white;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    width: 500px;
    padding: 20px;
    border-radius: 20px;
  }

  .visionBox:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }

  .missionBox {
    color: #a5f8f1;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    width: 500px;
    padding: 20px;
    border-radius: 20px;
  }

  .missionBox:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }

  .visionT {
    color: #a5f8f1;
    font-size: 30px;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    text-align: center;
  }

  .utilCon {
    color: white;
    font-size: 16px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    width: 80%;
  }

  .utilMainCon {
    width: 50%;
  }

  .utilMainCon img {
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }

  .tokenCon {
    display: flex;
    justify-content: space-between;
    flex-flow: row nowrap;
  }

  .platformMain {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
  }

  .tokenCon p {
    color: white;
    font-weight: 500;
  }

  th {
    font-weight: bold;
    font-size: 20px;
    color: #e3bcfa;
  }

  td {
    color: white;
    font-size: 16px;
  }

  table {
    width: 30%;
  }

  .chart {
    width: 50%;
    justify-content: flex-end;
  }

  .taxMain2 {
    display: flex;
    margin-top: 50px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  #customers {
    font-family: 'Poppins', sans-serif;
    border-collapse: collapse;
    width: 100%;
  }

  #customers td,
  #customers th {
    border: 1px solid #ddd;
    padding: 8px;
    font-size: 16px;
  }

  #customers tr:nth-child(even) {
    background-color: #f2f2f225;
  }

  #customers th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #ffc000;
    color: rgb(0, 0, 0);
  }

  #customers2 {
    font-family: 'Poppins', sans-serif;
    border-collapse: collapse;
    width: 70%;
    margin-right: 60px;
  }

  #customers2 td,
  #customers2 th {
    border: 1px solid #ddd;
    padding: 8px;
    font-size: 16px;
  }

  #customers2 tr:nth-child(even) {
    background-color: #b7d5f725;
  }

  #customers2 th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #8297f7;
    color: rgb(0, 0, 0);
  }


  #customers3 {
    font-family: 'Poppins', sans-serif;
    border-collapse: collapse;
    width: 100%;
    margin-right: 60px;
  }

  #customers3 td,
  #customers3 th {
    border: 1px solid #ddd;
    padding: 8px;
    font-size: 16px;
  }

  #customers3 tr:nth-child(even) {
    background-color: #b7d5f725;
  }

  #customers3 th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #8297f7;
    color: rgb(0, 0, 0);
  }

  .platformMain2 {
    display: flex;
    justify-content: space-evenly;
    margin-top: 50px;
  }

  .platformMain3 {
    justify-content: center;
    margin-top: 50px;
  }

  .pT2 {
    font-family: 'Poppins', sans-serif;
    color: #ffc000;
    font-size: 20px;
  }

  .tableCon2 p {
    color: rgb(145, 196, 245);
  }

  .tableCon2 {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .tableCon3 {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .alien {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);

    transform-origin: center;
    transform: perspective(800px) rotateY(25deg);
    transition: 0.5s;
  }

  .alien:hover {
    transform: perspective(800px) rotateY(0deg);
    opacity: 1;
  }

  .tableCon2Main {
    margin-top: auto;
    margin-bottom: auto;
  }

  .winnerNote {
    color: rgb(145, 196, 245);
    text-align: center;
    margin-top: 10px;
  }

  .nftT {
    font-weight: bold;
    color: white;
    font-size: 17px;
  }

  .nftT2 {
    color: white;
    font-size: 16px;

  }

  .nftCon {
    margin-bottom: 20px
  }

  #customers4 {
    font-family: 'Poppins', sans-serif;
    border-collapse: collapse;
    width: 100%;
  }

  #customers4 td,
  #customers4 th {
    border: 1px solid #ddd;
    padding: 8px;
    font-size: 16px;
  }

  #customers4 tr:nth-child(even) {
    background-color: #7ec5ff25;
  }

  #customers4 th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #ffc000;
    color: rgb(0, 0, 0);
  }

  .alien {
    width: 300px;
    height: 300px;
    border-radius: 20px;
  }

  .nftInfoT {
    color: #ffc000;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    font-size: 22px;
  }

  .nftInfo {
    color: rgb(162, 208, 252);
  }

  .note {
    width: 30px;
    height: 30px;
  }

  .com {
    color: #767698;
    font-size: 14px;
  }

  a {
    color: #767698;
    font-size: 14px;
    text-decoration: none;
  }

  a:hover {
    color: #a2a2c4;
    font-size: 14px;
    text-decoration: underline;
  }

  .comInfo {
    display: flex;
    justify-content: space-between;
    margin-top: 60px;
  }

  .lineF {
    width: 100%;
    height: 1px;
    background-color: rgba(75, 63, 78, 0.3);
    margin-bottom: 20px;
  }

  .lineF2 {
    width: 100%;
    height: 1px;
    background-color: rgba(75, 63, 78, 0.3);
    margin-top: 20px;
  }

  .cs {
    color: #5c6c70;
    margin-top: -20px;
    margin-bottom: 60px;
    font-size: 15px;
  }

}

@media screen and (min-width: 1920px) {
  #fontSize2{
    display: none;
  }
 
  .lotteryMain2-2 {
    justify-content: center;
   display: flex;
   flex-flow: column nowrap;
  /* From https://css.glass */
  background: url('./assets/Container.png') no-repeat center center fixed; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  padding: 60px;
   border: 2px solid rgb(160, 160, 255);
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(120, 120, 226);
  padding-top: 10px;
  margin-bottom: 120px;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
  }

  .lotteryMain4{
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  /*.............. FAQ ..............*/
  .totalTickets{
    color: white;
    font-size: 35px;
    margin-bottom: 80px;
  }
  .pomPrice{
    color: #4ac7fc;
    text-align: center;
    margin-top: 25px;
    font-size: 22px;
   }
/* CSS */
.button-29 {
  align-items: center;
  appearance: none;
  background-image: radial-gradient(100% 100% at 100% 0, #fff2b7 0, #ffd000 100%);
  border: 0;
  border-radius: 6px;
  box-shadow: rgba(45, 35, 66, .4) 0 2px 4px,rgba(45, 35, 66, .3) 0 7px 13px -3px,rgba(58, 65, 111, .5) 0 -3px 0 inset;
  box-sizing: border-box;
  color: rgb(0, 0, 0);
  cursor: pointer;
  display: inline-flex;
  font-family: "JetBrains Mono",monospace;
  height: 48px;
  justify-content: center;
  line-height: 1;
  list-style: none;
  overflow: hidden;
  padding-left: 16px;
  padding-right: 16px;
  position: relative;
  text-align: left;
  text-decoration: none;
  transition: box-shadow .15s,transform .15s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  will-change: box-shadow,transform;
  font-size: 22px;
  width: 270px;
}

.button-29:focus {
  box-shadow: #dab200 0 0 0 1.5px inset, rgba(45, 35, 66, .4) 0 2px 4px, rgba(45, 35, 66, .3) 0 7px 13px -3px, #f3cc1d 0 -3px 0 inset;
}

.button-29:hover {
  box-shadow: rgba(45, 35, 66, .4) 0 4px 8px, rgba(45, 35, 66, .3) 0 7px 13px -3px, #9c8001 0 -3px 0 inset;
  transform: translateY(-2px);
}

.ticketsSeparately{
  display: flex;
  flex-flow: column nowrap;
}


summary {
  font-size: 27px;
  background: #af3d330c;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  color: rgb(0, 0, 0);
  padding: 1rem;
  margin-bottom: 1rem;
  outline: none;
  text-align: left;
  cursor: pointer;
  color: white;
  border: 2px solid rgb(197, 197, 197);
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(255, 255, 255, 0.61);
}

details>summary::-webkit-details-marker {
  display: none;
}

details[open] summary~* {
  animation: sweep .5s ease-in-out;
}

@keyframes sweep {
  0% {
    opacity: 0;
    margin-top: -10px
  }

  100% {
    opacity: 1;
    margin-top: 0px
  }
}

.accordin {
  width: 70%;
  color: white;
  display: inline-block;
  flex-direction: column;
  margin-bottom: 85px;
}

.faq__content {

  text-shadow: 0 0 10px #FFFFFF;
  padding-bottom: 20px;
  text-align: left;
  font-size: 17px;
}

.faqTitle {
  margin-bottom: 20px;
  text-align: center;
  font-size: 70px;
  color: white;
  font-family: 'Poppins', sans-serif;
}

.faqbg {
  width: 100%;
  text-align: left;
  padding-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  width: 100%;
  padding-bottom: 10%;

}

.lotterySubH{
  justify-content: center;
  display: flex;
  font-size: 60px;
  font-family: 'Poppins', sans-serif;
  text-align: left;
  color: white;
  font-weight: bold;
  margin-top: -20px;
    border: 2px solid rgb(160, 160, 255);
   box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(120, 120, 226);
   width: 600px;
   margin-left: auto;
   margin-right: auto;
   border-bottom: 0px;
}

.connect div:hover {
  color: #ffc000;
}

.boxWrap2Lottery {
  display: flex;
  flex-flow: column nowrap;
  padding-bottom: 5%;
  padding-top: 5%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  width: 100%;
  background: rgb(0, 29, 56);
  background: linear-gradient(10deg, rgb(0, 37, 54) 0%, rgb(18, 17, 1) 34%, rgba(1, 6, 18, 1) 100%);
  padding-left: 5%;
  padding-right: 5%;
  
}

.lottery{
  width: 400px;
  height: 445px;
}

.ticketCategory{
  color: white;
  margin-left: auto;
  margin-right: auto;
}

.lotteryMain {
  margin-top: 30px;
   display: flex;
  flex-flow: column nowrap;
  margin-bottom: 5%;

}

.lotteryMain2 {
   justify-content: space-evenly;
  display: flex;
  flex-flow: row nowrap;
 /* From https://css.glass */
 background: url('./assets/Container.png') no-repeat center center fixed; 
 -webkit-background-size: cover;
 -moz-background-size: cover;
 -o-background-size: cover;
 background-size: cover;
 padding: 60px;
  border: 2px solid rgb(160, 160, 255);
 box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(120, 120, 226);
 padding-top: 10px;
 margin-bottom: 120px;
}

.lotteryMain3 {
  justify-content: space-evenly;
  flex-flow: column nowrap;
/* From https://css.glass */
background: url('./assets/Container.png') no-repeat center center fixed; 
-webkit-background-size: cover;
-moz-background-size: cover;
-o-background-size: cover;
background-size: cover;
padding: 60px;
 border: 2px solid rgb(160, 160, 255);
box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(120, 120, 226);
padding-top: 10px;
margin-bottom: 120px;
}

.drawBtn{
  background-color: white;
  color: black;
  border: none;
  padding: 15px;
  font-weight: 500;  
  font-size: 25px;
}

.autoPickedMain{
  background: url('./assets/Container.png') no-repeat center center fixed; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  padding: 60px;
   border: 2px solid rgb(160, 160, 255);
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(120, 120, 226);
   margin-bottom: 120px;
}

.autoPickedMain p{
  color: white;
  text-align: center;
}

.kave-btn {
  position: relative;
  border: solid 1px rgba(194, 191, 193, 0.3);
  background: none;
  color: white;
  letter-spacing: 1.8px;
  width: 300px;
  height: 50px;
  padding: 0;
  transition: all 0.25s ease;
  outline: none;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 60px;
}

.kave-btn:before {
  content: "";
  position: absolute;
  top: 0;
  left: 10%;
  width: 60px;
  height: 1px;
  transform: translateY(-1px);
  background: #93edfd;
  transition: all 0.25s ease;
}

.kave-btn:after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 10%;
  width: 60px;
  height: 1px;
  transform: translateY(1px);
  background: #00d6fc;
  transition: all 0.25s ease;
}

.kave-btn:hover {
  box-shadow: 1px 1px 8px rgba(0, 214, 252, 0.3);
  color: #00d6fc;
  text-shadow: 0 0 8px rgba(0, 214, 252, 0.4);
}

.kave-btn:hover.kave-btn:before {
  left: 0;
  width: 20px;
}

.kave-btn:hover.kave-btn:after {
  right: 0;
  width: 20px;
}

.kave-btn:hover .kave-line:before {
  bottom: 0;
}

.kave-btn:hover .kave-line:after {
  top: 0;
}

.kave-line:before {
  content: "";
  position: absolute;
  bottom: 30%;
  right: 0;
  width: 1px;
  height: 20px;
  transform: translateX(1px);
  background: #00d6fc;
  transition: all 0.25s ease;
}

.kave-line:after {
  content: "";
  position: absolute;
  top: 30%;
  left: 0;
  width: 1px;
  height: 20px;
  transform: translateX(-1px);
  background: #00d6fc;
  transition: all 0.25s ease;
}

  .aboutMob {
    display: none;
  }
  /* CSS */
  .button-92 {
    --c: #fff;
    /* text color */
    background: linear-gradient(90deg, #0000 33%, #fff5, #0000 67%) var(--_p,100%)/300% no-repeat,
      #004dff;
    /* background color */
    color: #0000;
    border: none;
    transform: perspective(500px) rotateY(calc(20deg * var(--_i,-1)));
    text-shadow: calc(var(--_i,-1) * 0.08em) -.01em 0   var(--c),
      calc(var(--_i,-1) * -0.08em)  .01em 2px #0004;
    outline-offset: .1em;
    transition: 0.3s;
  }

.button-92:hover,
.button-92:focus-visible {
  --_p: 0%;
  --_i: 1;
}

.button-92:active {
  text-shadow: none;
  color: var(--c);
  box-shadow: inset 0 0 9e9q #0005;
  transition: 0s;
}

.button-92 {
  font-weight: bold;
  font-size: 2.5rem;
  margin: 0;
  cursor: pointer;
  padding: .1em .3em;
}
  
  .boxWrap2Mint {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: rgb(0, 41, 79);
    background: radial-gradient(circle, rgba(0, 41, 79, 1) 0%, rgba(0, 10, 25, 1) 74%, rgba(0, 10, 25, 1) 100%);
    padding-left: 5%;
    padding-right: 5%;
  }
  .special:hover {
    background-color: rgb(228, 194, 0);

  }

  .taxMain2Mob {
    display: none;
  }

  .teamSectionMob {
    display: none;
  }

  .teamSection2Mob {
    display: none;
  }

  .special:active {
    background-color: rgb(228, 194, 0);
    box-shadow: -0px 7px rgb(153, 131, 4);
    transform: translateY(4px);
  }

  .special2:hover {
    background-color: rgb(228, 194, 0);

  }

  .special2:active {
    background-color: rgb(228, 194, 0);
    box-shadow: -0px 7px rgb(153, 131, 4);
    transform: translateY(4px);
  }

  .placeAndWL {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 50px;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    background: rgba(82, 82, 82, 0.2);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 30px;
  }

  .special {
    font-size: 20px;
    margin-left: 30px;
    border-radius: 10px;
    background-color: gold;
    border: none;
    padding: 10px;
    box-shadow: -0px 5px rgb(194, 165, 5);
  }

  .special2 {
    font-size: 20px;
    border-radius: 10px;
    background-color: gold;
    border: none;
    padding: 10px;
    box-shadow: -0px 5px rgb(194, 165, 5);
  }

  .mintingAmount {
    border: black 2px solid;
    border-radius: 10px;
    box-shadow: -0px 5px black;
    padding: 10px;
  }

  h1 {
    text-align: center;
    margin-bottom: 200px;
    margin-top: 100px;
    font-size: 50px;
    font-weight: 600;
    color: #0b72d3;
  }

  .timeline-container {
    display: flex;
    justify-content: center;
    margin-top: 80px;
  }

  .timeline-container .vertical-scrollable-timeline {
    width: 700px;
    list-style-type: none;
  }

  .timeline-container .vertical-scrollable-timeline li {
    position: relative;
    padding: 35px 0px 125px 158px;
  }

  .timeline-container .vertical-scrollable-timeline li:last-child {
    margin-bottom: 0;
  }

  .timeline-container .vertical-scrollable-timeline li h2 {
    font-size: 30px;
    margin-bottom: 10px;
    color: #ffc000;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
  }

  .timeline-container .vertical-scrollable-timeline li p {
    line-height: 1.5;
    color: rgb(198, 221, 255);
    font-size: 22px;
  }

  .timeline-container .vertical-scrollable-timeline li p:last-child {
    margin-bottom: 0;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder {
    position: absolute;
    left: 0;
    top: 0;
    width: 104px;
    height: 104px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #c5c3cb;
    border-radius: 50%;
    z-index: 1;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder::before {
    content: "";
    width: 80px;
    height: 80px;
    border: 4px solid #fff;
    position: absolute;
    background-color: #c5c3cb;
    border-radius: 50%;
    z-index: -1;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder i {
    font-size: 25px;
    color: #fff;
  }

  .timeline-container .vertical-scrollable-timeline li::after {
    content: "";
    position: absolute;
    height: 0;
    width: 8px;
    background-color: #3f96e7;
    left: 48px;
    top: 34px;
    z-index: 0;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 8px;
    background-color: #c5c3cb;
    left: 48px;
    z-index: 0;
  }

  .timeline-container .vertical-scrollable-timeline li:last-child::before {
    content: unset;
  }

  .timeline-container .vertical-scrollable-timeline li.active .icon-holder {
    background-color: #ffc000;
  }

  .timeline-container .vertical-scrollable-timeline li.active .icon-holder::before {
    background-color: #4372c3;
  }

  .timeline-container .vertical-scrollable-timeline li.active::after {
    height: 100%;
  }

  .timeline-container .vertical-scrollable-timeline li.active:last-child::after {
    content: unset;
  }


  figure {
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
  }

  figure:nth-child(1) .rmDetails {
    transform: scale(0.6) rotate(-12.5deg);
  }

  figure:nth-child(2) .rmDetails {
    transform: scale(0.6) rotate(-9.7222222222deg);
  }

  figure:nth-child(3) .rmDetails {
    transform: scale(0.6) rotate(-6.9444444444deg);
  }

  figure:nth-child(4) .rmDetails {
    transform: scale(0.6) rotate(-4.1666666667deg);
  }

  figure:nth-child(5) .rmDetails {
    transform: scale(0.6) rotate(-1.3888888889deg);
  }

  figure:nth-child(6) .rmDetails {
    transform: scale(0.6) rotate(1.3888888889deg);
  }

  figure:nth-child(7) .rmDetails {
    transform: scale(0.6) rotate(4.1666666667deg);
  }

  figure:nth-child(8) .rmDetails {
    transform: scale(0.6) rotate(6.9444444444deg);
  }

  figure:nth-child(9) .rmDetails {
    transform: scale(0.6) rotate(9.7222222222deg);
  }

  figure:nth-child(10) .rmDetails {
    transform: scale(0.6) rotate(12.5deg);
  }

  figure .rmDetails {
    width: 100%;
    border-radius: 3px;
    background-color: black;
    padding: 50px;
    border: 5px solid gray;
  }

  .connect2Mobile {
    display: none;
  }

  .discordNone {
    transition: transform .2s;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: no-drop;
  }

  .discordNone:hover {
    transform: scale(1.1);
  }

  .linkedin {
    width: 30px;
    height: 30px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 10px;
    cursor: pointer;

  }

  .team1 {
    width: 50%;
  }

  .discord3 {
    display: none;
  }

  .discord4 {
    display: none;
  }

  .storyHRmM {
    display: none;
  }

  .iconsMob {
    display: none;
  }

  .mainBanner {
    display: none;
  }

  .icons {
    display: flex;
    flex-flow: row nowrap;
  }

  .cover52 {
    display: none;
  }

  .coverRm {
    width: 100vw;
  }

  .discordF2 {
    display: none;
  }

  .connectMobile {
    display: none;
  }

  .rightMobile {
    display: none;
  }

  .osPic {
    width: 30px;
    height: 30px;
    cursor: pointer;
    z-index: 10000;
  }

  .osPic2 {
    width: 40px;
    height: 40px;
    cursor: pointer;
  }

  .price {
    text-align: center;
    font-size: 25px;
    color: #aef6ff;
  }

  .discord2 {
    display: none;
  }

  .discord3 {
    padding-right: 20px;
    display: none;
  }


  .uRMobile {
    display: none;
  }

  .storyPicDivMobile {
    display: none;
  }

  .allWrap {

    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    contain: content;
  }

  .boxWrap2 {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background-color: black;
  }

  .boxWrap2Story {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: rgb(0, 88, 81);
    background: radial-gradient(circle, rgba(0, 88, 81, 1) 0%, rgba(0, 42, 47, 1) 34%, rgb(3, 14, 41) 100%);
    padding-left: 5%;
    padding-right: 5%;
  }

  .boxWrap2Utility {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: rgb(0, 45, 56);
    background: linear-gradient(0deg, rgba(0, 45, 56, 1) 0%, rgba(1, 6, 18, 1) 34%, rgba(1, 6, 18, 1) 100%);
    padding-left: 5%;
    padding-right: 5%;
  }

  .boxWrap2Team {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: rgb(0, 41, 79);
    background: radial-gradient(circle, rgba(0, 41, 79, 1) 0%, rgba(0, 10, 25, 1) 74%, rgba(0, 10, 25, 1) 100%);
    padding-left: 5%;
    padding-right: 5%;
  }

  .boxWrap2Platform {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: rgb(21, 38, 7);
    background: linear-gradient(309deg, rgba(21, 38, 7, 1) 0%, rgba(0, 10, 25, 1) 74%, rgba(0, 10, 25, 1) 100%);
    padding-left: 5%;
    padding-right: 5%;
  }

  .boxWrap2Tokenomic {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: rgb(0, 5, 27);
    background: linear-gradient(97deg, rgba(0, 5, 27, 1) 0%, rgba(43, 22, 56, 1) 74%, rgba(0, 5, 27, 1) 100%);
    padding-left: 5%;
    padding-right: 5%;
  }

  .sharks {
    width: 100%;
  }

  .boxWrapC {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    position: relative;

    padding-bottom: 5%;
  }

  .green {
    background-color: #97ba5e;

  }

  .about {
    margin-top: 30px;
    justify-content: space-evenly;
    display: flex;
    flex-flow: row nowrap;
  }

  .aboutVAndM {
    margin-top: 30px;
    justify-content: space-evenly;
    display: flex;
    flex-flow: row nowrap;
  }

  .about2 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 90%;
  }

  .aboutImg {
    width: 50%;
    border: white 6px solid;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-bottom: 3%;
  }

  .aboutImg2 {
    width: 28%;
    height: 28%;
    border: white 6px solid;
    transition: transform .5s;
  }

  .aboutImg2:hover {
    transform: scale(1.2);
  }

  .utilityPics {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
  }

  .roadmapMain1 {
    top: 0;
    position: relative;
  }

  .storyPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .UtilDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .storyH {
    justify-content: left;
    display: flex;
    font-size: 26px;
    font-family: "Gordita";
    color: #00fae5;
    text-align: left;
  }

  .utilH {
    justify-content: left;
    display: flex;
    font-size: 26px;
    font-family: "Gordita";
    color: #00c8fa;
    text-align: left;
  }

  .TokenomicH {
    justify-content: left;
    display: flex;
    font-size: 26px;
    font-family: "Gordita";
    color: #c86ffc;
    text-align: left;
  }

  .platformH {
    justify-content: left;
    display: flex;
    font-size: 26px;
    font-family: "Gordita";
    color: #a2f55e;
    text-align: left;
  }

  .nftH {
    justify-content: left;
    display: flex;
    font-size: 26px;
    font-family: "Gordita";
    color: #0278ee;
    text-align: left;
  }

  .teamH {
    justify-content: left;
    display: flex;
    font-size: 26px;
    font-family: "Gordita";
    color: #ffc959;
    text-align: left;
  }

  .storyH2 {
    justify-content: left;
    display: flex;
    font-size: 70px;
    font-family: 'Poppins', sans-serif;
    text-align: left;
    color: white;
    font-weight: bold;
    margin-top: -20px;
  }

  .taxT {
    justify-content: center;
    display: flex;
    font-size: 60px;
    font-family: 'Poppins', sans-serif;
    text-align: left;
    color: white;
    font-weight: bold;
    margin-top: -20px;
  }

  .taxMain {
    margin-top: 150px;
  }

  .mintH {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    font-size: 90px;
    background: -webkit-linear-gradient(#f0d9a3, #ff9d01);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: "Gordita";
    -webkit-text-stroke-width: 3px;
    -webkit-text-stroke-color: black;
  }

  .storyRm img {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .tokenH {
    font-size: 70px;
    color: white;
    font-family: 'Poppins', sans-serif;
    text-align: center;
    margin-bottom: 2%;
  }


  .carouselH {
    font-size: 70px;
    color: white;
    font-family: 'Poppins', sans-serif;
    text-align: center;
    margin-bottom: 2%;
  }

  .rmH {
    font-size: 80px;
    color: white;
    font-family: 'Poppins', sans-serif;
    text-align: center;
    background-color: #fe8a00;
    padding-left: 20px;
    margin-bottom: 20px;
  }

  .rmH2 {
    color: grey;
    font-size: 18px;
    line-height: 30px;
  }

  .rmName {
    background: rgb(81, 190, 229);
    background: linear-gradient(0deg, rgba(81, 190, 229, 1) 2%, rgba(81, 190, 229, 1) 20%, rgba(6, 136, 198, 1) 63%, rgba(6, 136, 198, 1) 100%);
  }



  .storyCon {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 70%;
    margin-left: auto;
    margin-right: auto;

  }

  .storyCon-2 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    padding: 20px;
    border-radius: 50px;
    box-shadow: -10px 10px rgba(14, 21, 36, 0.363);
    background-color: rgba(30, 46, 80, 0.568);

  }

  .storyCon2 {
    font-size: 30px;
    font-family: 'Poppins', sans-serif;
    text-align: center;
  }

  .storyConP {
    font-size: 20px;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
  }

  .storyPic {
    width: 540px;
    height: 339px;
  }

  .storyRight {
    width: 45%;
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
  }

  .uR {
    width: 50%;
  }

  .intro {
    color: white;
    font-size: 100px;
    text-align: center;
    font-family: 'Riangriung', sans-serif;
  }

  .logoF {
    color: white;
    text-align: center;
    font-size: 50px;
    background: -webkit-linear-gradient(#f0d9a3, #ff9d01);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: "Gordita";
  }

  .logoF img {
    width: 50%;
  }

  .intro img {
    width: 500px;
    height: 500px;
  }

  .intro3 {
    font-style: italic;
    font-size: 20px;
    font-weight: 500;
  }

  .intro2 {
    color: rgb(0, 0, 0);
    font-size: 23px;
    text-align: left;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    font-weight: bold;
    width: 50%;
  }

  .mintDiv {
    padding-top: 3%;
    padding-bottom: 3%;
    width: 800px;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.493) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
    margin-bottom: 80px;
    border: 2px solid rgb(119, 222, 248);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(106, 184, 243);
    margin-top: 50px;
  }

  .errorMessage {
    margin-left: 3%;
    margin-top: 1%;
    font-size: 27px;
    color: #fc4741;
    text-align: center;
    text-shadow: -1px -1px 1px rgba(255, 255, 255, .1), 1px 1px 1px rgba(0, 0, 0, .5);
  }

  .loadingContainer {
    margin-top: 1%;
  }

  .loadingText {
    font-size: 25px;
    text-align: center;
    color: rgb(120, 184, 189);
    font-weight: bold;
  }

  .loadTextSub {
    text-align: center;
    color: rgb(157, 194, 199);
    font-size: 16px;
    padding-top: 5px;
  }
  /* loading dots */


  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;

  }

  .wPMobile {
    display: none;
  }

  .introductionMobile {
    display: none
  }

  .hl {
    color: #6ce34d;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
  }

  .right2 {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 1.5%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .discord {
    transition: transform .2s;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .discord:hover {
    transform: scale(1.1);
  }


  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(99, 31, 177);
  }



  .note {
    color: rgb(255, 255, 255);
  }

  .twitterSoc {
    margin-top: 10px;
    margin-right: 5px;
    /* z-index: -2;*/
    margin-left: 5px;

  }

  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/

  }

  /*.website{
    margin-top: 10px;
    margin-left: 10px;
   /* z-index: -2;*/
  /*color: #ffffffa2;
  }*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;

  }





  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    padding-left: 50px;
    padding-right: 50px;

    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);


  }

  .wallet:hover {
    background-color: white;
    color: rgb(73, 81, 190);
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-family: "Gordita";
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    font-family: "Gordita";
    align-items: center;
  }

  .connect div {
    margin-left: 10px;
    margin-right: 10px;
    font-family: "Gordita";
    color: #ffffff;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease;
    font-weight: 500;
  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }




  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding-bottom: 50px;
    margin-left: auto;
    margin-right: auto;

  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-bottom: 20px;

  }

  .center {
    margin-top: auto;
    margin-bottom: auto;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }

  .btnfos-0-2 {
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    height: 70px;
    border: none;
    font-family: 'Poppins', sans-serif;
    cursor: pointer;
    background-color: #3a9ae802;
    border: white 2px solid;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    height: 70px;
    border: none;
    font-family: 'Poppins', sans-serif;
    cursor: pointer;
    background-color: #3a9ae802;
    border: white 2px solid;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }


  .btnfos-0-2:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(134, 134, 134, 0.288) -5px 5px;
  }


  .btnfos-0-2-2:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(134, 134, 134, 0.288) 5px 5px;
  }


  .btnfos-0-3 {
    margin-top: 20px;
    background-color: #6cfc19;
    color: #000000;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 28px;
    border-radius: 50px;
    padding-left: 30px;
    padding-right: 30px;
    transition: transform .2s;
    border: none;
    font-family: 'Poppins', sans-serif;
    transition: transform .2s;
  }

  .btnfos-0-3:hover {
    transform: scale(1.1);
  }

  .nftamount {
    color: cyan;
    font-size: 85px;
    font-family: 'Poppins', sans-serif;
   }

  .walletConnect {

    display: flex;
    justify-content: center;
  }

  .connectButton {

    font-size: 45px;
  }

  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;

  }

  .loadingContainer {
    text-align: center;
    padding-top: 20px;
  }

  .errorMessage {
    text-align: center;
    padding-top: 20px;
  }

  .successfully {
    text-align: center;
    padding-top: 20px;
    color: #1ee03e;
    font-family: 'Poppins', sans-serif;
    font-size: 35px;
    text-shadow: -1px -1px 1px rgba(255, 255, 255, .1), 1px 1px 1px rgba(0, 0, 0, .5);
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #000000;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
    border-bottom: 1px solid rgba(59, 45, 87, 0.863);
    padding-top: 35px;
    padding-bottom: 35px;
  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
    margin-top: auto;
    margin-bottom: auto;
  }

  .h2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    background-color: #5ebaad;

    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/

  }

  .introduction {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    z-index: 1000;
    background: rgba(3, 24, 107, 0.733);
    background: radial-gradient(circle, rgba(3, 24, 107, 0.747) 0%, rgba(3, 25, 54, 0.705) 74%, rgba(3, 20, 42, 0.781) 100%);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    padding-bottom: 50px;
  }

  .wallet2 {
    z-index: 1000;
    background-color: #000000;
    color: #ffffff;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 20px;
    border-radius: 50px;
    width: 270px;
    transition: transform .2s;
    border: none;
    font-family: "Gordita";
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    transition: 0.5s ease;
    cursor: pointer;
    box-shadow: 0 3px #363636;
    letter-spacing: 2px;
  }

  .wallet2:hover {
    color: #000000;
    background-color: #ffffff;
  }

  .wallet2Btn {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    width: 400px;
    border: 2px solid #ffffff;
    margin-left: 10px;
    margin-right: 10px;
    border: 2px solid #2E8B57;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
  }

  .wallet2Btn:hover {
    color: rgb(156, 156, 156);
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }


  .wallet3 {
    background-image: linear-gradient(to right top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1);
    color: rgb(0, 0, 0);
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 30px;
    font-weight: bold;
    border-radius: 15px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 50px;
    padding-right: 50px;
    box-shadow: black 0px 5px;
  }

  .wallet3:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }

  @keyframes shake {

    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }

  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }

  .in2 {
    width: 100%;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .totalSupply {
    text-align: center;
    font-size: 80px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    font-family: 'Poppins', sans-serif;
    text-shadow: 0 0 10px #ffffff93;
    letter-spacing: 1px;
    margin-top: 1%;
    font-weight: bold;
  }

  @keyframes Slide_Up {
    0% {
      transform: translateY(250px);
      opacity: 1;
    }

    10%,
    87.5% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Tag {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 1;
    }

    20%,
    92.5% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Buttons {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 0;
    }

    20% {
      opacity: 1;
    }

    25%,
    95% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  .copyright {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 18px;
    text-shadow: 0 0 10px #FFFFFF;
  }

  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }

  @keyframes shake {

    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }

  @keyframes Slide_Left {
    0% {
      transform: translateX(250px);
    }

    100% {
      transform: translateX(0);
    }
  }

  @keyframes Slide_Left_Right {

    0%,
    100% {
      transform: translateX(100px);
    }

    12.5%,
    75% {
      transform: translateX(0);
    }

    25% {
      transform: translateX(20px);
    }

  }

  @keyframes Opacity {

    0%,
    100% {
      opacity: 0;
    }

    25%,
    75% {
      opacity: 1;
    }


  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }

  @keyframes scaleIn {
    from {
      transform: scale(.5, .5);
      opacity: .5;
    }

    to {
      transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }

  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1, 1);
    }

    50% {
      transform: scale(1.05, 1.05);
    }

    100% {
      transform: scale(1, 1);
    }
  }

  .logo {
    width: 150px;
    height: 150px;
    display: none;

  }

  .logo2 {
    width: 20%;
    cursor: pointer;
  }

  #fontSize {
    font-size: 25px;
    font-family: "Gordita";
    cursor: pointer;
    z-index: 10000;
    font-weight: 500;
  }

  .parrotDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;

  }

  .pic1 {
    width: 32%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    left: 70%;
    bottom: 0;

  }

  .pic2 {
    width: 32%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    left: -5%;
    bottom: 0;

  }

  
  .cover {
    width: 100vw;
  }

  .cover2 {
    width: 100%;
  }

  .parrotPic {
    width: 100vw;
  }

  .parrot {
    width: 60%;
    border-radius: 30px;
  }

  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .rmMain {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: -100px;
  }

  .rmBox {
    text-align: right;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }

  .rmBox2 {
    text-align: right;
    width: 80%;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }

  .rmBox {
    font-weight: 700;
    color: rgb(58, 58, 58);
    margin-bottom: 60%;
  }

  .year {
    font-size: 18px;
    font-family: 'Poppins', sans-serif;
    color: rgb(8, 81, 94);
    letter-spacing: 1px;
  }


  .rmBoxMain2 {
    margin-top: 30%;
    width: 33.33%;
  }

  .rmBoxMain {
    width: max-content;
    justify-content: flex-start;
    width: 33.33%;

  }

  .rmBox2 {
    text-align: left;
    width: 30%;
  }

  .rmParrot {
    width: 130px;
    height: 186px;
    z-index: 1;
    justify-content: center;
    position: -webkit-sticky;
    position: sticky;
    top: 10%;
    margin-bottom: 25%;
    margin-right: auto;
    display: block;
    margin-left: 33%;
    margin-top: -50%;
  }

  .line {
    margin-left: auto;
    margin-right: auto;
    display: block;
    height: 270vh;
    margin-top: -30%;
  }

  .rmP {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 33.33%;
    height: fit-content;
  }

  .box {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: -15%;
  }

  .discordF {
    padding-left: 10px;
    padding-right: 10px;
  }

  .socialAndLogo {
    flex-flow: row nowrap;
    justify-content: space-between;
    display: flex;
    padding-left: 10%;
    padding-right: 10%;
    margin-bottom: 10%;
    padding-top: 10%;
  }

  .chartAndContent {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }

  .tCon {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    color: white;
    font-weight: 500;
  }

  .tCon p {
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 5px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 13px;
  }

  .chart {
    width: 60%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .boxWrapToken {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-left: 5%;
    padding-right: 5%;
    background: rgb(74, 175, 139);
    background: linear-gradient(0deg, rgba(74, 175, 139, 1) 0%, rgba(88, 176, 135, 1) 18%, rgba(74, 175, 128, 1) 100%);
  }

  .tH {
    color: #3b3b3b;
    font-weight: bold;
  }

  .cont {
    height: 100vh;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background: rgb(3, 23, 107);
    background: radial-gradient(circle, rgba(3, 23, 107, 1) 0%, rgba(3, 25, 54, 1) 74%, rgba(3, 20, 42, 1) 100%);
  }

  .web {
    width: 100%;
    height: 100%;
  }

  .cont .drip {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background: rgb(24, 70, 28);
    position: absolute;
    top: -100%;
    animation: falling 5s linear infinite;
  }

  @keyframes falling {
    0% {
      top: -100%;
    }

    50% {
      top: 0%;
    }

    80% {
      top: 80%;
    }

    100% {
      top: 100%;
    }
  }

  .cont .drip:nth-child(1) {
    border-color: black;
    height: 9px;
    width: 9px;
    animation-delay: -0.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 241px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(2) {
    border-color: black;
    height: 9px;
    width: 9px;
    animation-delay: -0.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 175px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(3) {
    border-color: black;
    height: 36px;
    width: 36px;
    animation-delay: -0.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 190px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(4) {
    border-color: black;
    height: 24px;
    width: 24px;
    animation-delay: -0.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 86px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(5) {
    border-color: black;
    height: 5px;
    width: 5px;
    animation-delay: -1s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 197px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(6) {
    border-color: black;
    height: 23px;
    width: 23px;
    animation-delay: -1.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 244px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(7) {
    border-color: black;
    height: 11px;
    width: 11px;
    animation-delay: -1.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 120px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(8) {
    border-color: black;
    height: 10px;
    width: 10px;
    animation-delay: -1.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 120px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(9) {
    border-color: black;
    height: 27px;
    width: 27px;
    animation-delay: -1.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 29px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(10) {
    border-color: black;
    height: 33px;
    width: 33px;
    animation-delay: -2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 154px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(11) {
    border-color: black;
    height: 39px;
    width: 39px;
    animation-delay: -2.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 109px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(12) {
    border-color: black;
    height: 23px;
    width: 23px;
    animation-delay: -2.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 272px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(13) {
    border-color: black;
    height: 13px;
    width: 13px;
    animation-delay: -2.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 41%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(14) {
    border-color: black;
    height: 21px;
    width: 21px;
    animation-delay: -2.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 71%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(15) {
    border-color: black;
    height: 1px;
    width: 1px;
    animation-delay: -3s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 95%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(16) {
    border-color: black;
    height: 12px;
    width: 12px;
    animation-delay: -3.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 76%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(17) {
    border-color: black;
    height: 12px;
    width: 12px;
    animation-delay: -3.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 71%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(18) {
    border-color: black;
    height: 38px;
    width: 38px;
    animation-delay: -3.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 46%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(19) {
    border-color: black;
    height: 1px;
    width: 1px;
    animation-delay: -3.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 41%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(20) {
    border-color: black;
    height: 19px;
    width: 19px;
    animation-delay: -4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 33%;
    margin-left: 60px;
  }


  .cont .drip:nth-child(21) {
    border-color: black;
    height: 9px;
    width: 9px;
    animation-delay: -0.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 42%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(22) {
    border-color: black;
    height: 9px;
    width: 9px;
    animation-delay: -0.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 58%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(23) {
    border-color: black;
    height: 36px;
    width: 36px;
    animation-delay: -0.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 49%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(24) {
    border-color: black;
    height: 24px;
    width: 24px;
    animation-delay: -0.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 73%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(25) {
    border-color: black;
    height: 5px;
    width: 5px;
    animation-delay: -1s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 83%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(26) {
    border-color: black;
    height: 23px;
    width: 23px;
    animation-delay: -1.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 28%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(27) {
    border-color: black;
    height: 11px;
    width: 11px;
    animation-delay: -1.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 14%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(28) {
    border-color: black;
    height: 10px;
    width: 10px;
    animation-delay: -1.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 43%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(29) {
    border-color: black;
    height: 27px;
    width: 27px;
    animation-delay: -1.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 68%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(30) {
    border-color: black;
    height: 33px;
    width: 33px;
    animation-delay: -2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 57%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(31) {
    border-color: black;
    height: 39px;
    width: 39px;
    animation-delay: -2.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 27%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(32) {
    border-color: black;
    height: 23px;
    width: 23px;
    animation-delay: -2.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 12%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(33) {
    border-color: black;
    height: 13px;
    width: 13px;
    animation-delay: -2.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 30%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(34) {
    border-color: black;
    height: 21px;
    width: 21px;
    animation-delay: -2.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 38%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(35) {
    border-color: black;
    height: 1px;
    width: 1px;
    animation-delay: -3s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 55%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(36) {
    border-color: black;
    height: 12px;
    width: 12px;
    animation-delay: -3.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 50%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(37) {
    border-color: black;
    height: 12px;
    width: 12px;
    animation-delay: -3.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 58%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(38) {
    border-color: black;
    height: 38px;
    width: 38px;
    animation-delay: -3.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 67%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(39) {
    border-color: black;
    height: 1px;
    width: 1px;
    animation-delay: -3.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 70%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(40) {
    border-color: black;
    height: 19px;
    width: 19px;
    animation-delay: -4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 90%;
    margin-left: 60px;
  }

  .donutAndInfo {
    display: flex;
    flex-flow: row nowrap;
  }

  .donut {
    width: 45px;
    height: 45px;
    margin-right: 2%;
  }

  .rmCon {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    width: 90%;
  }

  .phrases p {
    font-size: 18px;
    letter-spacing: 2px;

  }

  .shoe {
    width: 400px;
    height: 400px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .phrases {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: row nowrap;
    width: fit-content;
    margin-top: 3%;
  }

  .rmCon1 {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .teamCon {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    display: flex;
    flex-flow: column nowrap;

  }

  .teamCon img {
    width: 300px;
    height: 300px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    border: 3px solid white;
  }

  .memberDetails {
    color: white;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .memberName {
    font-size: 23px;
    text-align: center;
  }

  .memberCon {
    font-size: 20px;
    text-align: center;
  }


  * {
    box-sizing: border-box;
  }

  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 4rem;
    background-color: #aa5620;
    padding-left: 100%;
  }

  .ticker-wrap .ticker {
    display: inline-block;
    height: 4rem;
    line-height: 4rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 30s;
    animation-duration: 50s;
  }

  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 18px;
    color: #ffffff;
    font-weight: 800;
  }

  .shark {
    width: 400px;
    height: 572px;
    z-index: 1;
    margin-right: -3%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .teamMain {
    display: flex;
  }


  .memName {
    font-family: 'Poppins', sans-serif;
    color: #ffc000;
    font-size: 28px;
    text-align: center;
    padding-right: 2%;
    font-weight: 500;
  }

  .memNamePosition {
    color: #99bad6;
    font-size: 22px;
    text-align: center;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .tPic {
    width: 30px;
    height: 30px;
    cursor: pointer;
  }


  .man {
    width: 290px;
    height: 290px;
    border-radius: 100%;
    transition: transform .5s;
  }

  .man:hover {
    transform: scale(1.1);
    border: 2px solid #5496ec;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px #90bbf3;
  }

  .teamSection {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 70%;
    margin-top: 40px;

  }

  .teamSection2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    margin-top: 40px;
  }


  .tPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .subT {
    font-family: 'Poppins', sans-serif;
    color: #f9f305;
    font-size: 50px;
    text-align: left;
  }

  .manDiv {
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 15px;
  }

  .memName2 {
    color: rgb(255, 255, 255);
    text-align: center;
    font-weight: 500;
    text-shadow: 0 0 10px rgb(255, 255, 255);
  }

  .rmDetails p {
    color: rgb(209, 209, 209);
    padding-left: 20px;
    font-size: 45px;
  }

  .flowers {
    width: 100%;
    margin-top: -10%;
  }

  .rmPicAndCon {
    display: flex;
    justify-content: flex-start;
    margin-left: 20px;
  }

  .footPrint2 {
    width: 30px;
    height: 30px;
  }


  /* Background */
  .mainFoot {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    margin-top: -10%;
  }

  /* Footprint */
  .footprint {
    position: absolute;
    top: 20%;
    left: 0;
    animation: walk 5s steps(10, end) infinite, walkopacity 2s ease-out infinite;
    text-align: center;
  }

  /* Image Size */
  .foot {
    max-width: 20%;
  }

  /* Right Footprint */
  .rightFoot {
    margin: 5rem;
    animation-delay: 250ms;
  }

  /* Walking Animation */
  @keyframes walk {
    to {
      transform: translateX(100vw);
    }
  }

  /* Opacity During Walking */
  @keyframes walkopacity {

    0%,
    100% {
      opacity: 0;
    }

    50% {
      opacity: 0.4;
    }
  }

  .honeyComb {
    width: 450px;
    height: 345px;
    position: sticky;
    position: -webkit-sticky;
    left: 80%;
    bottom: 80px;
  }

  .dripCover {
    width: 100%;
  }

  .ball {
    width: 550px;
    height: 550px;
    animation: floating 5s ease-in-out infinite;
  }

  .ball2 {
    width: 550px;
    height: 550px;
    animation: floating2 5.25s ease-in-out infinite;
  }

  .ball3 {
    width: 550px;
    height: 550px;
    animation: floating3 5.5s ease-in-out infinite;
  }

  .ball4 {
    width: 130px;
    height: 130px;
    animation: floating4 2.1s ease-in-out infinite;
    margin-top: -200px;
    margin-left: 250px;
  }

  .ball5 {
    width: 130px;
    height: 130px;
    animation: floating4 2.3s ease-in-out infinite;
    margin-top: -400px;
    margin-left: 200px;
  }

  .ball6 {
    width: 130px;
    height: 130px;
    animation: floating4 2.8s ease-in-out infinite;
    margin-top: -1100px;
    margin-right: 600px;
  }

  .ball7 {
    width: 130px;
    height: 130px;
    animation: floating4 3s ease-in-out infinite;
    margin-top: -1100px;
    margin-left: 1200px;
  }

  /* Define the keyframes for the floating animation */
  @keyframes floating {
    0% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-10px);
    }

    100% {
      transform: translateY(0);
    }
  }

  @keyframes floating2 {
    0% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-10px);
    }

    100% {
      transform: translateY(0);
    }
  }

  @keyframes floating3 {
    0% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-10px);
    }

    100% {
      transform: translateY(0);
    }
  }

  @keyframes floating4 {
    0% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-10px);
    }

    100% {
      transform: translateY(0);
    }
  }

  .title {
    text-align: center;
    font-weight: bolder;
    font-size: 300px;
    font-family: 'Poppins', sans-serif;
    color: #d1d1e7;
    text-transform: uppercase;
    text-shadow: 0 0 10px #ffffff75;
    z-index: 1000;

  }

  .title2 {
    text-align: center;
    font-weight: bolder;
    font-size: 110px;
    font-family: 'Poppins', sans-serif;
    color: #d1d1e7;
    text-transform: uppercase;
    margin-top: -60px;
    text-shadow: 0 0 10px #ffffff75;
    z-index: 1000;

  }

  .title3 {
    font-size: 22px;
    color: rgb(202, 201, 201);
    width: 50%;
    justify-content: center;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    z-index: 1000;

  }

  .balls {
    display: flex;
    justify-content: center;
    margin-top: 90px;
  }

  .visionBox {
    color: white;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    width: 700px;
    padding: 20px;
    border-radius: 20px;
    font-size: 24px;
  }

  .visionBox:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }

  .missionBox {
    color: #a5f8f1;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    width: 700px;
    padding: 20px;
    border-radius: 20px;
    font-size: 24px;
  }

  .missionBox:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }

  .visionT {
    color: #a5f8f1;
    font-size: 45px;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    text-align: center;
  }

  .utilCon {
    color: white;
    font-size: 23px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    width: 80%;
  }

  .utilMainCon {
    width: 50%;
  }

  .utilMainCon img {
    width: 85px;
    height: 85px;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }

  .tokenCon {
    display: flex;
    justify-content: space-between;
    flex-flow: row nowrap;
  }

  .platformMain {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
  }

  .tokenCon p {
    color: white;
    font-weight: 500;
  }

  th {
    font-weight: bold;
    font-size: 28px;
    color: #e3bcfa;
  }

  td {
    color: white;
    font-size: 23px;
  }

  table {
    width: 30%;
  }

  .chart {
    width: 50%;
    justify-content: flex-end;
  }

  .taxMain2 {
    display: flex;
    margin-top: 50px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  #customers {
    font-family: 'Poppins', sans-serif;
    border-collapse: collapse;
    width: 100%;
  }

  #customers td,
  #customers th {
    border: 1px solid #ddd;
    padding: 8px;
    font-size: 22px;
  }

  #customers tr:nth-child(even) {
    background-color: #f2f2f225;
  }

  #customers th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #ffc000;
    color: rgb(0, 0, 0);
  }

  #customers2 {
    font-family: 'Poppins', sans-serif;
    border-collapse: collapse;
    width: 70%;
    margin-right: 60px;
  }

  #customers2 td,
  #customers2 th {
    border: 1px solid #ddd;
    padding: 8px;
    font-size: 18px;
    font-size: 22px;

  }

  #customers2 tr:nth-child(even) {
    background-color: #b7d5f725;
  }

  #customers2 th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #8297f7;
    color: rgb(0, 0, 0);
  }


  #customers3 {
    font-family: 'Poppins', sans-serif;
    border-collapse: collapse;
    width: 100%;
    margin-right: 60px;
  }

  #customers3 td,
  #customers3 th {
    border: 1px solid #ddd;
    padding: 8px;
    font-size: 22px;
  }

  #customers3 tr:nth-child(even) {
    background-color: #b7d5f725;
  }

  #customers3 th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #8297f7;
    color: rgb(0, 0, 0);
  }

  .platformMain2 {
    display: flex;
    justify-content: space-evenly;
    margin-top: 50px;
  }

  .platformMain3 {
    justify-content: center;
    margin-top: 50px;
  }

  .pT2 {
    font-family: 'Poppins', sans-serif;
    color: #ffc000;
    font-size: 28px;
  }

  .tableCon2 p {
    color: rgb(145, 196, 245);
    font-size: 23px;
  }

  .tableCon2 {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .tableCon3 {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .alien {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);

    transform-origin: center;
    transform: perspective(800px) rotateY(25deg);
    transition: 0.5s;
  }

  .alien:hover {
    transform: perspective(800px) rotateY(0deg);
    opacity: 1;
  }

  .tableCon2Main {
    margin-top: auto;
    margin-bottom: auto;
  }

  .winnerNote {
    color: rgb(145, 196, 245);
    text-align: center;
    margin-top: 10px;
    font-size: 22px;
  }

  .nftT {
    font-weight: bold;
    color: white;
    font-size: 25px;
  }

  .nftT2 {
    color: white;
    font-size: 21px;

  }

  .nftCon {
    margin-bottom: 20px
  }

  #customers4 {
    font-family: 'Poppins', sans-serif;
    border-collapse: collapse;
    width: 100%;
  }

  #customers4 td,
  #customers4 th {
    border: 1px solid #ddd;
    padding: 8px;
    font-size: 22px;
  }

  #customers4 tr:nth-child(even) {
    background-color: #7ec5ff25;
  }

  #customers4 th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #ffc000;
    color: rgb(0, 0, 0);
  }

  .alien {
    width: 450px;
    height: 450px;
    border-radius: 20px;
  }

  .nftInfoT {
    color: #ffc000;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    font-size: 30px;
  }

  .nftInfo {
    color: rgb(162, 208, 252);
    font-size: 25px;
  }

  .note {
    width: 40px;
    height: 40px;
  }

  .com {
    color: #767698;
    font-size: 18px;
  }

  a {
    color: #767698;
    font-size: 18px;
    text-decoration: none;
  }

  a:hover {
    color: #a2a2c4;
    font-size: 18px;
    text-decoration: underline;
  }

  .comInfo {
    display: flex;
    justify-content: space-between;
    margin-top: 60px;
  }

  .lineF {
    width: 100%;
    height: 1px;
    background-color: rgba(75, 63, 78, 0.3);
    margin-bottom: 20px;
  }

  .lineF2 {
    width: 100%;
    height: 1px;
    background-color: rgba(75, 63, 78, 0.3);
    margin-top: 20px;
  }

  .cs {
    color: #5c6c70;
    margin-top: -20px;
    margin-bottom: 60px;
    font-size: 20px;
  }

}

@media screen and (min-width: 1921px) and (max-width: 3839px) {
  #fontSize2{
    display: none;
  }
  
  .lotteryMain2-2 {
    justify-content: center;
   display: flex;
   flex-flow: column nowrap;
  /* From https://css.glass */
  background: url('./assets/Container.png') no-repeat center center fixed; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  padding: 60px;
   border: 2px solid rgb(160, 160, 255);
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(120, 120, 226);
  padding-top: 10px;
  margin-bottom: 120px;
  margin-left: auto;
  margin-right: auto;
  }

  .lotteryMain4{
    display: flex;
  }
  
  /*.............. FAQ ..............*/
   .totalTickets{
    color: white;
    font-size: 45px;
    margin-bottom: 80px;
  }
   .pomPrice{
    color: #4ac7fc;
    text-align: center;
    margin-top: 25px;
    font-size: 30px;
   }
/* CSS */
.button-29 {
  align-items: center;
  appearance: none;
  background-image: radial-gradient(100% 100% at 100% 0, #fff2b7 0, #ffd000 100%);
  border: 0;
  border-radius: 6px;
  box-shadow: rgba(45, 35, 66, .4) 0 2px 4px,rgba(45, 35, 66, .3) 0 7px 13px -3px,rgba(58, 65, 111, .5) 0 -3px 0 inset;
  box-sizing: border-box;
  color: rgb(0, 0, 0);
  cursor: pointer;
  display: inline-flex;
  font-family: "JetBrains Mono",monospace;
  height: 60px;
  justify-content: center;
  line-height: 1;
  list-style: none;
  overflow: hidden;
  padding-left: 16px;
  padding-right: 16px;
  position: relative;
  text-align: left;
  text-decoration: none;
  transition: box-shadow .15s,transform .15s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  will-change: box-shadow,transform;
  font-size: 35px;
  width: 450px;
}

.button-29:focus {
  box-shadow: #dab200 0 0 0 1.5px inset, rgba(45, 35, 66, .4) 0 2px 4px, rgba(45, 35, 66, .3) 0 7px 13px -3px, #f3cc1d 0 -3px 0 inset;
}

.button-29:hover {
  box-shadow: rgba(45, 35, 66, .4) 0 4px 8px, rgba(45, 35, 66, .3) 0 7px 13px -3px, #9c8001 0 -3px 0 inset;
  transform: translateY(-2px);
}

.ticketsSeparately{
  display: flex;
  flex-flow: column nowrap;
}


summary {
  font-size: 40px;
  background: #af3d330c;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  color: rgb(0, 0, 0);
  padding: 1.5rem;
  margin-bottom: 1rem;
  outline: none;
  text-align: left;
  cursor: pointer;
  color: white;
  border: 2px solid rgb(197, 197, 197);
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(255, 255, 255, 0.61);
}

details>summary::-webkit-details-marker {
  display: none;
}

details[open] summary~* {
  animation: sweep .5s ease-in-out;
}

@keyframes sweep {
  0% {
    opacity: 0;
    margin-top: -10px
  }

  100% {
    opacity: 1;
    margin-top: 0px
  }
}

.accordin {
  width: 70%;
  color: white;
  display: inline-block;
  flex-direction: column;
  margin-bottom: 85px;
}

.faq__content {

  text-shadow: 0 0 10px #FFFFFF;
  padding-bottom: 20px;
  text-align: left;
  font-size: 17px;
}

.faqTitle {
  margin-bottom: 20px;
  text-align: center;
  font-size: 70px;
  color: white;
  font-family: 'Poppins', sans-serif;
}

.faqbg {
  width: 100%;
  text-align: left;
  padding-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  width: 100%;
  padding-bottom: 10%;

}

.lotterySubH{
  justify-content: center;
  display: flex;
  font-size: 80px;
  font-family: 'Poppins', sans-serif;
  text-align: left;
  color: white;
  font-weight: bold;
  margin-top: -20px;
    border: 2px solid rgb(160, 160, 255);
   box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(120, 120, 226);
   width: 800px;
   margin-left: auto;
   margin-right: auto;
   border-bottom: 0px;
}

.connect div:hover {
  color: #ffc000;
}

.boxWrap2Lottery {
  display: flex;
  flex-flow: column nowrap;
  padding-bottom: 5%;
  padding-top: 5%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  width: 100%;
  background: rgb(0, 29, 56);
  background: linear-gradient(10deg, rgb(0, 37, 54) 0%, rgb(18, 17, 1) 34%, rgba(1, 6, 18, 1) 100%);
  padding-left: 5%;
  padding-right: 5%;
  
}

.lottery{
  width: 550px;
  height: 611px;
}

.ticketCategory{
  color: white;
  margin-left: auto;
  margin-right: auto;
}

.lotteryMain {
  margin-top: 30px;
   display: flex;
  flex-flow: column nowrap;
  margin-bottom: 5%;

}

.lotteryMain2 {
   justify-content: space-evenly;
  display: flex;
  flex-flow: row nowrap;
 /* From https://css.glass */
 background: url('./assets/Container.png') no-repeat center center fixed; 
 -webkit-background-size: cover;
 -moz-background-size: cover;
 -o-background-size: cover;
 background-size: cover;
 padding: 60px;
  border: 2px solid rgb(160, 160, 255);
 box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(120, 120, 226);
 padding-top: 10px;
 margin-bottom: 120px;
}

.lotteryMain3 {
  justify-content: space-evenly;
  flex-flow: column nowrap;
/* From https://css.glass */
background: url('./assets/Container.png') no-repeat center center fixed; 
-webkit-background-size: cover;
-moz-background-size: cover;
-o-background-size: cover;
background-size: cover;
padding: 60px;
 border: 2px solid rgb(160, 160, 255);
box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(120, 120, 226);
padding-top: 10px;
margin-bottom: 120px;
}

.drawBtn{
  background-color: white;
  color: black;
  border: none;
  padding: 15px;
  font-weight: 500;  
  font-size: 35px;
}

.autoPickedMain{
  background: url('./assets/Container.png') no-repeat center center fixed; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  padding: 60px;
   border: 2px solid rgb(160, 160, 255);
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(120, 120, 226);
   margin-bottom: 120px;
}

.autoPickedMain p{
  color: white;
  text-align: center;
}

.kave-btn {
  position: relative;
  border: solid 1px rgba(194, 191, 193, 0.3);
  background: none;
  color: white;
  letter-spacing: 1.8px;
  width: 500px;
  height: 90px;
  padding: 0;
  transition: all 0.25s ease;
  outline: none;
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 60px;
}

.kave-btn:before {
  content: "";
  position: absolute;
  top: 0;
  left: 10%;
  width: 60px;
  height: 1px;
  transform: translateY(-1px);
  background: #93edfd;
  transition: all 0.25s ease;
}

.kave-btn:after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 10%;
  width: 60px;
  height: 1px;
  transform: translateY(1px);
  background: #00d6fc;
  transition: all 0.25s ease;
}

.kave-btn:hover {
  box-shadow: 1px 1px 8px rgba(0, 214, 252, 0.3);
  color: #00d6fc;
  text-shadow: 0 0 8px rgba(0, 214, 252, 0.4);
}

.kave-btn:hover.kave-btn:before {
  left: 0;
  width: 20px;
}

.kave-btn:hover.kave-btn:after {
  right: 0;
  width: 20px;
}

.kave-btn:hover .kave-line:before {
  bottom: 0;
}

.kave-btn:hover .kave-line:after {
  top: 0;
}

.kave-line:before {
  content: "";
  position: absolute;
  bottom: 30%;
  right: 0;
  width: 1px;
  height: 20px;
  transform: translateX(1px);
  background: #00d6fc;
  transition: all 0.25s ease;
}

.kave-line:after {
  content: "";
  position: absolute;
  top: 30%;
  left: 0;
  width: 1px;
  height: 20px;
  transform: translateX(-1px);
  background: #00d6fc;
  transition: all 0.25s ease;
}

  .aboutMob {
    display: none;
  }
  /* CSS */
  .button-92 {
    --c: #fff;
    /* text color */
    background: linear-gradient(90deg, #0000 33%, #fff5, #0000 67%) var(--_p,100%)/300% no-repeat,
      #004dff;
    /* background color */
    color: #0000;
    border: none;
    transform: perspective(500px) rotateY(calc(20deg * var(--_i,-1)));
    text-shadow: calc(var(--_i,-1) * 0.08em) -.01em 0   var(--c),
      calc(var(--_i,-1) * -0.08em)  .01em 2px #0004;
    outline-offset: .1em;
    transition: 0.3s;
  }

.button-92:hover,
.button-92:focus-visible {
  --_p: 0%;
  --_i: 1;
}

.button-92:active {
  text-shadow: none;
  color: var(--c);
  box-shadow: inset 0 0 9e9q #0005;
  transition: 0s;
}

.button-92 {
  font-weight: bold;
  font-size: 3rem;
  margin: 0;
  cursor: pointer;
  padding: .1em .3em;
}
  
  .boxWrap2Mint {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: rgb(0, 41, 79);
    background: radial-gradient(circle, rgba(0, 41, 79, 1) 0%, rgba(0, 10, 25, 1) 74%, rgba(0, 10, 25, 1) 100%);
    padding-left: 5%;
    padding-right: 5%;
  }
  .special:hover {
    background-color: rgb(228, 194, 0);

  }

  .taxMain2Mob {
    display: none;
  }

  .teamSectionMob {
    display: none;
  }

  .teamSection2Mob {
    display: none;
  }

  .special:active {
    background-color: rgb(228, 194, 0);
    box-shadow: -0px 7px rgb(153, 131, 4);
    transform: translateY(4px);
  }

  .special2:hover {
    background-color: rgb(228, 194, 0);

  }

  .special2:active {
    background-color: rgb(228, 194, 0);
    box-shadow: -0px 7px rgb(153, 131, 4);
    transform: translateY(4px);
  }

  .placeAndWL {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 50px;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    background: rgba(82, 82, 82, 0.2);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 30px;
  }

  .special {
    font-size: 20px;
    margin-left: 30px;
    border-radius: 10px;
    background-color: gold;
    border: none;
    padding: 10px;
    box-shadow: -0px 5px rgb(194, 165, 5);
  }

  .special2 {
    font-size: 20px;
    border-radius: 10px;
    background-color: gold;
    border: none;
    padding: 10px;
    box-shadow: -0px 5px rgb(194, 165, 5);
  }

  .mintingAmount {
    border: black 2px solid;
    border-radius: 10px;
    box-shadow: -0px 5px black;
    padding: 10px;
  }

  h1 {
    text-align: center;
    margin-bottom: 200px;
    margin-top: 100px;
    font-size: 50px;
    font-weight: 600;
    color: #0b72d3;
  }

  .timeline-container {
    display: flex;
    justify-content: center;
    margin-top: 80px;
  }

  .timeline-container .vertical-scrollable-timeline {
    width: 700px;
    list-style-type: none;
  }

  .timeline-container .vertical-scrollable-timeline li {
    position: relative;
    padding: 35px 0px 125px 158px;
  }

  .timeline-container .vertical-scrollable-timeline li:last-child {
    margin-bottom: 0;
  }

  .timeline-container .vertical-scrollable-timeline li h2 {
    font-size: 40px;
    margin-bottom: 10px;
    color: #ffc000;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
  }

  .timeline-container .vertical-scrollable-timeline li p {
    line-height: 1.5;
    color: rgb(198, 221, 255);
    font-size: 25px;
  }

  .timeline-container .vertical-scrollable-timeline li p:last-child {
    margin-bottom: 0;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder {
    position: absolute;
    left: 0;
    top: 0;
    width: 104px;
    height: 104px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #c5c3cb;
    border-radius: 50%;
    z-index: 1;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder::before {
    content: "";
    width: 80px;
    height: 80px;
    border: 4px solid #fff;
    position: absolute;
    background-color: #c5c3cb;
    border-radius: 50%;
    z-index: -1;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder i {
    font-size: 25px;
    color: #fff;
  }

  .timeline-container .vertical-scrollable-timeline li::after {
    content: "";
    position: absolute;
    height: 0;
    width: 8px;
    background-color: #3f96e7;
    left: 48px;
    top: 34px;
    z-index: 0;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 8px;
    background-color: #c5c3cb;
    left: 48px;
    z-index: 0;
  }

  .timeline-container .vertical-scrollable-timeline li:last-child::before {
    content: unset;
  }

  .timeline-container .vertical-scrollable-timeline li.active .icon-holder {
    background-color: #ffc000;
  }

  .timeline-container .vertical-scrollable-timeline li.active .icon-holder::before {
    background-color: #4372c3;
  }

  .timeline-container .vertical-scrollable-timeline li.active::after {
    height: 100%;
  }

  .timeline-container .vertical-scrollable-timeline li.active:last-child::after {
    content: unset;
  }


  figure {
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
  }

  figure:nth-child(1) .rmDetails {
    transform: scale(0.6) rotate(-12.5deg);
  }

  figure:nth-child(2) .rmDetails {
    transform: scale(0.6) rotate(-9.7222222222deg);
  }

  figure:nth-child(3) .rmDetails {
    transform: scale(0.6) rotate(-6.9444444444deg);
  }

  figure:nth-child(4) .rmDetails {
    transform: scale(0.6) rotate(-4.1666666667deg);
  }

  figure:nth-child(5) .rmDetails {
    transform: scale(0.6) rotate(-1.3888888889deg);
  }

  figure:nth-child(6) .rmDetails {
    transform: scale(0.6) rotate(1.3888888889deg);
  }

  figure:nth-child(7) .rmDetails {
    transform: scale(0.6) rotate(4.1666666667deg);
  }

  figure:nth-child(8) .rmDetails {
    transform: scale(0.6) rotate(6.9444444444deg);
  }

  figure:nth-child(9) .rmDetails {
    transform: scale(0.6) rotate(9.7222222222deg);
  }

  figure:nth-child(10) .rmDetails {
    transform: scale(0.6) rotate(12.5deg);
  }

  figure .rmDetails {
    width: 100%;
    border-radius: 3px;
    background-color: black;
    padding: 50px;
    border: 5px solid gray;
  }

  .connect2Mobile {
    display: none;
  }

  .discordNone {
    transition: transform .2s;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: no-drop;
  }

  .discordNone:hover {
    transform: scale(1.1);
  }

  .linkedin {
    width: 30px;
    height: 30px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 10px;
    cursor: pointer;

  }

  .team1 {
    width: 50%;
  }

  .discord3 {
    display: none;
  }

  .discord4 {
    display: none;
  }

  .storyHRmM {
    display: none;
  }

  .iconsMob {
    display: none;
  }

  .mainBanner {
    display: none;
  }

  .icons {
    display: flex;
    flex-flow: row nowrap;
  }

  .cover52 {
    display: none;
  }

  .coverRm {
    width: 100vw;
  }

  .discordF2 {
    display: none;
  }

  .connectMobile {
    display: none;
  }

  .rightMobile {
    display: none;
  }

  .osPic {
    width: 30px;
    height: 30px;
    cursor: pointer;
    z-index: 10000;
  }

  .osPic2 {
    width: 40px;
    height: 40px;
    cursor: pointer;
  }

  .price {
    text-align: center;
    font-size: 35px;
    color: #aef6ff;
  }

  .discord2 {
    display: none;
  }

  .discord3 {
    padding-right: 20px;
    display: none;
  }


  .uRMobile {
    display: none;
  }

  .storyPicDivMobile {
    display: none;
  }

  .allWrap {

    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    contain: content;
  }

  .boxWrap2 {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background-color: black;
  }

  .boxWrap2Story {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: rgb(0, 88, 81);
    background: radial-gradient(circle, rgba(0, 88, 81, 1) 0%, rgba(0, 42, 47, 1) 34%, rgb(3, 14, 41) 100%);
    padding-left: 5%;
    padding-right: 5%;
  }

  .boxWrap2Utility {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: rgb(0, 45, 56);
    background: linear-gradient(0deg, rgba(0, 45, 56, 1) 0%, rgba(1, 6, 18, 1) 34%, rgba(1, 6, 18, 1) 100%);
    padding-left: 5%;
    padding-right: 5%;
  }

  .boxWrap2Team {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: rgb(0, 41, 79);
    background: radial-gradient(circle, rgba(0, 41, 79, 1) 0%, rgba(0, 10, 25, 1) 74%, rgba(0, 10, 25, 1) 100%);
    padding-left: 5%;
    padding-right: 5%;
  }

  .boxWrap2Platform {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: rgb(21, 38, 7);
    background: linear-gradient(309deg, rgba(21, 38, 7, 1) 0%, rgba(0, 10, 25, 1) 74%, rgba(0, 10, 25, 1) 100%);
    padding-left: 5%;
    padding-right: 5%;
  }

  .boxWrap2Tokenomic {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: rgb(0, 5, 27);
    background: linear-gradient(97deg, rgba(0, 5, 27, 1) 0%, rgba(43, 22, 56, 1) 74%, rgba(0, 5, 27, 1) 100%);
    padding-left: 5%;
    padding-right: 5%;
  }

  .sharks {
    width: 100%;
  }

  .boxWrapC {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    position: relative;

    padding-bottom: 5%;
  }

  .green {
    background-color: #97ba5e;

  }

  .about {
    margin-top: 30px;
    justify-content: space-evenly;
    display: flex;
    flex-flow: row nowrap;
  }

  .aboutVAndM {
    margin-top: 30px;
    justify-content: space-evenly;
    display: flex;
    flex-flow: row nowrap;
  }

  .about2 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 90%;
  }

  .aboutImg {
    width: 50%;
    border: white 6px solid;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-bottom: 3%;
  }

  .aboutImg2 {
    width: 28%;
    height: 28%;
    border: white 6px solid;
    transition: transform .5s;
  }

  .aboutImg2:hover {
    transform: scale(1.2);
  }

  .utilityPics {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
  }

  .roadmapMain1 {
    top: 0;
    position: relative;
  }

  .storyPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .UtilDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .storyH {
    justify-content: left;
    display: flex;
    font-size: 35px;
    font-family: "Gordita";
    color: #00fae5;
    text-align: left;
  }

  .utilH {
    justify-content: left;
    display: flex;
    font-size: 35px;
    font-family: "Gordita";
    color: #00c8fa;
    text-align: left;
  }

  .TokenomicH {
    justify-content: left;
    display: flex;
    font-size: 35px;
    font-family: "Gordita";
    color: #c86ffc;
    text-align: left;
  }

  .platformH {
    justify-content: left;
    display: flex;
    font-size: 35px;
    font-family: "Gordita";
    color: #a2f55e;
    text-align: left;
  }

  .nftH {
    justify-content: left;
    display: flex;
    font-size: 35px;
    font-family: "Gordita";
    color: #0278ee;
    text-align: left;
  }

  .teamH {
    justify-content: left;
    display: flex;
    font-size: 35px;
    font-family: "Gordita";
    color: #ffc959;
    text-align: left;
  }

  .storyH2 {
    justify-content: left;
    display: flex;
    font-size: 90px;
    font-family: 'Poppins', sans-serif;
    text-align: left;
    color: white;
    font-weight: bold;
    margin-top: -20px;
  }

  .taxT {
    justify-content: center;
    display: flex;
    font-size: 80px;
    font-family: 'Poppins', sans-serif;
    text-align: left;
    color: white;
    font-weight: bold;
    margin-top: -20px;
  }

  .taxMain {
    margin-top: 150px;
  }

  .mintH {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    font-size: 90px;
    background: -webkit-linear-gradient(#f0d9a3, #ff9d01);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: "Gordita";
    -webkit-text-stroke-width: 3px;
    -webkit-text-stroke-color: black;
  }

  .storyRm img {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .tokenH {
    font-size: 70px;
    color: white;
    font-family: 'Poppins', sans-serif;
    text-align: center;
    margin-bottom: 2%;
  }


  .carouselH {
    font-size: 70px;
    color: white;
    font-family: 'Poppins', sans-serif;
    text-align: center;
    margin-bottom: 2%;
  }

  .rmH {
    font-size: 80px;
    color: white;
    font-family: 'Poppins', sans-serif;
    text-align: center;
    background-color: #fe8a00;
    padding-left: 20px;
    margin-bottom: 20px;
  }

  .rmH2 {
    color: grey;
    font-size: 18px;
    line-height: 30px;
  }

  .rmName {
    background: rgb(81, 190, 229);
    background: linear-gradient(0deg, rgba(81, 190, 229, 1) 2%, rgba(81, 190, 229, 1) 20%, rgba(6, 136, 198, 1) 63%, rgba(6, 136, 198, 1) 100%);
  }



  .storyCon {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 70%;
    margin-left: auto;
    margin-right: auto;

  }

  .storyCon-2 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    padding: 20px;
    border-radius: 50px;
    box-shadow: -10px 10px rgba(14, 21, 36, 0.363);
    background-color: rgba(30, 46, 80, 0.568);

  }

  .storyCon2 {
    font-size: 30px;
    font-family: 'Poppins', sans-serif;
    text-align: center;
  }

  .storyConP {
    font-size: 20px;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
  }

  .storyPic {
    width: 540px;
    height: 339px;
  }

  .storyRight {
    width: 45%;
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
  }

  .uR {
    width: 50%;
  }

  .intro {
    color: white;
    font-size: 100px;
    text-align: center;
    font-family: 'Riangriung', sans-serif;
  }

  .logoF {
    color: white;
    text-align: center;
    font-size: 50px;
    background: -webkit-linear-gradient(#f0d9a3, #ff9d01);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: "Gordita";
  }

  .logoF img {
    width: 50%;
  }

  .intro img {
    width: 500px;
    height: 500px;
  }

  .intro3 {
    font-style: italic;
    font-size: 20px;
    font-weight: 500;
  }

  .intro2 {
    color: rgb(0, 0, 0);
    font-size: 23px;
    text-align: left;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    font-weight: bold;
    width: 50%;
  }

  .mintDiv {
    padding-top: 3%;
    padding-bottom: 3%;
    width: 1300px;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.493) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
    margin-bottom: 80px;
    border: 2px solid rgb(119, 222, 248);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(106, 184, 243);
    margin-top: 50px;
  }

  .errorMessage {
    margin-left: 3%;
    margin-top: 1%;
    font-size: 40px;
    color: #fc4741;
    text-align: center;
    text-shadow: -1px -1px 1px rgba(255, 255, 255, .1), 1px 1px 1px rgba(0, 0, 0, .5);
  }

  .loadingContainer {
    margin-top: 1%;
  }

  .loadingText {
    font-size: 40px;
    text-align: center;
    color: rgb(120, 184, 189);
    font-weight: bold;
  }

  .loadTextSub {
    text-align: center;
    color: rgb(157, 194, 199);
    font-size: 30px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;

  }

  .wPMobile {
    display: none;
  }

  .introductionMobile {
    display: none
  }

  .hl {
    color: #6ce34d;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
  }

  .right2 {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 1.5%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .discord {
    transition: transform .2s;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .discord:hover {
    transform: scale(1.1);
  }


  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(99, 31, 177);
  }



  .note {
    color: rgb(255, 255, 255);
  }

  .twitterSoc {
    margin-top: 10px;
    margin-right: 5px;
    /* z-index: -2;*/
    margin-left: 5px;

  }

  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/

  }

  /*.website{
    margin-top: 10px;
    margin-left: 10px;
   /* z-index: -2;*/
  /*color: #ffffffa2;
  }*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;

  }





  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    padding-left: 50px;
    padding-right: 50px;

    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);


  }

  .wallet:hover {
    background-color: white;
    color: rgb(73, 81, 190);
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-family: "Gordita";
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    font-family: "Gordita";
    align-items: center;
  }

  .connect div {
    margin-left: 20px;
    margin-right: 20px;
    font-family: "Gordita";
    color: #ffffff;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease;
    font-weight: 500;
  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }




  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding-bottom: 50px;
    margin-left: auto;
    margin-right: auto;

  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-bottom: 20px;

  }

  .center {
    margin-top: auto;
    margin-bottom: auto;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }

  .btnfos-0-2 {
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 90px;
    height: 90px;
    border: none;
    font-family: 'Poppins', sans-serif;
    cursor: pointer;
    background-color: #3a9ae802;
    border: white 2px solid;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 90px;
    height: 90px;
    border: none;
    font-family: 'Poppins', sans-serif;
    cursor: pointer;
    background-color: #3a9ae802;
    border: white 2px solid;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }


  .btnfos-0-2:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(134, 134, 134, 0.288) -5px 5px;
  }


  .btnfos-0-2-2:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(134, 134, 134, 0.288) 5px 5px;
  }


  .btnfos-0-3 {
    margin-top: 20px;
    background-color: #6cfc19;
    color: #000000;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 28px;
    border-radius: 50px;
    padding-left: 30px;
    padding-right: 30px;
    transition: transform .2s;
    border: none;
    font-family: 'Poppins', sans-serif;
    transition: transform .2s;
  }

  .btnfos-0-3:hover {
    transform: scale(1.1);
  }

  .nftamount {
    color: cyan;
    font-size: 130px;
    font-family: 'Poppins', sans-serif;
   }

  .walletConnect {

    display: flex;
    justify-content: center;
  }

  .connectButton {

    font-size: 45px;
  }

  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;

  }

  .loadingContainer {
    text-align: center;
    padding-top: 20px;
  }

  .errorMessage {
    text-align: center;
    padding-top: 20px;
  }

  .successfully {
    text-align: center;
    padding-top: 20px;
    color: #1ee03e;
    font-family: 'Poppins', sans-serif;
    font-size: 35px;
    text-shadow: -1px -1px 1px rgba(255, 255, 255, .1), 1px 1px 1px rgba(0, 0, 0, .5);
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #000000;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
    border-bottom: 1px solid rgba(59, 45, 87, 0.863);
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
    margin-top: auto;
    margin-bottom: auto;
  }

  .h2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    background-color: #5ebaad;

    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/

  }

  .introduction {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    z-index: 1000;
    background: rgba(3, 24, 107, 0.733);
    background: radial-gradient(circle, rgba(3, 24, 107, 0.747) 0%, rgba(3, 25, 54, 0.705) 74%, rgba(3, 20, 42, 0.781) 100%);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    padding-bottom: 50px;
  }

  .wallet2 {
    z-index: 1000;
    background-color: #000000;
    color: #ffffff;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 20px;
    border-radius: 50px;
    width: 270px;
    transition: transform .2s;
    border: none;
    font-family: "Gordita";
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    transition: 0.5s ease;
    cursor: pointer;
    box-shadow: 0 3px #363636;
    letter-spacing: 2px;
  }

  .wallet2:hover {
    color: #000000;
    background-color: #ffffff;
  }

  .wallet2Btn {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    width: 400px;
    border: 2px solid #ffffff;
    margin-left: 10px;
    margin-right: 10px;
    border: 2px solid #2E8B57;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
  }

  .wallet2Btn:hover {
    color: rgb(156, 156, 156);
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }


  .wallet3 {
    background-image: linear-gradient(to right top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1);
    color: rgb(0, 0, 0);
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 30px;
    font-weight: bold;
    border-radius: 15px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 50px;
    padding-right: 50px;
    box-shadow: black 0px 5px;
  }

  .wallet3:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }

  @keyframes shake {

    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }

  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }

  .in2 {
    width: 100%;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .totalSupply {
    text-align: center;
    font-size: 90px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    font-family: 'Poppins', sans-serif;
    text-shadow: 0 0 10px #ffffff93;
    letter-spacing: 1px;
    margin-top: 1%;
    font-weight: bold;
  }

  @keyframes Slide_Up {
    0% {
      transform: translateY(250px);
      opacity: 1;
    }

    10%,
    87.5% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Tag {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 1;
    }

    20%,
    92.5% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Buttons {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 0;
    }

    20% {
      opacity: 1;
    }

    25%,
    95% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  .copyright {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 18px;
    text-shadow: 0 0 10px #FFFFFF;
  }

  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }

  @keyframes shake {

    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }

  @keyframes Slide_Left {
    0% {
      transform: translateX(250px);
    }

    100% {
      transform: translateX(0);
    }
  }

  @keyframes Slide_Left_Right {

    0%,
    100% {
      transform: translateX(100px);
    }

    12.5%,
    75% {
      transform: translateX(0);
    }

    25% {
      transform: translateX(20px);
    }

  }

  @keyframes Opacity {

    0%,
    100% {
      opacity: 0;
    }

    25%,
    75% {
      opacity: 1;
    }


  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }

  @keyframes scaleIn {
    from {
      transform: scale(.5, .5);
      opacity: .5;
    }

    to {
      transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }

  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1, 1);
    }

    50% {
      transform: scale(1.05, 1.05);
    }

    100% {
      transform: scale(1, 1);
    }
  }

  .logo {
    width: 185px;
    height: 185px;
    display: none;

  }

  .logo2 {
    width: 20%;
    cursor: pointer;
  }

  #fontSize {
    font-size: 32px;
    font-family: "Gordita";
    cursor: pointer;
    z-index: 10000;
    font-weight: 500;
  }

  .parrotDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;

  }

  .pic1 {
    width: 32%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    left: 70%;
    bottom: 0;

  }

  .pic2 {
    width: 32%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    left: -5%;
    bottom: 0;

  }

  
  .cover {
    width: 100vw;
  }

  .cover2 {
    width: 100%;
  }

  .parrotPic {
    width: 100vw;
  }

  .parrot {
    width: 50%;
    border-radius: 30px;
  }

  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .rmMain {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: -100px;
  }

  .rmBox {
    text-align: right;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }

  .rmBox2 {
    text-align: right;
    width: 80%;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }

  .rmBox {
    font-weight: 700;
    color: rgb(58, 58, 58);
    margin-bottom: 60%;
  }

  .year {
    font-size: 18px;
    font-family: 'Poppins', sans-serif;
    color: rgb(8, 81, 94);
    letter-spacing: 1px;
  }


  .rmBoxMain2 {
    margin-top: 30%;
    width: 33.33%;
  }

  .rmBoxMain {
    width: max-content;
    justify-content: flex-start;
    width: 33.33%;

  }

  .rmBox2 {
    text-align: left;
    width: 30%;
  }

  .rmParrot {
    width: 130px;
    height: 186px;
    z-index: 1;
    justify-content: center;
    position: -webkit-sticky;
    position: sticky;
    top: 10%;
    margin-bottom: 25%;
    margin-right: auto;
    display: block;
    margin-left: 33%;
    margin-top: -50%;
  }

  .line {
    margin-left: auto;
    margin-right: auto;
    display: block;
    height: 270vh;
    margin-top: -30%;
  }

  .rmP {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 33.33%;
    height: fit-content;
  }

  .box {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: -15%;
  }

  .discordF {
    padding-left: 10px;
    padding-right: 10px;
  }

  .socialAndLogo {
    flex-flow: row nowrap;
    justify-content: space-between;
    display: flex;
    padding-left: 10%;
    padding-right: 10%;
    margin-bottom: 10%;
    padding-top: 10%;
  }

  .chartAndContent {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }

  .tCon {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    color: white;
    font-weight: 500;
  }

  .tCon p {
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 5px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 13px;
  }

  .chart {
    width: 60%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .boxWrapToken {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-left: 5%;
    padding-right: 5%;
    background: rgb(74, 175, 139);
    background: linear-gradient(0deg, rgba(74, 175, 139, 1) 0%, rgba(88, 176, 135, 1) 18%, rgba(74, 175, 128, 1) 100%);
  }

  .tH {
    color: #3b3b3b;
    font-weight: bold;
  }

  .cont {
    height: 100vh;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background: rgb(3, 23, 107);
    background: radial-gradient(circle, rgba(3, 23, 107, 1) 0%, rgba(3, 25, 54, 1) 74%, rgba(3, 20, 42, 1) 100%);
  }

  .web {
    width: 100%;
    height: 100%;
  }

  .cont .drip {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background: rgb(24, 70, 28);
    position: absolute;
    top: -100%;
    animation: falling 5s linear infinite;
  }

  @keyframes falling {
    0% {
      top: -100%;
    }

    50% {
      top: 0%;
    }

    80% {
      top: 80%;
    }

    100% {
      top: 100%;
    }
  }

  .cont .drip:nth-child(1) {
    border-color: black;
    height: 9px;
    width: 9px;
    animation-delay: -0.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 241px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(2) {
    border-color: black;
    height: 9px;
    width: 9px;
    animation-delay: -0.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 175px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(3) {
    border-color: black;
    height: 36px;
    width: 36px;
    animation-delay: -0.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 190px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(4) {
    border-color: black;
    height: 24px;
    width: 24px;
    animation-delay: -0.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 86px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(5) {
    border-color: black;
    height: 5px;
    width: 5px;
    animation-delay: -1s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 197px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(6) {
    border-color: black;
    height: 23px;
    width: 23px;
    animation-delay: -1.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 244px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(7) {
    border-color: black;
    height: 11px;
    width: 11px;
    animation-delay: -1.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 120px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(8) {
    border-color: black;
    height: 10px;
    width: 10px;
    animation-delay: -1.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 120px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(9) {
    border-color: black;
    height: 27px;
    width: 27px;
    animation-delay: -1.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 29px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(10) {
    border-color: black;
    height: 33px;
    width: 33px;
    animation-delay: -2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 154px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(11) {
    border-color: black;
    height: 39px;
    width: 39px;
    animation-delay: -2.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 109px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(12) {
    border-color: black;
    height: 23px;
    width: 23px;
    animation-delay: -2.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 272px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(13) {
    border-color: black;
    height: 13px;
    width: 13px;
    animation-delay: -2.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 41%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(14) {
    border-color: black;
    height: 21px;
    width: 21px;
    animation-delay: -2.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 71%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(15) {
    border-color: black;
    height: 1px;
    width: 1px;
    animation-delay: -3s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 95%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(16) {
    border-color: black;
    height: 12px;
    width: 12px;
    animation-delay: -3.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 76%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(17) {
    border-color: black;
    height: 12px;
    width: 12px;
    animation-delay: -3.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 71%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(18) {
    border-color: black;
    height: 38px;
    width: 38px;
    animation-delay: -3.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 46%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(19) {
    border-color: black;
    height: 1px;
    width: 1px;
    animation-delay: -3.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 41%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(20) {
    border-color: black;
    height: 19px;
    width: 19px;
    animation-delay: -4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 33%;
    margin-left: 60px;
  }


  .cont .drip:nth-child(21) {
    border-color: black;
    height: 9px;
    width: 9px;
    animation-delay: -0.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 42%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(22) {
    border-color: black;
    height: 9px;
    width: 9px;
    animation-delay: -0.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 58%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(23) {
    border-color: black;
    height: 36px;
    width: 36px;
    animation-delay: -0.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 49%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(24) {
    border-color: black;
    height: 24px;
    width: 24px;
    animation-delay: -0.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 73%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(25) {
    border-color: black;
    height: 5px;
    width: 5px;
    animation-delay: -1s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 83%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(26) {
    border-color: black;
    height: 23px;
    width: 23px;
    animation-delay: -1.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 28%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(27) {
    border-color: black;
    height: 11px;
    width: 11px;
    animation-delay: -1.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 14%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(28) {
    border-color: black;
    height: 10px;
    width: 10px;
    animation-delay: -1.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 43%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(29) {
    border-color: black;
    height: 27px;
    width: 27px;
    animation-delay: -1.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 68%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(30) {
    border-color: black;
    height: 33px;
    width: 33px;
    animation-delay: -2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 57%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(31) {
    border-color: black;
    height: 39px;
    width: 39px;
    animation-delay: -2.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 27%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(32) {
    border-color: black;
    height: 23px;
    width: 23px;
    animation-delay: -2.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 12%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(33) {
    border-color: black;
    height: 13px;
    width: 13px;
    animation-delay: -2.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 30%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(34) {
    border-color: black;
    height: 21px;
    width: 21px;
    animation-delay: -2.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 38%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(35) {
    border-color: black;
    height: 1px;
    width: 1px;
    animation-delay: -3s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 55%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(36) {
    border-color: black;
    height: 12px;
    width: 12px;
    animation-delay: -3.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 50%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(37) {
    border-color: black;
    height: 12px;
    width: 12px;
    animation-delay: -3.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 58%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(38) {
    border-color: black;
    height: 38px;
    width: 38px;
    animation-delay: -3.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 67%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(39) {
    border-color: black;
    height: 1px;
    width: 1px;
    animation-delay: -3.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 70%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(40) {
    border-color: black;
    height: 19px;
    width: 19px;
    animation-delay: -4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 90%;
    margin-left: 60px;
  }

  .donutAndInfo {
    display: flex;
    flex-flow: row nowrap;
  }

  .donut {
    width: 45px;
    height: 45px;
    margin-right: 2%;
  }

  .rmCon {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    width: 90%;
  }

  .phrases p {
    font-size: 18px;
    letter-spacing: 2px;

  }

  .shoe {
    width: 400px;
    height: 400px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .phrases {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: row nowrap;
    width: fit-content;
    margin-top: 3%;
  }

  .rmCon1 {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .teamCon {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    display: flex;
    flex-flow: column nowrap;

  }

  .teamCon img {
    width: 300px;
    height: 300px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    border: 3px solid white;
  }

  .memberDetails {
    color: white;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .memberName {
    font-size: 23px;
    text-align: center;
  }

  .memberCon {
    font-size: 20px;
    text-align: center;
  }


  * {
    box-sizing: border-box;
  }

  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 4rem;
    background-color: #aa5620;
    padding-left: 100%;
  }

  .ticker-wrap .ticker {
    display: inline-block;
    height: 4rem;
    line-height: 4rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 30s;
    animation-duration: 50s;
  }

  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 18px;
    color: #ffffff;
    font-weight: 800;
  }

  .shark {
    width: 400px;
    height: 572px;
    z-index: 1;
    margin-right: -3%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .teamMain {
    display: flex;
  }


  .memName {
    font-family: 'Poppins', sans-serif;
    color: #ffc000;
    font-size: 35px;
    text-align: center;
    padding-right: 2%;
    font-weight: 500;
  }

  .memNamePosition {
    color: #99bad6;
    font-size: 28px;
    text-align: center;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .tPic {
    width: 30px;
    height: 30px;
    cursor: pointer;
  }


  .man {
    width: 380px;
    height: 380px;
    border-radius: 100%;
    transition: transform .5s;
  }

  .man:hover {
    transform: scale(1.1);
    border: 2px solid #5496ec;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px #90bbf3;
  }

  .teamSection {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 70%;
    margin-top: 40px;

  }

  .teamSection2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    margin-top: 40px;
  }


  .tPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .subT {
    font-family: 'Poppins', sans-serif;
    color: #f9f305;
    font-size: 50px;
    text-align: left;
  }

  .manDiv {
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 15px;
  }

  .memName2 {
    color: rgb(255, 255, 255);
    text-align: center;
    font-weight: 500;
    text-shadow: 0 0 10px rgb(255, 255, 255);
  }

  .rmDetails p {
    color: rgb(209, 209, 209);
    padding-left: 20px;
    font-size: 45px;
  }

  .flowers {
    width: 100%;
    margin-top: -10%;
  }

  .rmPicAndCon {
    display: flex;
    justify-content: flex-start;
    margin-left: 20px;
  }

  .footPrint2 {
    width: 30px;
    height: 30px;
  }


  /* Background */
  .mainFoot {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    margin-top: -10%;
  }

  /* Footprint */
  .footprint {
    position: absolute;
    top: 20%;
    left: 0;
    animation: walk 5s steps(10, end) infinite, walkopacity 2s ease-out infinite;
    text-align: center;
  }

  /* Image Size */
  .foot {
    max-width: 20%;
  }

  /* Right Footprint */
  .rightFoot {
    margin: 5rem;
    animation-delay: 250ms;
  }

  /* Walking Animation */
  @keyframes walk {
    to {
      transform: translateX(100vw);
    }
  }

  /* Opacity During Walking */
  @keyframes walkopacity {

    0%,
    100% {
      opacity: 0;
    }

    50% {
      opacity: 0.4;
    }
  }

  .honeyComb {
    width: 450px;
    height: 345px;
    position: sticky;
    position: -webkit-sticky;
    left: 80%;
    bottom: 80px;
  }

  .dripCover {
    width: 100%;
  }

  .ball {
    width: 700px;
    height: 700px;
    animation: floating 5s ease-in-out infinite;
  }

  .ball2 {
    width: 700px;
    height: 700px;
    animation: floating2 5.25s ease-in-out infinite;
  }

  .ball3 {
    width: 700px;
    height: 700px;
    animation: floating3 5.5s ease-in-out infinite;
  }

  .ball4 {
    width: 130px;
    height: 130px;
    animation: floating4 2.1s ease-in-out infinite;
    margin-top: -200px;
    margin-left: 250px;
  }

  .ball5 {
    width: 130px;
    height: 130px;
    animation: floating4 2.3s ease-in-out infinite;
    margin-top: -400px;
    margin-left: 200px;
  }

  .ball6 {
    width: 130px;
    height: 130px;
    animation: floating4 2.8s ease-in-out infinite;
    margin-top: -1100px;
    margin-right: 600px;
  }

  .ball7 {
    width: 130px;
    height: 130px;
    animation: floating4 3s ease-in-out infinite;
    margin-top: -1100px;
    margin-left: 1200px;
  }

  /* Define the keyframes for the floating animation */
  @keyframes floating {
    0% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-10px);
    }

    100% {
      transform: translateY(0);
    }
  }

  @keyframes floating2 {
    0% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-10px);
    }

    100% {
      transform: translateY(0);
    }
  }

  @keyframes floating3 {
    0% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-10px);
    }

    100% {
      transform: translateY(0);
    }
  }

  @keyframes floating4 {
    0% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-10px);
    }

    100% {
      transform: translateY(0);
    }
  }

  .title {
    text-align: center;
    font-weight: bolder;
    font-size: 420px;
    font-family: 'Poppins', sans-serif;
    color: #d1d1e7;
    text-transform: uppercase;
    text-shadow: 0 0 10px #ffffff75;
    z-index: 1000;

  }

  .title2 {
    text-align: center;
    font-weight: bolder;
    font-size: 140px;
    font-family: 'Poppins', sans-serif;
    color: #d1d1e7;
    text-transform: uppercase;
    margin-top: -60px;
    text-shadow: 0 0 10px #ffffff75;
    z-index: 1000;

  }

  .title3 {
    font-size: 30px;
    color: rgb(202, 201, 201);
    width: 50%;
    justify-content: center;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    z-index: 1000;

  }

  .balls {
    display: flex;
    justify-content: center;
    margin-top: 90px;
  }

  .visionBox {
    color: white;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    width: 850px;
    padding: 20px;
    border-radius: 20px;
    font-size: 28px;
  }

  .visionBox:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }

  .missionBox {
    color: #a5f8f1;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    width: 850px;
    padding: 20px;
    border-radius: 20px;
    font-size: 28px;
  }

  .missionBox:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }

  .visionT {
    color: #a5f8f1;
    font-size: 60px;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    text-align: center;
  }

  .utilCon {
    color: white;
    font-size: 28px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    width: 80%;
  }

  .utilMainCon {
    width: 50%;
  }

  .utilMainCon img {
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }

  .tokenCon {
    display: flex;
    justify-content: space-between;
    flex-flow: row nowrap;
  }

  .platformMain {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
  }

  .tokenCon p {
    color: white;
    font-weight: 500;
  }

  th {
    font-weight: bold;
    font-size: 35px;
    color: #e3bcfa;
  }

  td {
    color: white;
    font-size: 30px;
  }

  table {
    width: 30%;
  }

  .chart {
    width: 50%;
    justify-content: flex-end;
  }

  .taxMain2 {
    display: flex;
    margin-top: 50px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  #customers {
    font-family: 'Poppins', sans-serif;
    border-collapse: collapse;
    width: 100%;
  }

  #customers td,
  #customers th {
    border: 1px solid #ddd;
    padding: 8px;
    font-size: 30px;
  }

  #customers tr:nth-child(even) {
    background-color: #f2f2f225;
  }

  #customers th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #ffc000;
    color: rgb(0, 0, 0);
  }

  #customers2 {
    font-family: 'Poppins', sans-serif;
    border-collapse: collapse;
    width: 70%;
    margin-right: 60px;
  }

  #customers2 td,
  #customers2 th {
    border: 1px solid #ddd;
    padding: 8px;
    font-size: 18px;
    font-size: 30px;

  }

  #customers2 tr:nth-child(even) {
    background-color: #b7d5f725;
  }

  #customers2 th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #8297f7;
    color: rgb(0, 0, 0);
  }


  #customers3 {
    font-family: 'Poppins', sans-serif;
    border-collapse: collapse;
    width: 100%;
    margin-right: 60px;
  }

  #customers3 td,
  #customers3 th {
    border: 1px solid #ddd;
    padding: 8px;
    font-size: 30px;
  }

  #customers3 tr:nth-child(even) {
    background-color: #b7d5f725;
  }

  #customers3 th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #8297f7;
    color: rgb(0, 0, 0);
  }

  .platformMain2 {
    display: flex;
    justify-content: space-evenly;
    margin-top: 50px;
  }

  .platformMain3 {
    justify-content: center;
    margin-top: 50px;
  }

  .pT2 {
    font-family: 'Poppins', sans-serif;
    color: #ffc000;
    font-size: 36px;
  }

  .tableCon2 p {
    color: rgb(145, 196, 245);
    font-size: 30px;
  }

  .tableCon2 {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .tableCon3 {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .alien {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);

    transform-origin: center;
    transform: perspective(800px) rotateY(25deg);
    transition: 0.5s;
  }

  .alien:hover {
    transform: perspective(800px) rotateY(0deg);
    opacity: 1;
  }

  .tableCon2Main {
    margin-top: auto;
    margin-bottom: auto;
  }

  .winnerNote {
    color: rgb(145, 196, 245);
    text-align: center;
    margin-top: 10px;
    font-size: 30px;
  }

  .nftT {
    font-weight: bold;
    color: white;
    font-size: 30px;
  }

  .nftT2 {
    color: white;
    font-size: 27px;

  }

  .nftCon {
    margin-bottom: 20px
  }

  #customers4 {
    font-family: 'Poppins', sans-serif;
    border-collapse: collapse;
    width: 100%;
  }

  #customers4 td,
  #customers4 th {
    border: 1px solid #ddd;
    padding: 8px;
    font-size: 30px;
  }

  #customers4 tr:nth-child(even) {
    background-color: #7ec5ff25;
  }

  #customers4 th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #ffc000;
    color: rgb(0, 0, 0);
  }

  .alien {
    width: 600px;
    height: 600px;
    border-radius: 20px;
  }

  .nftInfoT {
    color: #ffc000;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    font-size: 35px;
  }

  .nftInfo {
    color: rgb(162, 208, 252);
    font-size: 30px;
  }

  .note {
    width: 55px;
    height: 55px;
  }

  .com {
    color: #767698;
    font-size: 21px;
  }

  a {
    color: #767698;
    font-size: 21px;
    text-decoration: none;
  }

  a:hover {
    color: #a2a2c4;
    font-size: 18px;
    text-decoration: underline;
  }

  .comInfo {
    display: flex;
    justify-content: space-between;
    margin-top: 60px;
  }

  .lineF {
    width: 100%;
    height: 1px;
    background-color: rgba(75, 63, 78, 0.3);
    margin-bottom: 20px;
  }

  .lineF2 {
    width: 100%;
    height: 1px;
    background-color: rgba(75, 63, 78, 0.3);
    margin-top: 20px;
  }

  .cs {
    color: #5c6c70;
    margin-top: -20px;
    margin-bottom: 60px;
    font-size: 28px;
  }

}

@media screen and (min-width: 3840px) {
  #fontSize2{
    display: none;
  }
  
  .lotteryMain2-2 {
    justify-content: center;
   display: flex;
   flex-flow: column nowrap;
  /* From https://css.glass */
  background: url('./assets/Container.png') no-repeat center center fixed; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  padding: 60px;
   border: 2px solid rgb(160, 160, 255);
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(120, 120, 226);
  padding-top: 10px;
  margin-bottom: 120px;
  margin-left: auto;
  margin-right: auto;
  }

  .lotteryMain4{
    display: flex;
  }
  
  .totalTickets{
    color: white;
    font-size: 70px;
    margin-bottom: 80px;
  }
    /*.............. FAQ ..............*/
    .pomPrice{
      color: #4ac7fc;
      text-align: center;
      margin-top: 25px;
      font-size: 38px;
     }
/* CSS */
.button-29 {
  align-items: center;
  appearance: none;
  background-image: radial-gradient(100% 100% at 100% 0, #fff2b7 0, #ffd000 100%);
  border: 0;
  border-radius: 6px;
  box-shadow: rgba(45, 35, 66, .4) 0 2px 4px,rgba(45, 35, 66, .3) 0 7px 13px -3px,rgba(58, 65, 111, .5) 0 -3px 0 inset;
  box-sizing: border-box;
  color: rgb(0, 0, 0);
  cursor: pointer;
  display: inline-flex;
  font-family: "JetBrains Mono",monospace;
  height: 80px;
  justify-content: center;
  line-height: 1;
  list-style: none;
  overflow: hidden;
  padding-left: 16px;
  padding-right: 16px;
  position: relative;
  text-align: left;
  text-decoration: none;
  transition: box-shadow .15s,transform .15s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  will-change: box-shadow,transform;
  font-size: 55px;
  width: 650px;
}

.button-29:focus {
  box-shadow: #dab200 0 0 0 1.5px inset, rgba(45, 35, 66, .4) 0 2px 4px, rgba(45, 35, 66, .3) 0 7px 13px -3px, #f3cc1d 0 -3px 0 inset;
}

.button-29:hover {
  box-shadow: rgba(45, 35, 66, .4) 0 4px 8px, rgba(45, 35, 66, .3) 0 7px 13px -3px, #9c8001 0 -3px 0 inset;
  transform: translateY(-2px);
}

.ticketsSeparately{
  display: flex;
  flex-flow: column nowrap;
}


summary {
  font-size: 60px;
  background: #af3d330c;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  color: rgb(0, 0, 0);
  padding: 1.5rem;
  margin-bottom: 1rem;
  outline: none;
  text-align: left;
  cursor: pointer;
  color: white;
  border: 2px solid rgb(197, 197, 197);
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(255, 255, 255, 0.61);
}

details>summary::-webkit-details-marker {
  display: none;
}

details[open] summary~* {
  animation: sweep .5s ease-in-out;
}

@keyframes sweep {
  0% {
    opacity: 0;
    margin-top: -10px
  }

  100% {
    opacity: 1;
    margin-top: 0px
  }
}

.accordin {
  width: 70%;
  color: white;
  display: inline-block;
  flex-direction: column;
  margin-bottom: 85px;
}

.faq__content {

  text-shadow: 0 0 10px #FFFFFF;
  padding-bottom: 20px;
  text-align: left;
  font-size: 17px;
}

.faqTitle {
  margin-bottom: 20px;
  text-align: center;
  font-size: 70px;
  color: white;
  font-family: 'Poppins', sans-serif;
}

.faqbg {
  width: 100%;
  text-align: left;
  padding-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  width: 100%;
  padding-bottom: 10%;

}

.lotterySubH{
  justify-content: center;
  display: flex;
  font-size: 110px;
  font-family: 'Poppins', sans-serif;
  text-align: left;
  color: white;
  font-weight: bold;
  margin-top: -20px;
    border: 2px solid rgb(160, 160, 255);
   box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(120, 120, 226);
   width: 800px;
   margin-left: auto;
   margin-right: auto;
   border-bottom: 0px;
}

.connect div:hover {
  color: #ffc000;
}

.boxWrap2Lottery {
  display: flex;
  flex-flow: column nowrap;
  padding-bottom: 5%;
  padding-top: 5%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  width: 100%;
  background: rgb(0, 29, 56);
  background: linear-gradient(10deg, rgb(0, 37, 54) 0%, rgb(18, 17, 1) 34%, rgba(1, 6, 18, 1) 100%);
  padding-left: 5%;
  padding-right: 5%;
  
}

.lottery{
  width: 700px;
  height: 778px;
}

.ticketCategory{
  color: white;
  margin-left: auto;
  margin-right: auto;
}

.lotteryMain {
  margin-top: 30px;
   display: flex;
  flex-flow: column nowrap;
  margin-bottom: 5%;

}

.lotteryMain2 {
   justify-content: space-evenly;
  display: flex;
  flex-flow: row nowrap;
 /* From https://css.glass */
 background: url('./assets/Container.png') no-repeat center center fixed; 
 -webkit-background-size: cover;
 -moz-background-size: cover;
 -o-background-size: cover;
 background-size: cover;
 padding: 60px;
  border: 2px solid rgb(160, 160, 255);
 box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(120, 120, 226);
 padding-top: 10px;
 margin-bottom: 120px;
}

.lotteryMain3 {
  justify-content: space-evenly;
  flex-flow: column nowrap;
/* From https://css.glass */
background: url('./assets/Container.png') no-repeat center center fixed; 
-webkit-background-size: cover;
-moz-background-size: cover;
-o-background-size: cover;
background-size: cover;
padding: 60px;
 border: 2px solid rgb(160, 160, 255);
box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(120, 120, 226);
padding-top: 10px;
margin-bottom: 120px;
}

.drawBtn{
  background-color: white;
  color: black;
  border: none;
  padding: 15px;
  font-weight: 500;  
  font-size: 60px;
}

.autoPickedMain{
  background: url('./assets/Container.png') no-repeat center center fixed; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  padding: 60px;
   border: 2px solid rgb(160, 160, 255);
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(120, 120, 226);
   margin-bottom: 120px;
}

.autoPickedMain p{
  color: white;
  text-align: center;
}

.kave-btn {
  position: relative;
  border: solid 1px rgba(194, 191, 193, 0.3);
  background: none;
  color: white;
  letter-spacing: 1.8px;
  width: 600px;
  height: 130px;
  padding: 0;
  transition: all 0.25s ease;
  outline: none;
  font-size: 45px;
  font-weight: bold;
  margin-bottom: 60px;
}

.kave-btn:before {
  content: "";
  position: absolute;
  top: 0;
  left: 10%;
  width: 60px;
  height: 1px;
  transform: translateY(-1px);
  background: #93edfd;
  transition: all 0.25s ease;
}

.kave-btn:after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 10%;
  width: 60px;
  height: 1px;
  transform: translateY(1px);
  background: #00d6fc;
  transition: all 0.25s ease;
}

.kave-btn:hover {
  box-shadow: 1px 1px 8px rgba(0, 214, 252, 0.3);
  color: #00d6fc;
  text-shadow: 0 0 8px rgba(0, 214, 252, 0.4);
}

.kave-btn:hover.kave-btn:before {
  left: 0;
  width: 20px;
}

.kave-btn:hover.kave-btn:after {
  right: 0;
  width: 20px;
}

.kave-btn:hover .kave-line:before {
  bottom: 0;
}

.kave-btn:hover .kave-line:after {
  top: 0;
}

.kave-line:before {
  content: "";
  position: absolute;
  bottom: 30%;
  right: 0;
  width: 1px;
  height: 20px;
  transform: translateX(1px);
  background: #00d6fc;
  transition: all 0.25s ease;
}

.kave-line:after {
  content: "";
  position: absolute;
  top: 30%;
  left: 0;
  width: 1px;
  height: 20px;
  transform: translateX(-1px);
  background: #00d6fc;
  transition: all 0.25s ease;
}
  .aboutMob {
    display: none;
  }
  /* CSS */
  .button-92 {
    --c: #fff;
    /* text color */
    background: linear-gradient(90deg, #0000 33%, #fff5, #0000 67%) var(--_p,100%)/300% no-repeat,
      #004dff;
    /* background color */
    color: #0000;
    border: none;
    transform: perspective(500px) rotateY(calc(20deg * var(--_i,-1)));
    text-shadow: calc(var(--_i,-1) * 0.08em) -.01em 0   var(--c),
      calc(var(--_i,-1) * -0.08em)  .01em 2px #0004;
    outline-offset: .1em;
    transition: 0.3s;
  }

.button-92:hover,
.button-92:focus-visible {
  --_p: 0%;
  --_i: 1;
}

.button-92:active {
  text-shadow: none;
  color: var(--c);
  box-shadow: inset 0 0 9e9q #0005;
  transition: 0s;
}

.button-92 {
  font-weight: bold;
  font-size: 5rem;
  margin: 0;
  cursor: pointer;
  padding: .1em .3em;
}
  
  .boxWrap2Mint {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: rgb(0, 41, 79);
    background: radial-gradient(circle, rgba(0, 41, 79, 1) 0%, rgba(0, 10, 25, 1) 74%, rgba(0, 10, 25, 1) 100%);
    padding-left: 5%;
    padding-right: 5%;
  }
  .taxMain2Mob {
    display: none;
  }

  .teamSectionMob {
    display: none;
  }

  .teamSection2Mob {
    display: none;
  }

  .special:hover {
    background-color: rgb(228, 194, 0);

  }

  .special:active {
    background-color: rgb(228, 194, 0);
    box-shadow: -0px 7px rgb(153, 131, 4);
    transform: translateY(4px);
  }

  .special2:hover {
    background-color: rgb(228, 194, 0);

  }

  .special2:active {
    background-color: rgb(228, 194, 0);
    box-shadow: -0px 7px rgb(153, 131, 4);
    transform: translateY(4px);
  }

  .placeAndWL {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 50px;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    background: rgba(82, 82, 82, 0.2);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 30px;
  }

  .special {
    font-size: 20px;
    margin-left: 30px;
    border-radius: 10px;
    background-color: gold;
    border: none;
    padding: 10px;
    box-shadow: -0px 5px rgb(194, 165, 5);
  }

  .special2 {
    font-size: 20px;
    border-radius: 10px;
    background-color: gold;
    border: none;
    padding: 10px;
    box-shadow: -0px 5px rgb(194, 165, 5);
  }

  .mintingAmount {
    border: black 2px solid;
    border-radius: 10px;
    box-shadow: -0px 5px black;
    padding: 10px;
  }

  h1 {
    text-align: center;
    margin-bottom: 200px;
    margin-top: 100px;
    font-size: 50px;
    font-weight: 600;
    color: #0b72d3;
  }

  .timeline-container {
    display: flex;
    justify-content: center;
    margin-top: 80px;
  }

  .timeline-container .vertical-scrollable-timeline {
    width: 700px;
    list-style-type: none;
  }

  .timeline-container .vertical-scrollable-timeline li {
    position: relative;
    padding: 35px 0px 125px 158px;
  }

  .timeline-container .vertical-scrollable-timeline li:last-child {
    margin-bottom: 0;
  }

  .timeline-container .vertical-scrollable-timeline li h2 {
    font-size: 40px;
    margin-bottom: 10px;
    color: #ffc000;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
  }

  .timeline-container .vertical-scrollable-timeline li p {
    line-height: 1.5;
    color: rgb(198, 221, 255);
    font-size: 25px;
  }

  .timeline-container .vertical-scrollable-timeline li p:last-child {
    margin-bottom: 0;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder {
    position: absolute;
    left: 0;
    top: 0;
    width: 104px;
    height: 104px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #c5c3cb;
    border-radius: 50%;
    z-index: 1;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder::before {
    content: "";
    width: 80px;
    height: 80px;
    border: 4px solid #fff;
    position: absolute;
    background-color: #c5c3cb;
    border-radius: 50%;
    z-index: -1;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder i {
    font-size: 25px;
    color: #fff;
  }

  .timeline-container .vertical-scrollable-timeline li::after {
    content: "";
    position: absolute;
    height: 0;
    width: 8px;
    background-color: #3f96e7;
    left: 48px;
    top: 34px;
    z-index: 0;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 8px;
    background-color: #c5c3cb;
    left: 48px;
    z-index: 0;
  }

  .timeline-container .vertical-scrollable-timeline li:last-child::before {
    content: unset;
  }

  .timeline-container .vertical-scrollable-timeline li.active .icon-holder {
    background-color: #ffc000;
  }

  .timeline-container .vertical-scrollable-timeline li.active .icon-holder::before {
    background-color: #4372c3;
  }

  .timeline-container .vertical-scrollable-timeline li.active::after {
    height: 100%;
  }

  .timeline-container .vertical-scrollable-timeline li.active:last-child::after {
    content: unset;
  }


  figure {
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
  }

  figure:nth-child(1) .rmDetails {
    transform: scale(0.6) rotate(-12.5deg);
  }

  figure:nth-child(2) .rmDetails {
    transform: scale(0.6) rotate(-9.7222222222deg);
  }

  figure:nth-child(3) .rmDetails {
    transform: scale(0.6) rotate(-6.9444444444deg);
  }

  figure:nth-child(4) .rmDetails {
    transform: scale(0.6) rotate(-4.1666666667deg);
  }

  figure:nth-child(5) .rmDetails {
    transform: scale(0.6) rotate(-1.3888888889deg);
  }

  figure:nth-child(6) .rmDetails {
    transform: scale(0.6) rotate(1.3888888889deg);
  }

  figure:nth-child(7) .rmDetails {
    transform: scale(0.6) rotate(4.1666666667deg);
  }

  figure:nth-child(8) .rmDetails {
    transform: scale(0.6) rotate(6.9444444444deg);
  }

  figure:nth-child(9) .rmDetails {
    transform: scale(0.6) rotate(9.7222222222deg);
  }

  figure:nth-child(10) .rmDetails {
    transform: scale(0.6) rotate(12.5deg);
  }

  figure .rmDetails {
    width: 100%;
    border-radius: 3px;
    background-color: black;
    padding: 50px;
    border: 5px solid gray;
  }

  .connect2Mobile {
    display: none;
  }

  .discordNone {
    transition: transform .2s;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: no-drop;
  }

  .discordNone:hover {
    transform: scale(1.1);
  }

  .linkedin {
    width: 30px;
    height: 30px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 10px;
    cursor: pointer;

  }

  .team1 {
    width: 50%;
  }

  .discord3 {
    display: none;
  }

  .discord4 {
    display: none;
  }

  .storyHRmM {
    display: none;
  }

  .iconsMob {
    display: none;
  }

  .mainBanner {
    display: none;
  }

  .icons {
    display: flex;
    flex-flow: row nowrap;
  }

  .cover52 {
    display: none;
  }

  .coverRm {
    width: 100vw;
  }

  .discordF2 {
    display: none;
  }

  .connectMobile {
    display: none;
  }

  .rightMobile {
    display: none;
  }

  .osPic {
    width: 30px;
    height: 30px;
    cursor: pointer;
    z-index: 10000;
  }

  .osPic2 {
    width: 40px;
    height: 40px;
    cursor: pointer;
  }

  .price {
    text-align: center;
    font-size: 40px;
    color: #aef6ff;
  }

  .discord2 {
    display: none;
  }

  .discord3 {
    padding-right: 20px;
    display: none;
  }


  .uRMobile {
    display: none;
  }

  .storyPicDivMobile {
    display: none;
  }

  .allWrap {

    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    contain: content;
  }

  .boxWrap2 {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background-color: black;
  }

  .boxWrap2Story {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: rgb(0, 88, 81);
    background: radial-gradient(circle, rgba(0, 88, 81, 1) 0%, rgba(0, 42, 47, 1) 34%, rgb(3, 14, 41) 100%);
    padding-left: 5%;
    padding-right: 5%;
  }

  .boxWrap2Utility {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: rgb(0, 45, 56);
    background: linear-gradient(0deg, rgba(0, 45, 56, 1) 0%, rgba(1, 6, 18, 1) 34%, rgba(1, 6, 18, 1) 100%);
    padding-left: 5%;
    padding-right: 5%;
  }

  .boxWrap2Team {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: rgb(0, 41, 79);
    background: radial-gradient(circle, rgba(0, 41, 79, 1) 0%, rgba(0, 10, 25, 1) 74%, rgba(0, 10, 25, 1) 100%);
    padding-left: 5%;
    padding-right: 5%;
  }

  .boxWrap2Platform {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: rgb(21, 38, 7);
    background: linear-gradient(309deg, rgba(21, 38, 7, 1) 0%, rgba(0, 10, 25, 1) 74%, rgba(0, 10, 25, 1) 100%);
    padding-left: 5%;
    padding-right: 5%;
  }

  .boxWrap2Tokenomic {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: rgb(0, 5, 27);
    background: linear-gradient(97deg, rgba(0, 5, 27, 1) 0%, rgba(43, 22, 56, 1) 74%, rgba(0, 5, 27, 1) 100%);
    padding-left: 5%;
    padding-right: 5%;
  }

  .sharks {
    width: 100%;
  }

  .boxWrapC {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    position: relative;

    padding-bottom: 5%;
  }

  .green {
    background-color: #97ba5e;

  }

  .about {
    margin-top: 30px;
    justify-content: space-evenly;
    display: flex;
    flex-flow: row nowrap;
  }

  .aboutVAndM {
    margin-top: 30px;
    justify-content: space-evenly;
    display: flex;
    flex-flow: row nowrap;
  }

  .about2 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 90%;
  }

  .aboutImg {
    width: 50%;
    border: white 6px solid;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-bottom: 3%;
  }

  .aboutImg2 {
    width: 28%;
    height: 28%;
    border: white 6px solid;
    transition: transform .5s;
  }

  .aboutImg2:hover {
    transform: scale(1.2);
  }

  .utilityPics {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
  }

  .roadmapMain1 {
    top: 0;
    position: relative;
  }

  .storyPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .UtilDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .storyH {
    justify-content: left;
    display: flex;
    font-size: 50px;
    font-family: "Gordita";
    color: #00fae5;
    text-align: left;
  }

  .utilH {
    justify-content: left;
    display: flex;
    font-size: 50px;
    font-family: "Gordita";
    color: #00c8fa;
    text-align: left;
  }

  .TokenomicH {
    justify-content: left;
    display: flex;
    font-size: 50px;
    font-family: "Gordita";
    color: #c86ffc;
    text-align: left;
  }

  .platformH {
    justify-content: left;
    display: flex;
    font-size: 50px;
    font-family: "Gordita";
    color: #a2f55e;
    text-align: left;
  }

  .nftH {
    justify-content: left;
    display: flex;
    font-size: 50px;
    font-family: "Gordita";
    color: #0278ee;
    text-align: left;
  }

  .teamH {
    justify-content: left;
    display: flex;
    font-size: 50px;
    font-family: "Gordita";
    color: #ffc959;
    text-align: left;
  }

  .storyH2 {
    justify-content: left;
    display: flex;
    font-size: 120px;
    font-family: 'Poppins', sans-serif;
    text-align: left;
    color: white;
    font-weight: bold;
    margin-top: -20px;
  }

  .taxT {
    justify-content: center;
    display: flex;
    font-size: 110px;
    font-family: 'Poppins', sans-serif;
    text-align: left;
    color: white;
    font-weight: bold;
    margin-top: -20px;
  }

  .taxMain {
    margin-top: 150px;
  }

  .mintH {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    font-size: 90px;
    background: -webkit-linear-gradient(#f0d9a3, #ff9d01);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: "Gordita";
    -webkit-text-stroke-width: 3px;
    -webkit-text-stroke-color: black;
  }

  .storyRm img {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .tokenH {
    font-size: 70px;
    color: white;
    font-family: 'Poppins', sans-serif;
    text-align: center;
    margin-bottom: 2%;
  }


  .carouselH {
    font-size: 70px;
    color: white;
    font-family: 'Poppins', sans-serif;
    text-align: center;
    margin-bottom: 2%;
  }

  .rmH {
    font-size: 80px;
    color: white;
    font-family: 'Poppins', sans-serif;
    text-align: center;
    background-color: #fe8a00;
    padding-left: 20px;
    margin-bottom: 20px;
  }

  .rmH2 {
    color: grey;
    font-size: 18px;
    line-height: 30px;
  }

  .rmName {
    background: rgb(81, 190, 229);
    background: linear-gradient(0deg, rgba(81, 190, 229, 1) 2%, rgba(81, 190, 229, 1) 20%, rgba(6, 136, 198, 1) 63%, rgba(6, 136, 198, 1) 100%);
  }



  .storyCon {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 70%;
    margin-left: auto;
    margin-right: auto;

  }

  .storyCon-2 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    padding: 20px;
    border-radius: 50px;
    box-shadow: -10px 10px rgba(14, 21, 36, 0.363);
    background-color: rgba(30, 46, 80, 0.568);

  }

  .storyCon2 {
    font-size: 30px;
    font-family: 'Poppins', sans-serif;
    text-align: center;
  }

  .storyConP {
    font-size: 20px;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
  }

  .storyPic {
    width: 540px;
    height: 339px;
  }

  .storyRight {
    width: 45%;
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
  }

  .uR {
    width: 50%;
  }

  .intro {
    color: white;
    font-size: 100px;
    text-align: center;
    font-family: 'Riangriung', sans-serif;
  }

  .logoF {
    color: white;
    text-align: center;
    font-size: 50px;
    background: -webkit-linear-gradient(#f0d9a3, #ff9d01);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: "Gordita";
  }

  .logoF img {
    width: 50%;
  }

  .intro img {
    width: 500px;
    height: 500px;
  }

  .intro3 {
    font-style: italic;
    font-size: 20px;
    font-weight: 500;
  }

  .intro2 {
    color: rgb(0, 0, 0);
    font-size: 23px;
    text-align: left;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    font-weight: bold;
    width: 50%;
  }

  .mintDiv {
    padding-top: 3%;
    padding-bottom: 3%;
    width: 1300px;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.493) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
    margin-bottom: 80px;
    border: 2px solid rgb(119, 222, 248);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(106, 184, 243);
    margin-top: 50px;
  }
  .errorMessage {
    margin-left: 3%;
    margin-top: 1%;
    font-size: 40px;
    color: #fc4741;
    text-align: center;
    text-shadow: -1px -1px 1px rgba(255, 255, 255, .1), 1px 1px 1px rgba(0, 0, 0, .5);
  }

  .loadingContainer {
    margin-top: 1%;
  }

  .loadingText {
    font-size: 40px;
    text-align: center;
    color: rgb(120, 184, 189);
    font-weight: bold;
  }

  .loadTextSub {
    text-align: center;
    color: rgb(157, 194, 199);
    font-size: 30px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;

  }

  .wPMobile {
    display: none;
  }

  .introductionMobile {
    display: none
  }

  .hl {
    color: #6ce34d;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
  }

  .right2 {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 1.5%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .discord {
    transition: transform .2s;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .discord:hover {
    transform: scale(1.1);
  }


  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(99, 31, 177);
  }



  .note {
    color: rgb(255, 255, 255);
  }

  .twitterSoc {
    margin-top: 10px;
    margin-right: 5px;
    /* z-index: -2;*/
    margin-left: 5px;

  }

  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/

  }

  /*.website{
    margin-top: 10px;
    margin-left: 10px;
   /* z-index: -2;*/
  /*color: #ffffffa2;
  }*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;

  }





  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    padding-left: 50px;
    padding-right: 50px;

    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);


  }

  .wallet:hover {
    background-color: white;
    color: rgb(73, 81, 190);
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-family: "Gordita";
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    font-family: "Gordita";
    align-items: center;
  }

  .connect div {
    margin-left: 20px;
    margin-right: 20px;
    font-family: "Gordita";
    color: #ffffff;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease;
    font-weight: 500;
  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }




  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding-bottom: 50px;
    margin-left: auto;
    margin-right: auto;

  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-bottom: 20px;

  }

  .center {
    margin-top: auto;
    margin-bottom: auto;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }

  .btnfos-0-2 {
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 110px;
    height: 110px;
    border: none;
    font-family: 'Poppins', sans-serif;
    cursor: pointer;
    background-color: #3a9ae802;
    border: white 2px solid;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 110px;
    height: 110px;
    border: none;
    font-family: 'Poppins', sans-serif;
    cursor: pointer;
    background-color: #3a9ae802;
    border: white 2px solid;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }


  .btnfos-0-2:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(134, 134, 134, 0.288) -5px 5px;
  }


  .btnfos-0-2-2:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(134, 134, 134, 0.288) 5px 5px;
  }


  .btnfos-0-3 {
    margin-top: 20px;
    background-color: #6cfc19;
    color: #000000;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 28px;
    border-radius: 50px;
    padding-left: 30px;
    padding-right: 30px;
    transition: transform .2s;
    border: none;
    font-family: 'Poppins', sans-serif;
    transition: transform .2s;
  }

  .btnfos-0-3:hover {
    transform: scale(1.1);
  }

  .nftamount {
    color: cyan;
    font-size: 150px;
    font-family: 'Poppins', sans-serif;
   }

  .walletConnect {

    display: flex;
    justify-content: center;
  }

  .connectButton {

    font-size: 45px;
  }

  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;

  }

  .loadingContainer {
    text-align: center;
    padding-top: 20px;
  }

  .errorMessage {
    text-align: center;
    padding-top: 20px;
  }

  .successfully {
    text-align: center;
    padding-top: 20px;
    color: #1ee03e;
    font-family: 'Poppins', sans-serif;
    font-size: 35px;
    text-shadow: -1px -1px 1px rgba(255, 255, 255, .1), 1px 1px 1px rgba(0, 0, 0, .5);
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #000000;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
    border-bottom: 1px solid rgba(59, 45, 87, 0.863);
    padding-top: 85px;
    padding-bottom: 85px;
  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
    margin-top: auto;
    margin-bottom: auto;
  }

  .h2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    background-color: #5ebaad;

    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/

  }

  .introduction {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    z-index: 1000;
    background: rgba(3, 24, 107, 0.733);
    background: radial-gradient(circle, rgba(3, 24, 107, 0.747) 0%, rgba(3, 25, 54, 0.705) 74%, rgba(3, 20, 42, 0.781) 100%);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    padding-bottom: 50px;
  }

  .wallet2 {
    z-index: 1000;
    background-color: #000000;
    color: #ffffff;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 20px;
    border-radius: 50px;
    width: 270px;
    transition: transform .2s;
    border: none;
    font-family: "Gordita";
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    transition: 0.5s ease;
    cursor: pointer;
    box-shadow: 0 3px #363636;
    letter-spacing: 2px;
  }

  .wallet2:hover {
    color: #000000;
    background-color: #ffffff;
  }

  .wallet2Btn {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    width: 400px;
    border: 2px solid #ffffff;
    margin-left: 10px;
    margin-right: 10px;
    border: 2px solid #2E8B57;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
  }

  .wallet2Btn:hover {
    color: rgb(156, 156, 156);
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }


  .wallet3 {
    background-image: linear-gradient(to right top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1);
    color: rgb(0, 0, 0);
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 30px;
    font-weight: bold;
    border-radius: 15px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 50px;
    padding-right: 50px;
    box-shadow: black 0px 5px;
  }

  .wallet3:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }

  @keyframes shake {

    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }

  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }

  .in2 {
    width: 100%;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .totalSupply {
    text-align: center;
    font-size: 80px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    font-family: 'Poppins', sans-serif;
    text-shadow: 0 0 10px #ffffff93;
    letter-spacing: 1px;
    margin-top: 1%;
    font-weight: bold;
  }

  @keyframes Slide_Up {
    0% {
      transform: translateY(250px);
      opacity: 1;
    }

    10%,
    87.5% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Tag {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 1;
    }

    20%,
    92.5% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Buttons {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 0;
    }

    20% {
      opacity: 1;
    }

    25%,
    95% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  .copyright {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 18px;
    text-shadow: 0 0 10px #FFFFFF;
  }

  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }

  @keyframes shake {

    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }

  @keyframes Slide_Left {
    0% {
      transform: translateX(250px);
    }

    100% {
      transform: translateX(0);
    }
  }

  @keyframes Slide_Left_Right {

    0%,
    100% {
      transform: translateX(100px);
    }

    12.5%,
    75% {
      transform: translateX(0);
    }

    25% {
      transform: translateX(20px);
    }

  }

  @keyframes Opacity {

    0%,
    100% {
      opacity: 0;
    }

    25%,
    75% {
      opacity: 1;
    }


  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }

  @keyframes scaleIn {
    from {
      transform: scale(.5, .5);
      opacity: .5;
    }

    to {
      transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }

  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1, 1);
    }

    50% {
      transform: scale(1.05, 1.05);
    }

    100% {
      transform: scale(1, 1);
    }
  }

  .logo {
    width: 270px;
    height: 270px;
    display: none;
  }

  .logo2 {
    width: 20%;
    cursor: pointer;
  }

  #fontSize {
    font-size: 50px;
    font-family: "Gordita";
    cursor: pointer;
    z-index: 10000;
    font-weight: 500;
  }

  .parrotDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;

  }

  .pic1 {
    width: 32%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    left: 70%;
    bottom: 0;

  }

  .pic2 {
    width: 32%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    left: -5%;
    bottom: 0;

  }

  
  .cover {
    width: 100vw;
  }

  .cover2 {
    width: 100%;
  }

  .parrotPic {
    width: 100vw;
  }

  .parrot {
    width: 50%;
    border-radius: 30px;
  }

  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .rmMain {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: -100px;
  }

  .rmBox {
    text-align: right;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }

  .rmBox2 {
    text-align: right;
    width: 80%;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }

  .rmBox {
    font-weight: 700;
    color: rgb(58, 58, 58);
    margin-bottom: 60%;
  }

  .year {
    font-size: 18px;
    font-family: 'Poppins', sans-serif;
    color: rgb(8, 81, 94);
    letter-spacing: 1px;
  }


  .rmBoxMain2 {
    margin-top: 30%;
    width: 33.33%;
  }

  .rmBoxMain {
    width: max-content;
    justify-content: flex-start;
    width: 33.33%;

  }

  .rmBox2 {
    text-align: left;
    width: 30%;
  }

  .rmParrot {
    width: 130px;
    height: 186px;
    z-index: 1;
    justify-content: center;
    position: -webkit-sticky;
    position: sticky;
    top: 10%;
    margin-bottom: 25%;
    margin-right: auto;
    display: block;
    margin-left: 33%;
    margin-top: -50%;
  }

  .line {
    margin-left: auto;
    margin-right: auto;
    display: block;
    height: 270vh;
    margin-top: -30%;
  }

  .rmP {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 33.33%;
    height: fit-content;
  }

  .box {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: -15%;
  }

  .discordF {
    padding-left: 10px;
    padding-right: 10px;
  }

  .socialAndLogo {
    flex-flow: row nowrap;
    justify-content: space-between;
    display: flex;
    padding-left: 10%;
    padding-right: 10%;
    margin-bottom: 10%;
    padding-top: 10%;
  }

  .chartAndContent {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }

  .tCon {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    color: white;
    font-weight: 500;
  }

  .tCon p {
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 5px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 13px;
  }

  .chart {
    width: 60%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .boxWrapToken {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-left: 5%;
    padding-right: 5%;
    background: rgb(74, 175, 139);
    background: linear-gradient(0deg, rgba(74, 175, 139, 1) 0%, rgba(88, 176, 135, 1) 18%, rgba(74, 175, 128, 1) 100%);
  }

  .tH {
    color: #3b3b3b;
    font-weight: bold;
  }

  .cont {
    height: 100vh;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background: rgb(3, 23, 107);
    background: radial-gradient(circle, rgba(3, 23, 107, 1) 0%, rgba(3, 25, 54, 1) 74%, rgba(3, 20, 42, 1) 100%);
  }

  .web {
    width: 100%;
    height: 100%;
  }

  .cont .drip {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background: rgb(24, 70, 28);
    position: absolute;
    top: -100%;
    animation: falling 5s linear infinite;
  }

  @keyframes falling {
    0% {
      top: -100%;
    }

    50% {
      top: 0%;
    }

    80% {
      top: 80%;
    }

    100% {
      top: 100%;
    }
  }

  .cont .drip:nth-child(1) {
    border-color: black;
    height: 9px;
    width: 9px;
    animation-delay: -0.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 241px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(2) {
    border-color: black;
    height: 9px;
    width: 9px;
    animation-delay: -0.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 175px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(3) {
    border-color: black;
    height: 36px;
    width: 36px;
    animation-delay: -0.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 190px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(4) {
    border-color: black;
    height: 24px;
    width: 24px;
    animation-delay: -0.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 86px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(5) {
    border-color: black;
    height: 5px;
    width: 5px;
    animation-delay: -1s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 197px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(6) {
    border-color: black;
    height: 23px;
    width: 23px;
    animation-delay: -1.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 244px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(7) {
    border-color: black;
    height: 11px;
    width: 11px;
    animation-delay: -1.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 120px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(8) {
    border-color: black;
    height: 10px;
    width: 10px;
    animation-delay: -1.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 120px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(9) {
    border-color: black;
    height: 27px;
    width: 27px;
    animation-delay: -1.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 29px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(10) {
    border-color: black;
    height: 33px;
    width: 33px;
    animation-delay: -2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 154px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(11) {
    border-color: black;
    height: 39px;
    width: 39px;
    animation-delay: -2.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 109px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(12) {
    border-color: black;
    height: 23px;
    width: 23px;
    animation-delay: -2.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 272px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(13) {
    border-color: black;
    height: 13px;
    width: 13px;
    animation-delay: -2.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 41%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(14) {
    border-color: black;
    height: 21px;
    width: 21px;
    animation-delay: -2.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 71%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(15) {
    border-color: black;
    height: 1px;
    width: 1px;
    animation-delay: -3s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 95%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(16) {
    border-color: black;
    height: 12px;
    width: 12px;
    animation-delay: -3.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 76%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(17) {
    border-color: black;
    height: 12px;
    width: 12px;
    animation-delay: -3.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 71%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(18) {
    border-color: black;
    height: 38px;
    width: 38px;
    animation-delay: -3.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 46%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(19) {
    border-color: black;
    height: 1px;
    width: 1px;
    animation-delay: -3.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 41%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(20) {
    border-color: black;
    height: 19px;
    width: 19px;
    animation-delay: -4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 33%;
    margin-left: 60px;
  }


  .cont .drip:nth-child(21) {
    border-color: black;
    height: 9px;
    width: 9px;
    animation-delay: -0.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 42%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(22) {
    border-color: black;
    height: 9px;
    width: 9px;
    animation-delay: -0.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 58%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(23) {
    border-color: black;
    height: 36px;
    width: 36px;
    animation-delay: -0.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 49%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(24) {
    border-color: black;
    height: 24px;
    width: 24px;
    animation-delay: -0.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 73%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(25) {
    border-color: black;
    height: 5px;
    width: 5px;
    animation-delay: -1s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 83%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(26) {
    border-color: black;
    height: 23px;
    width: 23px;
    animation-delay: -1.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 28%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(27) {
    border-color: black;
    height: 11px;
    width: 11px;
    animation-delay: -1.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 14%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(28) {
    border-color: black;
    height: 10px;
    width: 10px;
    animation-delay: -1.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 43%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(29) {
    border-color: black;
    height: 27px;
    width: 27px;
    animation-delay: -1.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 68%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(30) {
    border-color: black;
    height: 33px;
    width: 33px;
    animation-delay: -2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 57%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(31) {
    border-color: black;
    height: 39px;
    width: 39px;
    animation-delay: -2.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 27%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(32) {
    border-color: black;
    height: 23px;
    width: 23px;
    animation-delay: -2.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 12%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(33) {
    border-color: black;
    height: 13px;
    width: 13px;
    animation-delay: -2.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 30%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(34) {
    border-color: black;
    height: 21px;
    width: 21px;
    animation-delay: -2.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 38%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(35) {
    border-color: black;
    height: 1px;
    width: 1px;
    animation-delay: -3s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 55%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(36) {
    border-color: black;
    height: 12px;
    width: 12px;
    animation-delay: -3.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 50%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(37) {
    border-color: black;
    height: 12px;
    width: 12px;
    animation-delay: -3.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 58%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(38) {
    border-color: black;
    height: 38px;
    width: 38px;
    animation-delay: -3.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 67%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(39) {
    border-color: black;
    height: 1px;
    width: 1px;
    animation-delay: -3.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 70%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(40) {
    border-color: black;
    height: 19px;
    width: 19px;
    animation-delay: -4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 90%;
    margin-left: 60px;
  }

  .donutAndInfo {
    display: flex;
    flex-flow: row nowrap;
  }

  .donut {
    width: 45px;
    height: 45px;
    margin-right: 2%;
  }

  .rmCon {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    width: 90%;
  }

  .phrases p {
    font-size: 18px;
    letter-spacing: 2px;

  }

  .shoe {
    width: 400px;
    height: 400px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .phrases {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: row nowrap;
    width: fit-content;
    margin-top: 3%;
  }

  .rmCon1 {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .teamCon {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    display: flex;
    flex-flow: column nowrap;

  }

  .teamCon img {
    width: 300px;
    height: 300px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    border: 3px solid white;
  }

  .memberDetails {
    color: white;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .memberName {
    font-size: 23px;
    text-align: center;
  }

  .memberCon {
    font-size: 20px;
    text-align: center;
  }


  * {
    box-sizing: border-box;
  }

  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 4rem;
    background-color: #aa5620;
    padding-left: 100%;
  }

  .ticker-wrap .ticker {
    display: inline-block;
    height: 4rem;
    line-height: 4rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 30s;
    animation-duration: 50s;
  }

  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 18px;
    color: #ffffff;
    font-weight: 800;
  }

  .shark {
    width: 400px;
    height: 572px;
    z-index: 1;
    margin-right: -3%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .teamMain {
    display: flex;
  }


  .memName {
    font-family: 'Poppins', sans-serif;
    color: #ffc000;
    font-size: 50px;
    text-align: center;
    padding-right: 2%;
    font-weight: 500;
  }

  .memNamePosition {
    color: #99bad6;
    font-size: 40px;
    text-align: center;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .tPic {
    width: 30px;
    height: 30px;
    cursor: pointer;
  }


  .man {
    width: 470px;
    height: 470px;
    border-radius: 100%;
    transition: transform .5s;
  }

  .man:hover {
    transform: scale(1.1);
    border: 2px solid #5496ec;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px #90bbf3;
  }

  .teamSection {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 70%;
    margin-top: 40px;

  }

  .teamSection2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    margin-top: 40px;
  }


  .tPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .subT {
    font-family: 'Poppins', sans-serif;
    color: #f9f305;
    font-size: 50px;
    text-align: left;
  }

  .manDiv {
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 15px;
  }

  .memName2 {
    color: rgb(255, 255, 255);
    text-align: center;
    font-weight: 500;
    text-shadow: 0 0 10px rgb(255, 255, 255);
  }

  .rmDetails p {
    color: rgb(209, 209, 209);
    padding-left: 20px;
    font-size: 45px;
  }

  .flowers {
    width: 100%;
    margin-top: -10%;
  }

  .rmPicAndCon {
    display: flex;
    justify-content: flex-start;
    margin-left: 20px;
  }

  .footPrint2 {
    width: 30px;
    height: 30px;
  }


  /* Background */
  .mainFoot {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    margin-top: -10%;
  }

  /* Footprint */
  .footprint {
    position: absolute;
    top: 20%;
    left: 0;
    animation: walk 5s steps(10, end) infinite, walkopacity 2s ease-out infinite;
    text-align: center;
  }

  /* Image Size */
  .foot {
    max-width: 20%;
  }

  /* Right Footprint */
  .rightFoot {
    margin: 5rem;
    animation-delay: 250ms;
  }

  /* Walking Animation */
  @keyframes walk {
    to {
      transform: translateX(100vw);
    }
  }

  /* Opacity During Walking */
  @keyframes walkopacity {

    0%,
    100% {
      opacity: 0;
    }

    50% {
      opacity: 0.4;
    }
  }

  .honeyComb {
    width: 450px;
    height: 345px;
    position: sticky;
    position: -webkit-sticky;
    left: 80%;
    bottom: 80px;
  }

  .dripCover {
    width: 100%;
  }

  .ball {
    width: 900px;
    height: 900px;
    animation: floating 5s ease-in-out infinite;
  }

  .ball2 {
    width: 900px;
    height: 900px;
    animation: floating2 5.25s ease-in-out infinite;
  }

  .ball3 {
    width: 900px;
    height: 900px;
    animation: floating3 5.5s ease-in-out infinite;
  }

  .ball4 {
    width: 130px;
    height: 130px;
    animation: floating4 2.1s ease-in-out infinite;
    margin-top: -200px;
    margin-left: 250px;
  }

  .ball5 {
    width: 130px;
    height: 130px;
    animation: floating4 2.3s ease-in-out infinite;
    margin-top: -400px;
    margin-left: 200px;
  }

  .ball6 {
    width: 130px;
    height: 130px;
    animation: floating4 2.8s ease-in-out infinite;
    margin-top: -1100px;
    margin-right: 600px;
  }

  .ball7 {
    width: 130px;
    height: 130px;
    animation: floating4 3s ease-in-out infinite;
    margin-top: -1100px;
    margin-left: 1200px;
  }

  /* Define the keyframes for the floating animation */
  @keyframes floating {
    0% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-10px);
    }

    100% {
      transform: translateY(0);
    }
  }

  @keyframes floating2 {
    0% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-10px);
    }

    100% {
      transform: translateY(0);
    }
  }

  @keyframes floating3 {
    0% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-10px);
    }

    100% {
      transform: translateY(0);
    }
  }

  @keyframes floating4 {
    0% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-10px);
    }

    100% {
      transform: translateY(0);
    }
  }

  .title {
    text-align: center;
    font-weight: bolder;
    font-size: 620px;
    font-family: 'Poppins', sans-serif;
    color: #d1d1e7;
    text-transform: uppercase;
    text-shadow: 0 0 10px #ffffff75;
    z-index: 1000;

  }

  .title2 {
    text-align: center;
    font-weight: bolder;
    font-size: 200px;
    font-family: 'Poppins', sans-serif;
    color: #d1d1e7;
    text-transform: uppercase;
    margin-top: -60px;
    text-shadow: 0 0 10px #ffffff75;
    z-index: 1000;

  }

  .title3 {
    font-size: 50px;
    color: rgb(202, 201, 201);
    width: 50%;
    justify-content: center;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    z-index: 1000;

  }

  .balls {
    display: flex;
    justify-content: center;
    margin-top: 250px;
  }

  .visionBox {
    color: white;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    width: 1100px;
    padding: 20px;
    border-radius: 20px;
    font-size: 38px;
  }

  .visionBox:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }

  .missionBox {
    color: #a5f8f1;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    width: 1100px;
    padding: 20px;
    border-radius: 20px;
    font-size: 38px;
  }

  .missionBox:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }

  .visionT {
    color: #a5f8f1;
    font-size: 90px;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    text-align: center;
  }

  .utilCon {
    color: white;
    font-size: 40px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    width: 80%;
  }

  .utilMainCon {
    width: 50%;
  }

  .utilMainCon img {
    width: 150px;
    height: 150px;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }

  .tokenCon {
    display: flex;
    justify-content: space-between;
    flex-flow: row nowrap;
  }

  .platformMain {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
  }

  .tokenCon p {
    color: white;
    font-weight: 500;
  }

  th {
    font-weight: bold;
    font-size: 54px;
    color: #e3bcfa;
  }

  td {
    color: white;
    font-size: 45px;
  }

  table {
    width: 30%;
  }

  .chart {
    width: 50%;
    justify-content: flex-end;
  }

  .taxMain2 {
    display: flex;
    margin-top: 50px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  #customers {
    font-family: 'Poppins', sans-serif;
    border-collapse: collapse;
    width: 100%;
  }

  #customers td,
  #customers th {
    border: 1px solid #ddd;
    padding: 8px;
    font-size: 42px;
  }

  #customers tr:nth-child(even) {
    background-color: #f2f2f225;
  }

  #customers th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #ffc000;
    color: rgb(0, 0, 0);
  }

  #customers2 {
    font-family: 'Poppins', sans-serif;
    border-collapse: collapse;
    width: 70%;
    margin-right: 60px;
  }

  #customers2 td,
  #customers2 th {
    border: 1px solid #ddd;
    padding: 8px;
    font-size: 42px;
  }

  #customers2 tr:nth-child(even) {
    background-color: #b7d5f725;
  }

  #customers2 th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #8297f7;
    color: rgb(0, 0, 0);
  }


  #customers3 {
    font-family: 'Poppins', sans-serif;
    border-collapse: collapse;
    width: 100%;
    margin-right: 60px;
  }

  #customers3 td,
  #customers3 th {
    border: 1px solid #ddd;
    padding: 8px;
    font-size: 42px;
  }

  #customers3 tr:nth-child(even) {
    background-color: #b7d5f725;
  }

  #customers3 th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #8297f7;
    color: rgb(0, 0, 0);
  }

  .platformMain2 {
    display: flex;
    justify-content: space-evenly;
    margin-top: 50px;
  }

  .platformMain3 {
    justify-content: center;
    margin-top: 50px;
  }

  .pT2 {
    font-family: 'Poppins', sans-serif;
    color: #ffc000;
    font-size: 55px;
  }

  .tableCon2 p {
    color: rgb(145, 196, 245);
    font-size: 45px;
  }

  .tableCon2 {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .tableCon3 {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .alien {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);

    transform-origin: center;
    transform: perspective(800px) rotateY(25deg);
    transition: 0.5s;
  }

  .alien:hover {
    transform: perspective(800px) rotateY(0deg);
    opacity: 1;
  }

  .tableCon2Main {
    margin-top: auto;
    margin-bottom: auto;
  }

  .winnerNote {
    color: rgb(145, 196, 245);
    text-align: center;
    margin-top: 10px;
    font-size: 45px;
  }

  .nftT {
    font-weight: bold;
    color: white;
    font-size: 50px;
  }

  .nftT2 {
    color: white;
    font-size: 40px;

  }

  .nftCon {
    margin-bottom: 20px
  }

  #customers4 {
    font-family: 'Poppins', sans-serif;
    border-collapse: collapse;
    width: 100%;
  }

  #customers4 td,
  #customers4 th {
    border: 1px solid #ddd;
    padding: 8px;
    font-size: 42px;
  }

  #customers4 tr:nth-child(even) {
    background-color: #7ec5ff25;
  }

  #customers4 th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #ffc000;
    color: rgb(0, 0, 0);
  }

  .alien {
    width: 700px;
    height: 700px;
    border-radius: 20px;
  }

  .nftInfoT {
    color: #ffc000;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    font-size: 50px;
  }

  .nftInfo {
    color: rgb(162, 208, 252);
    font-size: 45px;
  }

  .note {
    width: 65px;
    height: 65px;
  }

  .com {
    color: #767698;
    font-size: 35px;
  }

  a {
    color: #767698;
    font-size: 35px;
    text-decoration: none;
  }

  a:hover {
    color: #a2a2c4;
    font-size: 18px;
    text-decoration: underline;
  }

  .comInfo {
    display: flex;
    justify-content: space-between;
    margin-top: 60px;
  }

  .lineF {
    width: 100%;
    height: 1px;
    background-color: rgba(75, 63, 78, 0.3);
    margin-bottom: 20px;
  }

  .lineF2 {
    width: 100%;
    height: 1px;
    background-color: rgba(75, 63, 78, 0.3);
    margin-top: 20px;
  }

  .cs {
    color: #5c6c70;
    margin-top: -20px;
    margin-bottom: 60px;
    font-size: 40px;
  }

}
